@import '~@sonnen/shared-web/abstracts';

.tooltip-clickable {
  position: relative;
  display: inline-block;
}

.tooltip-clickable__trigger {
  display: inline-block;
  cursor: pointer;
}

.tooltip-clickable__bubble {
  position: absolute;
  top: 24px;
  left: -15px;
  width: 250px;
  font-size: 12px;
  z-index: var(--z-index-tooltip);
}
