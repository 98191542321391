@import '~@sonnen/shared-web/abstracts';

.modal {
  .sw-modal__dialog {
    width: 720px;
  }
}

.lead-setup-modal {
  cursor: default;
  height: 100%;
  width: 100%;

  @include breakpointUp(SM) {
    height: 100%;
    width: 100%;
  }

  .sw-modal-layout__header {
    background-color: getColor('grey-lightest');
    border-radius: 3px 3px 0 0;
    padding-top: calc(var(--gap-3) + var(--gap-1) / 2);
  }

  .sw-modal-layout__body {
    padding-bottom: var(--gap-1);
    padding-right: calc(var(--gap-2) * 0.75);
    padding-top: var(--gap-1);
  }

  .sw-modal-layout__body-inner {
    padding: var(--gap-2) calc(var(--gap-5) + var(--gap-1) / 4);
  }

  .sw-modal-layout__title {
    font-size: 26px;
  }

  .sw-modal-layout__subtitle {
    margin-bottom: 0;
    margin-top: calc(var(--gap-1) + var(--gap-1) / 4);
  }

  .sw-default-paragraph {
    color: getColor('grey');
    font-size: 12px;
    font-weight: 420;
  }

  .sw-modal-layout__footer {
    flex-direction: column;
    padding: var(--gap-1) var(--gap-5) var(--gap-3);
  }

  &__footer-button {
    height: calc(var(--gap-5) * 0.95);
    margin-right: 0;
  }

  &__buttons {
    justify-content: center;
    margin-top: calc(var(--gap-3) - var(--gap-1) / 4);
  }

  &__form {
    width: 100%;
    height: 100%;
  }

  &__warning {
    display: flex;
    align-items: center;
    background-color: getColor('yellow-lightest');
    border-radius: 4px;
    padding: 12px;
    margin-top: 10px;
  }

  &__warning-icon {
    fill: getColor('yellow');
    margin: 0 var(--gap-2) var(--gap-2) var(--gap-2);
    width: var(--gap-4);
  }

  &__section {
    margin-top: 18px;
    &:first-of-type {
      margin-top: 20px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--gap-2);
    margin-bottom: var(--gap-3);

    &:first-of-type {
      margin-top: var(--gap-1);
    }

    &:last-of-type {
      margin-bottom: var(--gap-2);
    }
  }

  &__name {
    color: getTextColor('dark');
    font-weight: 420;
    margin-bottom: calc(var(--gap-1) / 4);
  }

  &__owner {
    color: getTextColor('grey');
    font-size: 12px;
    margin: 0;
    padding-left: calc(var(--gap-1) + var(--gap-1) / 4);
    position: relative;

    &::before {
      background-color: getColor('grey');
      border-radius: 50%;
      content: '';
      height: calc(var(--gap-1) / 2);
      left: 0;
      position: absolute;
      top: calc(50% - var(--gap-1) / 4);
      width: calc(var(--gap-1) / 2);
    }
  }
}
