@import '~@sonnen/shared-web/abstracts';

.c-lead-setup-list__header {
  --lead-list-header-field-height: 45px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-lead-setup-list__container {
  padding-top: 0;
}

.c-lead-setup-list__headline-wrapper {
  padding: var(--gap-3) 0 var(--gap-4);
}

.c-lead-setup-list__pagination {
  justify-content: center;
  flex-flow: initial;
}

.c-lead-setup-list__search-field {
  --search-field-height: var(--lead-list-header-field-height);

  flex: 1;

  @include breakpointUp(SM) {
    --search-field-width: 250px;
    display: flex;
    justify-content: flex-start;
    margin-right: var(--gap-2);
    margin-bottom: 0;
  }

  @include breakpointUp(MD) {
    margin-right: var(--gap-4);
  }
}

.c-lead-list__status-filter {
  flex: 3;
}

// tablet

.c-list-table--setup-list {
  @include breakpointDown(LG) {
    .c-list-table__table {
      overflow-x: initial;
    }

    .c-list-table__table-wrapper {
      min-width: unset;
    }

    .c-list-table__header {
      display: none;
    }

    .c-lead-list-table-item {
      align-items: flex-start;
      flex-direction: column;
    }

    .c-lead-list-table-item__cell {
      flex-direction: column;
      margin: 0;
    }

    .c-lead-list-battery,
    .c-lead-list-location {
      margin-bottom: var(--gap-1);
    }

    .c-lead-list-battery__paragraph,
    .c-lead-list-location__paragraph {
      margin: 0 5px 0 0;
    }

    .c-lead-list-table-item__round-btn-wrapper {
      justify-content: flex-end;
      padding: 0;

      p {
        max-width: 90px;
      }

      @include breakpointDown(XS) {
        justify-content: flex-start;
        margin-top: var(--gap-3);

        p {
          max-width: initial;
        }
      }
    }

    .c-lead-list-table-item__cell--mobile-top {
      flex-basis: initial;
      padding-bottom: 12px;
    }

    .c-lead-list-table-item__cell--mobile-bottom {
      align-items: center;
      border-top: 1px solid getColor('grey-light');
      flex-direction: row;
      justify-content: space-between;
      padding: 30px 0 10px;
      width: 100%;

      @include breakpointDown(XS) {
        flex-wrap: wrap;
      }
    }
  }
}
