@import '~@sonnen/shared-web/abstracts';

.c-overview-edit-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-overview-edit-form--warning {
  margin-bottom: var(--gap-7);

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-4);
  }
}

.c-overview-edit-form__body {
  @include breakpointDown(SM) {
    width: 100%;
  }
}

.c-overview-edit-form__input--error {
  --input-margin-bottom: var(--gap-5);
}

.c-overview-edit-form__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  height: 100%;

  > button {
    margin-bottom: var(--gap-2);
  }
}

.c-overview-edit-form__button-submit {
  --btn-min-width: 12px;
}

.c-overview-edit-form__button-submit,
.c-overview-edit-form__button-cancel {
  margin-left: var(--gap-2);
}

.c-overview-edit-form__button-cancel {
  padding: 0;
}

.c-overview-edit-error {
  margin-bottom: var(--gap-2);
  margin-top: var(--gap-2);
  color: getTextColor('red');
  font-weight: 420;
  font-size: 14px;
}
