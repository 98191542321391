@import '~@sonnen/shared-web/abstracts';

.c-lead-list-header__nav-icon {
  font-size: 24px;
  height: 24px;
  max-width: 24px;
  fill: currentColor;
  display: none;

  @include breakpointUp(XS) {
    display: block;
    margin-right: var(--gap-1);
  }
}

.c-lead-list-header__headline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  font-size: 28px;
  font-weight: 300;
  color: getTextColor('dark');

  @include breakpointUp(SM) {
    font-size: var(--gap-4);
  }
}

.c-lead-list-header {
  @include breakpointDown(SM) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.c-lead-list-header__button--mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--gap-4);
  width: var(--gap-4);
  background-color: getColor('blue');
  border-radius: 50%;
  cursor: pointer;
}

.c-lead-list-header__icon {
  @include createIcon($_width: 18px, $_height: 18px, $_color: getColor('white'));
}

.c-lead-list-header__button-icon {
  $width: 18px;
  $height: 18px;
  @include createIcon($_width: $width, $_height: $height, $_color: getColor('blue-light'));
  margin-right: 10px;
  margin-bottom: -4px;

  &--is-disabled {
    @include createIcon($_width: $width, $_height: $height, $_color: getColor('grey-light'));
  }
}
