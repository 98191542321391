@import '~@sonnen/shared-web/abstracts';

.c-form-section {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: var(--gap-6);
  width: 100%;

  &:nth-child(2) {
    margin-bottom: var(--gap-4);
  }

  &:nth-child(3) {
    margin-bottom: var(--gap-3);
  }

  .c-form-input {
    margin-bottom: 0;
  }

  .c-form-input-select {
    margin-bottom: 20px;
  }

  .c-form-textarea__field {
    height: var(--gap-8);
  }

  .c-form-field-error {
    position: relative;
  }

  .c-form-input-radio-group {
    margin-bottom: var(--gap-3);
    width: 100%;
  }

  .c-form-input-checkbox {
    margin: 0 var(--gap-3) 0 0;
  }

  .c-form-input-checkbox-group {
    margin-bottom: 0;
  }

  .c-form-textarea {
    font-size: 18px;
    margin: var(--gap-3) 0 0;
  }
}

.c-form-section__title {
  margin-bottom: var(--gap-3);
  width: 100%;
}

.c-news-form--edit {
  padding-bottom: var(--gap-4);
}

.c-form-input-field--width45 {
  width: 45.5%;
}

.c-form-input-field--width100 {
  width: 100%;
}

.c-form-input-field--with-star {
  label::after {
    color: getColor('blue');
    content: '*';
  }
}

.c-news-form__description {
  color: getTextColor('dark');
  font-size: 12px;
  font-weight: 420;
  margin: 0 var(--gap-4) 0 0;
  width: 100%;
}

.c-news-form__button {
  display: block;
  margin-left: auto;
}

.c-news-form__input-date {
  width: 245px;
}

.c-news-form-partner__news-style-dropdown > div > div > div > ol {
  z-index: 11;
}
