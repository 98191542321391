@import '~@sonnen/shared-web/abstracts';

.c-register-failure {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--content-height);
  text-align: center;
}

.c-register-failure__icon {
  display: inline-block;
  margin: var(--gap-2) 0;
  font-size: 70px;
  color: getColor('blue');
}

.c-register-failure__paragraph {
  padding: var(--gap-3) var(--gap-2);
  line-height: 1.4;
}

.c-register-failure__button {
  margin: var(--gap-1) 0 var(--gap-4);
}
