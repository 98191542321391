@import '~@sonnen/shared-web/abstracts';

.lead-configuration-protect {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-bottom: var(--gap-6);
}

.lead-configuration-protect__subheadline {
  font-size: 16px;
  font-weight: 420;
  margin-bottom: var(--gap-4);
}

.lead-configuration-protect__question {
  font-size: 14px;
  margin-bottom: var(--gap-3);
}

.c-lead-configuration-protect {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.lead-configuration-protect__radio-btns {
  margin-bottom: var(--gap-4);
}

.lead-configuration-protect__info-box {
  margin-bottom: var(--gap-1);
}

.flat-switch-modal__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: var(--gap-3) 0;
}

.flat-switch-modal__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flat-switch-modal__body-info {
  padding: var(--gap-1);
  text-align: center;
  max-width: 500px;
  font-size: 14px;
  line-height: 1.4;
}
