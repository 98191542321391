@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-tile-action {
  position: relative;
  display: block;
  margin: 0 0 0 var(--gap-2);
}

.c-lead-configuration-tile-action__button {
  display: block;
  font-size: 10px;
  font-weight: 420;
  color: getColor('blue');
  transition: fill 0.15s ease-in-out;

  &.is-disabled {
    color: getTextColor('grey');
    cursor: not-allowed;
  }
}

.c-lead-configuration-tile-action__icon {
  @include createIcon($_width: 15px, $_height: 16px, $_color: getColor('blue'));
  display: block;
  margin: 0 auto 4px;
  transition: fill 0.15s ease-in-out;

  > * {
    display: block;
  }

  .c-lead-configuration-tile-action__button.is-disabled & {
    @include createIcon($_width: 15px, $_color: getColor('grey-light'));
  }
}
