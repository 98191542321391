.battery__schedule__graph {
  position: relative;
  height: 80px;
  border-left: 1px dashed black;
  border-right: 1px dashed black;
  margin-top: 20px;
  margin-bottom: 20px;

  &--start_label {
    position: absolute;
    bottom: -14px;
    left: -14px;
  }

  &--end_label {
    position: absolute;
    bottom: -15px;
    right: -14px;
  }
}
