@import '~@sonnen/shared-web/abstracts';

.c-setup-tool__dso-summary {
  .c-form-banner--error {
    margin: var(--gap-3) var(--gap-10) 0;
    width: auto;

    @include breakpointDown(MD) {
      margin: var(--gap-3) var(--gap-4) 0;
    }
  }
}

.c-dso-registration-register-pv-captcha {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--gap-3);
}

.c-pv-register__checkbox {
  margin: var(--gap-2) 0 var(--gap-3);
}

.c-pv-register-go-to-setup-button {
    padding: 0;
}