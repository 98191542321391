@import '~@sonnen/shared-web/abstracts';

.dynamic-tariff {
  margin-bottom: 20px;

  .expanding {
    width: 100%;

    &__info {
      margin-bottom: 16px;
    }
    @include breakpointUp(MD) {
      width: 80%;
    }
  }
}
