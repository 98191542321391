@import "~@sonnen/shared-web/abstracts";

.c-setup-tool__dso-summary-status {
  color: getColor('green');
  margin-left: var(--gap-2);

  &--submit-success {
    font-size: 14px;
    margin-left: 0;
    margin-top: var(--gap-7);
    text-align: center;
  }
}

.c-dso-registration-summary__container {
  display: block;
  margin: var(--gap-1) 0;
  align-items: flex-start;
  justify-items: flex-start;

}

.c-dso-registration-summary__help {
  display: flex;
  margin-top: var(--gap-2);

  &--border {
    border-bottom: 1px solid getColor('grey-lightest');
    padding-bottom: var(--gap-4);
    margin-bottom: var(--gap-4);
  }

  p {
    @include typographyParagraph();
    margin-left: var(--gap-2);
    display: flex;
    align-items: center;
  }
  .c-dso-registration-summary__help-icon {
    display: flex;
    align-self: center;
    height: 22px;
  }
}

.c-dso-registration-go-to-setup-button {
  padding: 0;
}
