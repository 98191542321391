@import '~@sonnen/shared-web/abstracts';

.c-case-report-search-site {
  max-width: 630px;
  margin-bottom: var(--gap-4);
}

.c-case-report-search-site__label {
  margin: 0 0 var(--gap-1);
  font-weight: 420;
  font-size: 16px;
}
