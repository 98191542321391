@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-summary {
  --configuration-summary-padding-v: var(--gap-5);
  --configuration-summary-padding-h: var(--gap-3);

  @include breakpointDown(MD) {
    --configuration-summary-padding-v: var(--gap-3);
    --configuration-summary-padding-h: var(--gutter-layout);
  }
}

.c-lead-configuration-summary {
  @include boxShadow();
  position: relative;
  display: block;
  padding: var(--configuration-summary-padding-v) var(--configuration-summary-padding-h);
  background-color: getColor('grey-lightest');
  border-radius: 5px;

  @include breakpointDown(MD) {
    display: flex;
    flex-direction: column;
  }
}

.c-lead-configuration-summary--initial {
  @include breakpointDown(MD) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include breakpointDown(XS) {
    flex-direction: column;
  }
}

.c-lead-configuration-summary__header {
  @include breakpointDown(MD) {
    margin-right: var(--gap-1);
  }
}

.c-lead-configuration-summary__headline {
  font-size: 20px;
  font-weight: 420;
  margin-bottom: 12px;
}

.c-lead-configuration-summary__description {
  font-size: 16px;
  margin-bottom: var(--gap-3);
  line-height: 1.4;

  @include breakpointDown(MD) {
    font-size: 14px;
  }
}

.c-lead-configuration-summary__btn {
  display: flex;
  justify-content: center;

  @include breakpointDown(MD) {
    @include breakpointUp(XS) {
      justify-content: flex-end;
    }
  }
}

.c-lead-configuration-summary__separator {
  width: calc(100% + var(--gap-6));
  margin-top: var(--gap-5);
  margin-bottom: var(--gap-5);
  margin-left: calc(var(--gap-3) * (-1));
  border-top: 1px solid getColor('grey');

  @include breakpointDown(MD) {
    margin-top: var(--gap-2);
    margin-bottom: var(--gap-2);
  }
}

.c-lead-configuration-summary__loader {
  margin-bottom: var(--gap-3);
  --loader-size: var(--gap-3);
}

.c-lead-configuration-summary__loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 420;
  color: getTextColor('dark');
  background: rgba(getColor('white'), 0.8);
  border-radius: 5px;
  z-index: 9;
}


.c-lead-configuration-summary__vat {
  margin-top: var(--gap-2);
}
