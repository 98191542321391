@import '~@sonnen/shared-web/abstracts';

.details-list-table {
  &__key {
    margin: 4px 0;
    max-width: calc(var(--width) * 130%);
    padding-right: var(--gap-1);
  }

  &__value {
    margin: 4px 0;
    flex: 1 1 100%;
    white-space: pre-wrap;
    justify-content: flex-end;
    align-items: flex-start;
    word-break: break-word;

    .label-medium {
      width: 100%;
    }

    &--info {
      color: getTextColor('blue');
    }

    &--success {
      color: getColor('green');
    }

    &--warning {
      color: getColor('yellow');
    }

    &--danger {
      color: getTextColor('red');
    }
  }
}

.sw-table-cell--grid {
  flex: none;
  flex-basis: calc(var(--width) * 130%);
}
