@import '~@sonnen/shared-web/abstracts';

$_crosshair-size: 26px;
$_crosshair-handle-overlay-fraction: 1 / 3;
$_crosshair-z-index: var(--z-index-tooltip - 1);

.c-analysis-chart-crosshair {
  --x: 0;
  --offset: 0;
  --desktopHeight: 400px;
  --mobileHeight: 250px;
}

.c-analysis-chart-crosshair {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: $_crosshair-z-index;
  width: 0;
  bottom: calc(#{$_crosshair-size * (-(.5 + (1 - $_crosshair-handle-overlay-fraction)))} - var(--offset) * 1px);
  left: calc(var(--x) * 1px);
  pointer-events: none;
  cursor: pointer;

  &.is-hidden {
    opacity: 0;
  }
}

.c-analysis-chart-crosshair__line {
  width: 1px;
  height: var(--mobileHeight);
  margin-bottom: $_crosshair-size * -.5;
  opacity: .5;
  background: getColor('grey-darkest');

  @include breakpointUp(SM) {
    height: var(--desktopHeight);
  }
}

.c-analysis-chart-crosshair__handle-hitbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $_crosshair-size * 2;
  height: $_crosshair-size * 2;
  pointer-events: none;
}

.c-analysis-chart-crosshair__handle {
  width: $_crosshair-size;
  height: $_crosshair-size;
  border: 2px solid getColor('blue');
  background: getColor('white');
  border-radius: 50%;
}
