@import '../../../../../../node_modules/@sonnen/shared-web/abstracts';

.c-battery-time-of-use-form {

  &__container {
    padding-bottom: var(--gap-3);
  }

  &__radio-group {

    .c-form-input-radio-group__item {
      margin: 6px 0;
    }

    .c-form-input-radio-group__text {
      color: #0A1535;
      font-weight: 420;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 10px;
    }

    .c-form-input-radio-label {
      font-weight: 390;
      font-size: 14px;
      line-height: 20px;
      color: getTextColor('dark');
    }

    .c-form-input-radio-circle {
      width: 20px;
      height: 20px;
      margin: 0 6px 0 0;

      &::before {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__edit-charge-state {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: var(--gap-2) 0;
  }

  &__edit-charge-state-description {
    font-weight: 420;
    font-size: 14px;
    line-height: 20px;
  }

  &__edit-charge-value-container {
    width: 70%;
  }

  &__edit-charge-value-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 420;
      font-size: 14px;
      color: #0A1535;
      margin: 0;
    }
  }

  &__time-window {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 30px;
    align-items: center;
    justify-content: flex-start;

    &-header {
      font-weight: 420;
      font-size: 14px;
      line-height: 20px;
      color: #0A1535;
    }

    &-separation {
      margin: auto 5px;
    }

    &-label {
      width: 50px;
      height: 100%;
      margin: 0;
    }

    &-input {
      width: 145px;
      text-align: center;
    }

    &-header-detail {
      font-weight: 420;
      font-size: 14px;
      line-height: 20px;
      margin-left: 5px;
      color: getTextColor('grey');
    }

    &-detail {
      width: 20px;
      margin: 0 26px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        background: #61687A;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .c-form-input-select {
      width: 30%;
    }

    .c-form-input {
      margin: 0;
    }
  }
}
