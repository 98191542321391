@import '~@sonnen/shared-web/abstracts';
// @TODO: Remove as soon as new colors are implemented
@import '~@sonnen/shared-web/src/colors/_color-base.scss';

.c-form-input-radio {
  margin-right: var(--gap-3);

  @include createRadioInput(
    $_size: 20px,
    $_circle-size: 14px,
    $_font-size: 14px,
    $_color: getTextColor('dark'),
    $_background: getColor('white'),
    $_border-color: getColor('grey-light'),
  );
}

.c-form-input-radio-radio:disabled + .c-form-input-radio-label {
  cursor: not-allowed;

  .c-form-input-radio-circle {
    background-color: getColor('grey-lightest');
    cursor: not-allowed;
  }
}

.c-form-input-radio__text {
  padding-top: var(--gap-1);
  padding-left: var(--gap-3);
  font-size: 14px;
  font-weight: 390;
}
