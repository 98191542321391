@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-document-modal {
  @include breakpointUp(SM) {
    > .c-modal__dialog {
      height: 200px;
    }
  }

  &__icon-and-text {
    text-align: center;
  }
}

.c-lead-overview-document-modal__inner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.c-lead-overview-document-modal__content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: var(--gap-2);

  &--link {
    cursor: pointer;
  }
}

.c-lead-overview-document-modal__icon {
  width: 40px;
  fill: getColor('blue');
}

.c-lead-overview-document-modal__paragraph {
  margin: var(--gap-2) 0 var(--gap-2) var(--gap-2);
  font-size: 20px;
  color: getTextColor('grey');

  .c-lead-overview-document-modal__content--link & {
    color: getTextColor('blue');
  }
}
