@import '~@sonnen/shared-web/abstracts';

@mixin borderGradient($color: getColor('grey-light'), $size: 3px, $length: 25px) {
  background-image: linear-gradient(to right, $color 50%, transparent 50%),
                    linear-gradient(to right, $color 50%, transparent 50%),
                    linear-gradient(to bottom, $color 50%, transparent 50%),
                    linear-gradient(to bottom, $color 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: $length $size, $length $size, $size $length, $size $length;
}

.c-form-upload-input {
  display: flex;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  width: 100%;
  padding: var(--gap-4) 0;

  &--with-checkbox {
    padding-bottom: 0;
  }

  &--collapsed {
    transition: max-height 1s, opacity 0.5s;
    max-height: 0;
    opacity: 0;
  }

  &--expanded {
    transition: max-height 1s, opacity 0.5s;
    max-height: 500px;
    opacity: 1;
  }
}

.c-form-upload-input__content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.c-form-upload-input__drag-area {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: var(--gap-4);
  background: getColor('grey-lightest'); //TODO change color designer
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: getColor('grey');
  font-size: 18px;
  outline: none;
  @include borderGradient(getColor('grey-light'));

  &.c-form-upload-input__drag-area--active, &:hover, &:focus {
    @include borderGradient(getColor('blue'));
  }
}

.c-form-upload-input__files {
  align-items: flex-start;
  justify-items: flex-start;

  &--multiple {
    color: getTextColor('grey');
    font-size: 16px;
    padding-top: var(--gap-3);
  }
}

.c-form-upload-input__file-container {
  display: flex;
  align-items: center;
}

.c-form-upload-input__filename {
  font-size: 16px;
  color: getTextColor('grey');
  padding: var(--gap-3) 0;

  &--multiple {
    padding: var(--gap-2) 0;
  }
}

.c-form-upload-input__title {
  display: block;
  text-align: center;
  color: getTextColor('blue');
}

.c-form-upload-input__placeholder {
  margin-top: 10px;
  color: getTextColor('grey');
}

.c-form-upload-input__upload-icon {
  @include createIcon(
    $_width: 40px,
    $_color: getColor('blue'),
  );

  padding-top: var(--gap-1);
  transform: rotate(180deg);
}

.c-form-upload-input__remove-icon {
  @include createIcon(
    $_width: 15px,
    $_color: getColor('grey'),
  );
}

.c-form-upload-input__tick-icon {
  @include createIcon(
    $_width: 18px,
    $_color: getColor('white'),
  );

  background-color: getColor('blue');
  height: 18px;
  border-radius: 50%;
  padding: 4px;
  margin-left: var(--gap-1);
  margin-right: var(--gap-2);
}

.c-form-upload-input__row {
  border-bottom: 0.5px solid map-get($infographic-colors, getColor('grey-light'));
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }
}

.c-form-upload-input__field--error {
  border-color: getColor('red');
  color: getTextColor('red');

  &:hover {
    border-color: getColor('red');
  }
}

.c-form-upload-input__error {
  height: 21px;
  padding-top: var(--gap-1);
}

.c-form-upload-input__error-message {
  color: getTextColor('red');
  font-size: 12px;
}

.c-form-upload-input__headline {
  color: getTextColor('grey');
  font-weight: 420;
  font-size: 13px;
}

.c-form-upload-input__text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.c-form-upload-input--with-placeholder {
  .c-form-upload-input__drag-area {
    min-height: 113px;
    padding-bottom: var(--gap-1);
    padding-top: var(--gap-2);

    @include borderGradient(getColor('grey-light'), 1px, 15px);

    &.c-form-upload-input__drag-area--active, &:hover, &:focus {
      @include borderGradient(getColor('blue'), 1px, 15px);
    }
  }

  .c-form-upload-input__upload-icon {
    @include createIcon(
      $_width: 25px,
      $_color: getColor('blue'),
    );
  }

  .c-form-upload-input__title {
    font-size: 16px;
    font-weight: 420;
  }

  .c-form-upload-input__placeholder {
    font-size: 14px;
    font-weight: 300;
    margin-top: 8px;
  }
}
