@import '~@sonnen/shared-web/abstracts';

.c-help {
  --accordion-vertical-gap: var(--gap-2);
  padding-top: var(--gap-4);
  padding-bottom: var(--gap-4);

  @include breakpointUp(XLG) {
    padding-top: calc(var(--gutter-v) * 2);
    padding-bottom: calc(var(--gutter-v) * 2);
  }
}

.c-help__content {
  display: flex;
  flex-flow: column-reverse;

  @include breakpointUp(XS) {
    flex-flow: row;
  }
}

.c-help__section {
  flex: 1;
  list-style-type: none;

  @include breakpointUp(XS) {
    width: calc(50% - var(--gap-4));
  }
}

.c-help__spacer {
  flex: 0 0 var(--gap-8);
}

.c-help__side-info {
  margin-top: var(--gap-2);

  p {
    margin-top: var(--gap-2);
  }

  @include breakpointUp(XS) {
    margin-top: var(--gap-3);
  }
}

