@import '~@sonnen/shared-web/abstracts';

.c-impact-analysis-wizard-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: var(--gap-1) var(--gap-3);
}

.c-impact-analysis-wizard-footer__navigation-prev {
  margin-right: var(--gap-4);

  @include breakpointUp(XS) {
    margin-right: var(--gap-8);
  }
}
