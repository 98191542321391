@import '/src/app/designSystem/styles/';

$_bounce: 5px;
$_total: 3;
$_animation-time-ms: 1000;
$_delay-ms: ($_animation-time-ms / 2) / -$_total;
$_animation-delay-ms: $_animation-time-ms + $_delay-ms;

@keyframes dotAnimation {
  0%,
  100% {
    opacity: 1;
    transform: translateY($_bounce);
  }

  80% {
    opacity: 0.1;
    transform: translateY(-$_bounce);
  }
}

.loader-default-class {
  --loader-size-protected: var(--loader-size, 8px);
  height: 20px;
}

.loader__dot {
  display: inline-block;
  width: var(--loader-size-protected);
  height: var(--loader-size-protected);
  margin: 0 calc(var(--loader-size-protected) / 2);
  border-radius: 50%;
  background-color: $blue_500;
  animation: dotAnimation #{$_animation-time-ms}ms ease-in-out infinite;
}

@for $_i from $_total through 1 {
  .loader__dot:nth-of-type(#{$_i}) {
    animation-delay: -#{($_i - 1) * $_animation-delay-ms}ms;
  }
}
