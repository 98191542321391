@import '~@sonnen/shared-web/abstracts';

.c-lead-impact-analysis {
  display: block;
}

.c-lead-impact-analysis {
  --ia-input-width: 360px;
  --ia-column-space: var(--gap-4);
  --ia-column-width: calc(50% - var(--ia-column-space) / 2);
}

.c-lead-impact-analysis__step {
  @include borderRadius('big');
  @include boxShadow();
  background-color: getColor('white');

  .sw-card {
    box-shadow: none;
  }
}

.c-lead-impact-analysis__wizard-header-wrapper {
  padding: 0 var(--gutter-card);
}

.c-lead-impact-analysis__wizard-header {
  padding-top: var(--gap-6);
}
