@import '~@sonnen/shared-web/abstracts';

.c-login-loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: var(--gap-3) var(--gap-2);
}

.c-login-loader__headline {
  text-align: center;
  font-size: 18px;
  color: getTextColor('grey');
}
