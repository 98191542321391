@import '~@sonnen/shared-web/abstracts';

.c-footer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  padding: var(--gutter-v) 0;
  background-color: getColor('grey-lightest');
  color: getTextColor('dark');

  @include breakpointDown(MD) {
    padding: var(--gutter-v) 0;
  }

  @include breakpointDown(SM) {
    height: var(--footer-height);
    padding: 0;
  }
}

.c-footer--transparent {
  color: getColor('white');
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, rgba(getColor('grey-darkest'), 0.85), transparent);
  }
}

.c-footer__container {
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  align-items: stretch;
  z-index: 1;

  @include breakpointUp(NAV) {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }
}

.c-footer__copyright {
  color: getTextColor('dark');

  .c-footer--transparent & {
    color: getColor('white');
  }
}

.c-footer__links-container {
  display: flex;
  flex-flow: column-reverse;
  align-items: stretch;

  @include breakpointUp(SM) {
    flex-flow: row;
  }
}

.c-footer__socials {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpointUp(XLG) {
    padding-left: calc(var(--gutter-v) / 4);
  }
}

.c-footer__nav {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding: calc(var(--gutter-v) / 2) 0;

  @include breakpointUp(SM) {
    flex-flow: row nowrap;
  }

  @include breakpointUp(XLG) {
    padding: 0;
  }
}
