@import '~@sonnen/shared-web/abstracts';

$_checkbox-size: 20px;
$_icon-size: 14px;
$_transition: 150ms ease 50ms;

.basic-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;

  & > input[type='checkbox'] {
    width: $_checkbox-size;
    height: $_checkbox-size;
    margin-right: 10px;
    visibility: hidden;
  }
  &__styled-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    // this positions the checkbox aligned on the horizontal axis with its label,
    // so that label can receive padding an checkbox is still vertically aligned
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: $_checkbox-size;
    width: $_checkbox-size;
    background-color: getColor('white');
    border: 1px solid getColor('grey-light');
    border-radius: 4px;
    transition: background-color $_transition;

    & > svg {
      transform: scale(0);
      width: $_icon-size;
      height: $_icon-size;
    }
  }

  & > input:checked ~ &__styled-checkbox {
    background-color: getColor('blue');
    border-color: getColor('blue');
    transition: background-color $_transition;

    & > svg {
      stroke: getColor('white');
      transform: scale(1);
      transition: transform $_transition;
    }
  }

  & > input:disabled ~ &__styled-checkbox {
    background-color: getColor('grey-lighter');
    border-color: getColor('grey-light');
    cursor: not-allowed;
  }
}
