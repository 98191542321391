@import '~@sonnen/shared-web/abstracts';

.c-dso-registration-form-radio-group {
  display: flex;
  align-items: center;
  margin: var(--gap-2) 0 var(--gap-3);
  width: 100%;

  @include breakpointUp(XS) {
    min-height: 44px;
    margin: var(--gap-2) 0 var(--gap-4);
  }

  &--hidden {
    display: none;
  }

  &--controlling-section {
    margin: 0 0 var(--gap-5);
  }

  &--controlling-subsection {
    margin: var(--gap-3) 0 var(--gap-2);
  }

  .c-form-input-radio-group__text {
    color: getTextColor('dark');
    font-size: 16px;
    margin-bottom: var(--gap-3);
  }
}
