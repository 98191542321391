@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-dso-validation-modal {
  --modal-layout-body-padding-v: 0px;
  --modal-layout-body-padding-h: var(--gap-4);

  @include breakpointUp(SM) {
    --modal-layout-body-padding-h: var(--gap-10);
  }
}

.c-lead-overview-dso-validation-modal__content {
  height: 100vh;

  @include breakpointUp(SM) {
    height: 85vh;
    max-height: 500px;
  }
}

.c-lead-overview-dso-validation-modal__form {
  width: 100%;
  height: 100%;
}

.c-lead-overview-dso-validation-modal__buttons {
  width: 100%;
  margin-bottom: var(--gap-3);
  padding: var(--gap-2) 0;

  @include breakpointUp(XS) {
    margin-bottom: 0;
  }

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-3) 0;
  }
}

.c-lead-overview-dso-validation-modal__buttons-wrapper {
  display: flex;
  flex-direction: row;

  @include breakpointDown(SM) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}

.c-lead-overview-dso-validation-modal__footer-button {
  margin-bottom: var(--gap-3);
  padding: 0;
  display: inline-block;

  @include breakpointUp(SM) {
    margin-right: var(--gap-7);
    margin-bottom: unset;
  }
}

.c-lead-overview-dso-validation-modal__radio-group {
  margin-top: var(--gap-2);
}

.c-lead-overview-dso-validation-modal__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 9;
}

.c-lead-overview-dso-validation-modal__error,
.c-lead-overview-dso-validation-modal__info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.c-lead-overview-dso-validation-modal__info {
  display: flex;
  align-items: center;
  margin: var(--gap-6) var(--modal-layout-body-padding-h);
}

.c-lead-overview-dso-validation-modal__info-icon {
  @include createIcon($_width: 21px, $_color: getColor('blue'));
  margin-right: var(--gap-2);
}
