@import '~@sonnen/shared-web/abstracts';

.c-support-form__title {
  margin: var(--gap-4) 0 var(--gap-2);
}

.c-support-form__input-title {
  margin-bottom: var(--gap-1);
  font-weight: 420;
}

.c-form-input-select {
  margin-bottom: var(--gap-1);
}

.c-support-form__hint {
  margin-bottom: var(--gap-2);
}

.c-support-form__hint--error {
  font-weight: 420;
  color: getTextColor('red');
}

.c-support-form__hint--success {
  font-weight: 420;
  color: getTextColor('green');
}

.c-support-form__upload-input {
  padding: 0 0 var(--gap-3);
}

.c-support-form__upload-input .c-form-upload-input__drag-area {
  max-height: 140px;
  margin: 0 auto;
}

.c-support-form__upload-input .c-form-upload-input__files {
  max-width: 100%;
  max-height: 100%;
}

.c-support-form__category {
  margin: var(--gap-3) 0;
}