@import '~@sonnen/shared-web/abstracts';

.c-missing-battery-claim-success__banner {
  margin: var(--gap-1) 0;
}

.c-missing-battery-claim-success__padlock-icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: getColor('grey');
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.c-missing-battery-claim-success__padlock-icon {
  @include createIcon(
    $_width: 12px,
    $_color: getColor('white')
  );
}

.c-missing-battery-claim-success__done-icon {
  @include createIcon(
    $_width: 20px,
  );
}

.c-missing-battery-claim-success__field {
  display: flex;
  flex-direction: column;
  padding-top: var(--gap-2);
}

.c-missing-battery-claim-success__field-label {
  font-size: 14px;
  font-weight: 420;
  color: getTextColor('dark');
  padding-bottom: var(--gap-1);
}

.c-missing-battery-claim-success__field-value {
  font-size: 14px;
  font-weight: 300;
}
