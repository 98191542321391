@import '/src/app/designSystem/styles/';

.tooltip-on-status-icon {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  & > .icon {
    height: 20px;
  }

  &__content {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &--title {
      margin-bottom: 6px;
    }

    &--button {
      margin-top: 12px;
      float: right;
    }
  }
}

.reusable-class__tooltip.tooltip-status-column {
  padding: 16px;
  max-width: 256px;
}