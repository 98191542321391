@import '~@sonnen/shared-web/abstracts';

.c-lead-hardware-status-modal {
  @include breakpointUp(SM) {
    --modal-layout-body-padding-h: var(--gap-9);
  }
}

.c-lead-hardware-status-modal {
  height: 100vh;
  cursor: default;

  .sw-modal-layout__title {
    font-size: 20px;
    font-weight: normal;
  }

  @include breakpointUp(SM) {
    height: 100%;
  }
}

.c-lead-hardware-status-modal__wrapper {
  .sw-modal__dialog {
    @include breakpointUp(SM) {
      width: 720px;
      max-height: 635px;
    }
  }

  .sw-modal-layout__body {
    overflow-y: scroll;
  }
}

.c-lead-hardware-status-modal-form {
  width: 100%;
  height: 100%;
}

.c-lead-hardware-status-modal__footer-button {
  padding: 0;
  display: inline-block;
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    margin-right: var(--gap-7);
    margin-bottom: 0;
  }
}

.c-lead-hardware-status-modal__footer-button--disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.c-lead-hardware-status-modal__footer {
  width: 100%;
  padding: var(--gap-3) 0;

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-3) 0;
  }
}

.c-lead-hardware-status-modal__buttons-wrapper {
  display: flex;
  @include breakpointDown(SM) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}

.c-lead-hardware-status-modal__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 2;
}

.c-lead-hardware-status-modal__status-name {
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 420;
  margin-bottom: calc(var(--gap-1) / 4);
}

.c-lead-hardware-status-modal__status-owner {
  color: getTextColor('grey');
  font-size: 12px;
  margin: 0;
  padding-left: calc(var(--gap-1) + var(--gap-1) / 4);
  position: relative;

  &::before {
    background-color: getColor('grey');
    border-radius: 50%;
    content: '';
    height: calc(var(--gap-1) / 2);
    left: 0;
    position: absolute;
    top: calc(50% - var(--gap-1) / 4);
    width: calc(var(--gap-1) / 2);
  }
}

.c-lead-hardware-status-modal__status-owner--bold {
  font-weight: 420;
}

.c-lead-hardware-status-modal__widget-wrapper {
  margin-bottom: var(--gap-3);
}
