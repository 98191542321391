@import '~@sonnen/shared-web/abstracts';

.c-widget-warning {
  --widget-warning-color: #{getTextColor('dark')};
}

.c-widget-warning {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.c-widget-warning--success {
  --widget-warning-color: #{getColor('green')};
}

.c-widget-warning--warning {
  --widget-warning-color: #{getColor('yellow')};
}

.c-widget-warning--danger {
  --widget-warning-color: #{getColor('red')};
}

.c-widget-warning--info {
  --widget-warning-color: #{getColor('blue')};
}

.c-widget-warning__content {
  font-size: 14px;
  font-weight: 420;
  color: var(--widget-warning-color);
}

.c-widget-warning__icon {
  margin-right: var(--gap-1);
  font-size: 20px;
  color: var(--widget-warning-color);
}
