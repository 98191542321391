@import '~@sonnen/shared-web/abstracts';

.c-lead-hardware-form {
  --hardware-input-width: 360px;
  --hardware-column-space: var(--gap-4);
}

.c-lead-hardware-form {
  flex: 1;
}

.c-lead-hardware-form__button-wrapper {
  margin: 0 var(--gutter-layout);

  @include breakpointUp(SM) {
    align-self: flex-end;
  }

  @include breakpointUp(MD) {
    margin: 0 var(--gutter-card);
  }
}

.c-lead-hardware-form__container {
  padding: 0 calc(var(--gutter-layout) - var(--gutter-card));

  @include breakpointUp(MD) {
    padding: 0;
  }
}

.c-lead-hardware-form__title {
  font-size: 20px;
  font-weight: 420;
  margin-top: var(--gap-1);
}

.c-lead-hardware-form__subtitle {
  font-size: 14px;
  margin: var(--gap-2) 0 var(--gap-6) 0;
}

.c-lead-hardware-form__subheadline {
  font-size: 16px;
  font-weight: 420;
  margin-bottom: var(--gap-4);
}

.c-lead-hardware-form__columns {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-4);

  @include breakpointUp(SM) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.c-lead-hardware-form__column {
  margin-bottom: var(--gap-2);
  @include breakpointUp(SM) {
    width: var(--hardware-input-width);

    &:first-child {
      margin-right: var(--hardware-column-space);
    }
  }
}


.c-lead-hardware-form__input {
  max-width: var(--hardware-input-width);
  margin-bottom: var(--gap-1);
}
