@import "~@sonnen/shared-web/abstracts";

.c-video-thumbnail {
  position: relative;

  &:hover {
    opacity: .9;

    .c-video-thumbnail__play-icon {
      @include createIcon($_width: 55px, $_color: getColor('blue'));
    }

  }
}

.c-video-thumbnail__image {
  min-height: 200px;
  width: 100%;
}

.c-video-thumbnail__tip {
  padding: 4px;
  border-radius: var(--border-radius);
  background: rgba(getColor('white'), 0.8);
  font-size: 12px;
  font-weight: 420;
}


.c-video-thumbnail__category {
  position: absolute;
  top: var(--gap-2);
  left: var(--gap-2);
}

.c-video-thumbnail__length {
  position: absolute;
  right: var(--gap-2);
  bottom: var(--gap-2);
}

.c-video-thumbnail__play-icon {
  @include createIcon($_width: 55px, $_color: getColor('white'));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}