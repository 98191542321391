@import "~@sonnen/shared-web/abstracts";

.c-form-field-label {
  display: block;
  position: relative;
  margin: 0 0 var(--gap-1);
  font-size: 14px;
  font-weight: 420;
  color: getTextColor('dark');
}

.c-form-field-label--disabled {
  color: getTextColor('grey');
}

.c-form-field-label__star {
  position: absolute;
  top: calc(var(--gap-1)*(-1));
  right: -10px;
  font-size: 18px;
  color: getColor('blue');
}
