@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-info {
  @include boxShadow();
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: 16px;
  margin-bottom: var(--gap-4);
  padding: var(--gap-3);
  justify-content: space-between;
  line-height: normal;
  position: relative;

  &::before {
    background-color: getColor('grey-light');
    border-radius: 4px 0 0 4px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
  }

  .c-setup-tool-info__content {
    align-items: center;
    display: flex;
  }

  .c-setup-tool-info__icon {
    min-width: 24px;
    max-width: 24px;
  }

  .c-setup-tool-info__text {
    font-size: 12px;
    margin: 0 var(--gap-4) 0 var(--gap-2);
  }

  .c-setup-tool-info__button {
    height: calc(var(--btn-height) - calc(var(--gap-1)*1.5));
    min-width: inherit;

    .sw-button__label {
      padding: 0 calc(var(--gap-2));
      white-space: nowrap;
    }

    .sw-button__theme-provider::after {
      border-width: 1px;
    }
  }
}
