@import "~@sonnen/shared-web/abstracts";

.c-dso-registration-installer__form-header {
  font-size: 26px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: calc(var(--gap-1)*2.75);
}

.c-dso-registration-installer-data__item {
  width: 45.5%;

  .sw-select__list {
    max-height: 150px;
  }
}

.c-dso-registration-installer-data__item--options {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-dso-registration-installer-options__icon {
  @include createIcon($_width: 18px, $_color: getColor('blue'));

  margin-right: calc(var(--gap-1)*1.5);

  &:hover {
    @include createIcon($_width: 18px, $_color: lighten(getColor('blue'), 15%));
  }
}

.c-dso-registration-installer-options__item {
  align-items: center;
  display: flex;
  font-weight: 420;
  padding: 1px 0;
  margin: var(--gap-1) 0;

  &:hover {
    color: lighten(getColor('grey-darkest'), 25%);
  }
}

.c-dso-registration-installer-options__item--empty-list {
  margin-top: 34px;
}

.c-dso-registration-installer-options__form-banner-icon {
  @include createIcon(
    $_width: 20px,
  );
}
