@import '~@sonnen/shared-web/abstracts';

.inbox-lead-list-rable-radio-btn {
  width: 100%;

  @include createRadioInput(
    $_size: 16px,
    $_circle-size: 8px,
    $_font-size: 13px,
    $_color: getTextColor('dark'),
    $_background: getColor('white'),
    $_border-color: getColor('grey-light')
  );

  &-circle {
    top: 50%;
    right: 0;
    left: unset;
    transform: translateY(-50%);
  }

  &-label {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: -16px -16px -16px 0;
    padding: 16px 0;
    display: flex;
  }
}

.inbox-lead-list-rable-radio-btn__cell {
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  margin: 0 var(--gap-2) var(--gap-1) 0;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-bottom: 0;
  }
}

.inbox-lead-list-rable-radio-btn__address {
  @include breakpointDown(XS) {
    margin-left: var(--gap-7);
  }
}
