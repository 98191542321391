@import '/src/app/designSystem/styles/';

.c-layout__content-only {
  .controls-menu__sticky {
    top: 0;
  }
}

.controls-menu__sticky {
  position: sticky;
  z-index: var(--z-index-controls-menu);
  background-color: $white;
  top: $_NAVBAR_HEIGHT_TABLET;
  margin-top: 28px;
  margin-bottom: 8px;

  @include breakpoint('desktop') {
    top: $_NAVBAR_HEIGHT_DESKTOP;
  }
}
.drop-shadow {
  @include boxShadow($_side: 'bottom', $_color: $grey_200);
}

.wrapper-for-loader-and-table {
  width: 100%;
  position: relative;
}
