@import '~@sonnen/shared-web/abstracts';

$_box-size: var(--gap-2);
$_transition: 200ms ease 100ms;

.status-checkbox {
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;

    &:checked + .status-checkbox__label {
      .status-checkbox__box {
        background-color: getTextColor('blue');
        border-color: getTextColor('blue');
      }
    }
    &:not(:checked) + .status-checkbox__label {
      .status-checkbox__box {
        background-color: getColor('white');
      }
    }
    &:disabled + .status-checkbox__label {
      cursor: default;
      .status-checkbox__box {
        margin-left: 2px;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  &__text {
    font-size: 12px;
    color: getColor('grey-darkest');
    line-height: $_box-size;
  }

  &__box {
    @include borderRadius();
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0;
    min-width: $_box-size;
    height: $_box-size;
    margin-right: var(--gap-2);
    border: 1px solid getColor('grey');
    background: getColor('blue');
    transition: border-color $_transition, background-color $_transition;

    &--automatic,
    &--status-set {
      background: none!important;
      border: none!important;
    }
    &--automatic {
      margin-right: calc(var(--gap-2) - 2px);
    }
  }

  &__box-icon {
    @include createIcon($_width: 10px, $_height: 10px, $_color: getColor('white'));
    transition: fill $_transition, transform $_transition;

    &--automatic {
      @include createIcon($_width: 21px, $_height: 20px, $_color: getColor('blue'));
      transform: none;
    }
    &--status-set {
      @include createIcon($_width: 16px, $_height: 16px, $_color: getColor('green'));
    }
  }
}
