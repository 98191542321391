@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-registration-header {
  @include boxShadow();
  background-color: getColor('white');
  border-radius: 3px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  padding: 17px var(--gap-7);
  position: relative;
  z-index: 1;
}

.c-setup-tool-registration-header__text {
  font-size: 14px;
  font-weight: 390;
  color: getTextColor('grey');
  margin-bottom: 0;
  text-align: center;
}

.c-setup-tool-registration-select__buttons {
  border-radius: 0 0 3px 3px;
  display: flex;
  justify-content: space-between;
  padding: var(--gap-4) var(--gap-1) var(--gap-2);
  width: 100%;
}

.c-setup-tool-registration-select__button {
  align-items: center;
  background-color: getColor('white');
  border: 1px solid getColor('grey-darkest');
  color: getTextColor('dark');
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  transition: transform 0.3s ease;
  width: 48%;

  &:hover {
    transform: translateY(-2px);
  }

  &--disabled {
    border: 1px solid getColor('grey-light');
    box-shadow: initial;
    color: getTextColor('grey');
    cursor: initial;

    &:hover {
      transform: initial;
    }

    .c-setup-tool-registration-select__icon {
      fill: getColor('grey');
    }
  }

  &--active {
    border: 1px solid getColor('blue');
    color: getTextColor('blue');

    .c-setup-tool-registration-select__icon {
      fill: getColor('blue');
    }
  }
}

.c-setup-tool-registration-select__icon {
  height: 21px;
}

.c-setup-tool-registration-select__button-text {
  font-size: 16px;
  font-weight: 420;
  margin-top: 10px;
}
