@import '~@sonnen/shared-web/abstracts';

.c-tariff-window-list-item__body {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid #D3D5D8;

  &:first-of-type {
    border: none;
  }
}

.c-tariff-window-list-item__header {
  display: flex;
  flex-direction: row;
  font-weight: 420;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 8px;
}

.c-tariff-window-list-item__header-marker {
  display: block;
  width: 14px;
  height: 14px;
  background-color: $yellow_200;
  margin-right: 9px;
}

.c-tariff-window-list-item__header-marker.offPeak-color {
  background-color: $green_200;
}

.c-tariff-window-list-item__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #61687A;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.c-tariff-window-list-item__content-details {
  flex: 1;
}

.c-tariff-window-list-item__content-details p {
  margin: 3px 0;
}

.c-tariff-window-list-item__content-details p span{
  width: 20%;
  display: inline-block;
}

.c-tariff-window-list-item__content-actions-button {
  min-width: 50px;
  --btn-font-color: var(--btn-color-dark);
}

.c-tariff-window-list-item__content-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.c-tariff-window-list-item__edit-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--gap-2) 0 0;
}
.c-tariff-window-list-item__edit-info-icon {
  width: var(--gap-3);
  height: var(--gap-3);
}
.c-tariff-window-list-item__edit-info-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
