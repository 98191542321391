@import "~@sonnen/shared-web/abstracts";

$_box-size: var(--gap-2);
$_transition: 150ms ease 50ms;

.c-form-controlled-checkbox {
  // stylelint-disable-next-line length-zero-no-unit
  --form-checkbox-margin-bottom-protected: var(--form-checkbox-margin-bottom, 0px);
  --form-checkbox-color-protected: var(--form-checkbox-color, #{getTextColor('dark')});
  --form-checkbox-box-background-color: transparent;
  --form-checkbox-box-border-color: #{getColor('grey')};
  --form-checkbox-box-connection-line-color: #{getColor('grey')};
  --form-checkbox-box-icon-color: #{getColor('white')};
  --form-checkbox-box-icon-scale: 0;
  --form-checkbox-margin-top: var(--gap-2);
  --form-checkbox-margin-bottom: var(--gap-3);
}

.c-form-controlled-checkbox--error {
  --form-checkbox-color-protected: var(--form-checkbox-color-error, #{getColor('red')});
  --form-checkbox-box-background-color: transparent;
  --form-checkbox-box-border-color: #{getColor('red')};
  --form-checkbox-box-icon-color: #{getColor('white')};
  --link-color: #{getColor('red')}; // 1
}

.c-form-controlled-checkbox--connection-active {
  --form-checkbox-box-connection-line-color: #{getColor('blue')};
}

.c-form-controlled-checkbox {
  margin: var(--form-checkbox-margin-top) 0 var(--form-checkbox-margin-bottom);
}

.c-form-controlled-checkbox--connected {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: $_box-size;
    left: calc(#{$_box-size} / 2);
    display: block;
    width: 1px;
    height: calc(100% - #{$_box-size} + var(--form-checkbox-margin-bottom-protected));
    border-left: 1px solid var(--form-checkbox-box-connection-line-color);
    transition: border-color $_transition;
  }
}

.c-form-controlled-checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.c-form-controlled-checkbox__label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.c-form-controlled-checkbox__input:checked
  + .c-form-controlled-checkbox__label {
  --form-checkbox-box-background-color: #{getColor('blue')};
  --form-checkbox-box-border-color: #{getColor('blue')};
  --form-checkbox-box-icon-scale: 1;
}

.c-form-controlled-checkbox__input:disabled
  + .c-form-controlled-checkbox__label {
  --form-checkbox-color: #{getTextColor('grey')};
  --form-checkbox-box-background-color: #{getColor('grey')};
  --form-checkbox-box-border-color: transparent;
  cursor: not-allowed;
}

.c-form-controlled-checkbox__box {
  @include borderRadius();
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 $_box-size;
  width: $_box-size;
  height: $_box-size;
  border: 1px solid var(--form-checkbox-box-border-color);
  background: var(--form-checkbox-box-background-color);
  transition: border-color $_transition, background-color $_transition;
}

.c-form-controlled-checkbox__box-icon {
  @include createIcon(
    $_width: 10px,
    $_height: 10px,
    $_color: var(--form-checkbox-box-icon-color)
  );
  transform: scale(var(--form-checkbox-box-icon-scale));
  transition: fill $_transition, transform $_transition;
}

.c-form-controlled-checkbox__text {
  margin-left: var(--gap-2);
  font-size: 12px;
  color: var(--form-checkbox-color-protected);
  line-height: $_box-size;
  user-select: none;
  transition: color $_transition;

  // 1
  a:not([class]) {
    // stylelint-disable-line selector-max-type
    color: getTextColor('blue');
  }
}
