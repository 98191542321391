@import '~@sonnen/shared-web/abstracts';

.c-help-contact {
  position: relative;
}

.c-help-contact__side-info {
  margin-top: var(--gap-2);

  @include breakpointUp(XS) {
    margin-top: var(--gap-3);
  }

  a:hover {
    text-decoration: underline;
  }
}

.c-help-contact__loader {
  padding: var(--gap-4) 0;
}

.c-help-contact__side-info .sw-side-info__content p {
  margin-top: var(--gap-2);
}
