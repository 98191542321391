@import '~@sonnen/shared-web/abstracts';

.c-widget-simple {
  @include boxShadow();
  border-radius: 3px;
  font-size: 14px;
}

.c-widget-simple__header {
  padding: 14px var(--gap-3);
  line-height: 1.5;

  p {
    margin: 0;
  }
}

.c-widget-simple__headline {
  font-weight: 420;
}

.c-widget-simple__subheadline {
  font-size: 12px;
  color: getColor('grey');
}

.c-widget-simple__content {
  border-top: 1px solid getColor('grey-lighter');
  padding: var(--gap-1) var(--gap-3);
}

.c-widget-simple__grayed-headline {
  font-weight: 400;
}
