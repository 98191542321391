@import '~@sonnen/shared-web/abstracts';

.c-news-channel-header {
  position: relative;
}

.c-news-channel__title {
  display: flex;
  align-items: flex-end;
  vertical-align: middle;
  padding-right: var(--gutter-h);
  font-size: 26px;
  font-weight: 300;
  color: getTextColor('dark');
  line-height: 1.4;

  @include breakpointUp(SM) {
    font-size: 32px;
  }

  @include breakpointUp(NAV) {
    max-width: 50%;
  }
}

.c-news-channel__nav-link {
  cursor: pointer;

  &:hover,
  &.is-active {
    color: getTextColor('blue')
  }

  &.is-active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    border-bottom: 4px solid getColor('blue');
  }
}
