@import '~@sonnen/shared-web/abstracts';

.battery_tariff__window {
  position: absolute;
  bottom: 8px;
  z-index: 1;

  &--offPeak {
    height: 32px;
    background-color: $green_200;
  }

  &--peak {
    height: 72px;
    background-color: $yellow_200;
  }

  &--time_stamp_label {
    position: absolute;
    bottom: -23px;
    left: -14px;
  }

  &--left_border {
    position: absolute;
    left: 0;
    top: -4px;
    height: calc(100% + 8px);
    width: 1px;
    border-left: 1px dashed #61687A;
  }
}
