@import '~@sonnen/shared-web/abstracts';

.news-item {
  line-height: normal;
  padding: 18px 0;

  &:not(.news-item--news-inbox-details, .news-item--news-notifications-modal):hover {
    cursor: pointer;

    .news-item__read-more {
      text-decoration: underline;
    }

    .news-item__title,
    .news-item__message,
    .news-item__read-more {
      cursor: pointer;
    }
  }
}

.news-item__header {
  align-items: center;
  display: flex;
  margin-bottom: var(--gap-1);
}

.news-item__date {
  color: getTextColor('grey');
  font-size: 13px;
  font-weight: 420;
}

.news-item__title {
  font-size: 16px;
  font-weight: 420;
  margin-bottom: 5px;

  @include breakpointUp(NAV) {
    font-size: 18px;
  }
}

.news-item__introduction {
  font-size: 18px;
  margin-bottom: 30px;
}

.news-item__message {
  font-size: 14px;
  margin-bottom: 5px;

  @include breakpointUp(NAV) {
    font-size: 16px;
  }

  a:hover {
    text-decoration: underline;
  }
}

.news-item__read-more {
  color: getTextColor('blue');
  font-size: 16px;
  font-weight: 420;
  margin-bottom: 0;
}

.news-item__read-all-news {
  margin-top: var(--gap-3);
}

.news-item--dashboard {
  &::before {
    display: none;
  }

  .news-item__title {
    margin-bottom: var(--gap-1);

    @include breakpointUp(NAV) {
      line-height: 22px;
      margin-bottom: 6px;
    }
  }
}

.news-item--news-inbox-sidebar {
  white-space: normal;

  @include breakpointUp(NAV) {
    padding-left: var(--gap-8);
    padding-right: 45px;
    position: relative;
  }

  .news-item__title {
    margin-bottom: 4px;
  }

  &:first-child {
    padding-top: 0;
  }

  &::after {
    background-color: getColor('grey-lighter');
    bottom: 0;
    content: '';
    height: 1px;
    left: var(--gap-8);
    position: absolute;
    width: calc(100% - 90px);
  }
}

.news-item--news-inbox-details {
  .news-item__title {
    font-size: 32px;
    margin-bottom: 28px;
  }

  &.news-item--active {
    border-bottom: none;
  }
}

.news-item--news-notifications-modal {
  .news-item__title {
    font-size: 26px;
    margin-bottom: var(--gap-2);
  }
}

.news-item--active {
  overflow: visible;

  @include breakpointUp(NAV) {
    &::before {
      background-color: getColor('blue');
      content: '';
      height: 100%;
      left: 3px;
      position: absolute;
      top: 0;
      width: 5px;
    }
  }
}

.news-item--unread {
  .news-item__title {
    font-weight: bold;
  }
}

.news-item--news-inbox-details,
.news-item--news-notifications-modal {
  &:before {
    display: none;
  }

  .news-item__header {
    margin-bottom: var(--gap-2);
  }

  .news-item__title {
    font-weight: 420;
  }
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;

  li:not(.ql-direction-rtl) {
    padding-left: 10px;
  }
}

.news-item__new-tag {
  margin-left: var(--gap-1);
  position: absolute;
}
