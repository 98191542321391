@import '~@sonnen/shared-web/abstracts';

.customer-analysis-statistics-toolbar {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.customer-analysis-statistics-toolbar__date-switcher-icon {
  @include createIcon(
    $_color: getColor('grey-darkest'),
    $_width: 14px
  );
}
