@import '~@sonnen/shared-web/abstracts';

.c-battery-backup-buffer-list-item {
  margin-top: var(--gap-1);
}

.c-battery-backup-buffer__battery-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--gap-1) var(--gap-2) var(--gap-4) var(--gap-2);

  @include breakpointUp(SM) {
    flex-direction: row;
    margin: 0 calc(var(--gap-2) * -1);
  }
}

.c-battery-backup-buffer__battery {
  flex: 0 0 var(--gap-9);
  width: var(--gap-9);

  @include breakpointUp(SM) {
    width: auto;
    margin-right: var(--gap-2);
  }
}

.c-battery-backup-buffer__slider {
  width: 100%;

  @include breakpointUp(MD) {
    flex: 0 0 80%;
    padding: 0 var(--gap-2);
  }
}

.c-battery-backup-buffer__slider-range {
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  align-items: center;
}

.c-battery-backup-buffer__range-track {
  height: 8px;
  width: 100%;
  background-color: getColor('blue');
  border-radius: 4px;
}

.c-battery-backup-buffer__range-thumb {
  height: 20px;
  width: 20px;
  background-color: getColor('white');
  border: 2px solid getColor('blue');
  border-radius: 50%;
}

.c-battery-backup-buffer__footer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin: 0 calc(var(--accodion-content-padding) * -1);
  padding: var(--gap-3) var(--accodion-content-padding) 0;
  border-top: 1px solid getColor('grey-lighter');

  @include breakpointUp(XXS) {
    flex-direction: row;
  }
}

.c-battery-backup-buffer__slider-backup {
  margin: 0;
  font-size: 16px;
  font-weight: 420;
  line-height: 24px;
  color: getTextColor('blue');
}

.c-battery-backup-buffer__slider-selfcons {
  text-align: right;
  margin: 0;
  font-size: 16px;
  font-weight: 390;
  line-height: 24px;
  color: getTextColor('grey');
}

.c-battery-backup-buffer__slider-header {
  display: flex;
  width: 100%;
  margin: 0 0 4px;
  justify-content: space-between;
  align-items: flex-end;

  @include breakpointUp(SM) {
    margin: 0 0 var(--gap-1);
  }
}

.c-battery-backup-buffer__slider-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: var(--gap-2);
}

.c-battery-backup-buffer__slider-footer-backup {
  font-weight: 420;
  font-size: 40px;
  line-height: 48px;
  color: getColor('blue');
}

.c-battery-backup-buffer__slider-footer-selfcons {
  font-weight: 330;
  font-size: 40px;
  line-height: 48px;
  color: $grey_400;
}

.c-battery-backup-buffer__slider-value {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  color: getColor('blue');
}

.c-battery-backup-buffer__slider-value-text {
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  color: getColor('blue');
}

.c-battery-backup-buffer__confirmation {
  margin-top: var(--gap-4);
}

.c-battery-backup-buffer__confirmation-label {
  font-size: 14px;
  line-height: 20px;
}
