@import '~@sonnen/shared-web/abstracts';
// @TODO improve styles

.c-contact-role {
  display: flex;
  align-items: center;
}

.c-contact-role__input {
  width: auto;
  margin-right: var(--gap-1);
}
