@import '~@sonnen/shared-web/abstracts';

.c-customer-analysis-day-toolbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--gap-3) var(--gap-4);
  white-space: nowrap;
}

.c-customer-analysis-day-toolbar__dates {
  display: flex;
  justify-content: center;
  z-index: calc(var(--z-index-tooltip) + 2);

  @include breakpointUp(XS) {
    align-items: center;
    justify-content: flex-start;
  }
}

.c-customer-analysis-day-toolbar__label {
  display: none;

  @include breakpointUp(XS) {
    display: inline-block;
    padding-right: var(--gap-2);
    font-size: 14px;
    font-weight: 420;
    color: getTextColor('dark');
  }
}

.c-customer-analysis-day-toolbar__label--end {
  @include breakpointUp(LG) {
    margin-left: var(--gap-8);
  }
}

.c-customer-analysis-day-toolbar__dates-switcher-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  @include breakpointDown(LG) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.c-customer-analysis-day-toolbar__filter-csv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-direction: row;
  @include breakpointDown(SM) {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
  }
}

.c-customer-analysis-day-toolbar__date-switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:first-child {
    @include breakpointDown(LG ) {
      margin-bottom: var(--gap-2);
    }
  }
}

.c-customer-analysis-day-toolbar__date-picker-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.c-customer-analysis-day-toolbar__filter-button {
  .c-accordion-button {
    align-items: center;
  }

  .c-accordion-button__start-adornment {
    margin-right: calc(var(--gap-1) / 2);

    @include createIcon(
      $_color: getColor('blue'),
      $_width: 26px,
      $_height: 26px
    );

    @include breakpointDown(MD) {
      @include createIcon(
        $_width: 22px,
        $_height: 22px
      );
    }
  }

  .c-accordion-button__icon {
    @include createIcon(
      $_color: getColor('blue'),
      $_width: 16px,
      $_height: 16px
    );
  }
}

.c-customer-analysis-day-toolbar__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: getColor('grey-lightest');

  transition:
    max-height 150ms linear,
    padding 150ms ease;

  &.is-open {
    max-height: 150px;
    padding: calc(var(--gap-1) * 1.5) var(--gap-1);
    border-top: 1px solid getColor('grey-light');
    overflow: visible;
  }

  @include breakpointDown(MD) {
    margin-bottom: var(--gap-2);
  }
}

.c-customer-analysis-day-toolbar__spacer {
  margin: 0 15px;
}

.c-customer-analysis-day-toolbar__tooltip-header {
  margin-bottom: var(--gap-1);
  font-size: 16px;
  font-weight: 420;
}

.c-customer-analysis-day-toolbar__tooltip-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.c-customer-analysis-day-toolbar__tooltip-button {
  flex-grow: 0;
  padding: 0;
}

.c-customer-analysis-day-toolbar__tooltip-text {
  margin-bottom: 0;
}
