@import '/src/app/designSystem/styles/';

$dropdown-menu-height: 400px;
$input-field-border-radius: 4px;
$input-field-height: 40px;
$type-size: 14px;

.postcode-search-select__list {
  max-height: $dropdown-menu-height;

  .loader-wrapper {
    margin: 18px auto 10px auto;
  }

  .postcode-search__input {
    position: relative;

    & > input {
      outline: none;
      padding: 0;
      margin: 0;
      width: 168px;
      border: 1px solid $grey_200;
      height: $input-field-height;
      border-radius: $input-field-border-radius;
      padding-left: 10px;
      font-family: 'Brandon Text', sans-serif;
      font-size: $type-size;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
      }

      &::placeholder {
        color: $grey_200;
      }
    }

    &--button {
      position: absolute;
      top: 0;
      right: -2px;
      height: $input-field-height;
      width: $input-field-height;
      border-top-right-radius: $input-field-border-radius;
      border-bottom-right-radius: $input-field-border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue_500;

      & > svg {
        fill: $white;
      }
    }
  }

  .postcode-search__dropdown-content {
    margin-top: 24px;
    max-height: 264px;
    overflow-y: auto;

    &--result {
      .label-takes-full-width-of-parent {
        padding: 6px 0;
        width: 100%;

        &:hover {
          cursor: pointer;
          background-color: $grey_25;
        }
      }

      .postcode-label {
        letter-spacing: 1px;

        .search-match {
          font-weight: bold;
        }
      }
    }
  }

  .postcode-search__buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;

    .cancel-postcode-selection {
      padding: 6px;
    }
  }

  .no-postcode-found {
    padding-top: 24px;
  }
  .postcode-input-helper-text {
    padding-top: 6px;
    color: $grey_500;
  }
}
