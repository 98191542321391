@import '~@sonnen/shared-web/abstracts';

.tooltip-hoverable {
  position: relative;
}

.tooltip-hoverable__bubble {
  position: absolute;
  width: 250px;
  font-size: 12px;
  z-index: var(--z-index-tooltip);
}
