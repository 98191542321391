@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-contact {
  @include boxShadow();
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 18px;
  width: calc(100% + 50px);
  height: 170px;
  left: calc(100% - 46px);
  z-index: var(--z-index-header);;
  font-size: 16px;
  background-color: getColor('white');
  border-radius: 3px;
  line-height: normal;
  transition: left 0.4s;
}

.c-setup-tool-contact.is-expanded {
  left: -46px;
}

.c-setup-tool-contact--minimized {
  @include boxShadow();
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 47px;
  border-radius: 3px;
  border-left: solid 3px getColor('grey-light');
  cursor: pointer;
}

.c-setup-tool-contact--minimized__inner {
  display: flex;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.c-setup-tool-contact--minimized__header {
  margin-top: 1px;
}

.c-setup-tool-contact--minimized__phone-circle-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  transform: rotate(90deg);
}

.c-setup-tool-contact--expanded {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 3px);
  padding: var(--gap-3) var(--gap-5);
  border-radius: 3px;
  border-left: solid 3px getColor('grey-light');

  p {
    margin: 0;
  }

  @include breakpointUp(SM) {
    padding: var(--gap-5) var(--gap-9);
  }
}

.c-setup-tool-contact--expanded__header,
.c-setup-tool-contact--expanded__body strong {
  font-weight: 420;
}

.c-setup-tool-contact--expanded__close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 17px;
  width: 17px;
}

.c-setup-tool-contact--expanded__close-icon {
  fill: getColor('grey');
}
