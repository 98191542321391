@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-customer-data__item {
  width: 45.5%;
}

.c-setup-tool__static-info {
  background-color: getColor('grey-lightest');
  flex: 1;
  padding: var(--gap-4);
  margin-bottom: var(--gutter-v);
}
