@import '~@sonnen/shared-web/abstracts';

.c-dso-registration-summary-files {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  &.c-dso-registration-summary-files--compact {
    font-size: 12px;
    padding: var(--gap-1) 0;
  }
}

.c-dso-registration-summary__container-files {
  display: block;
  margin: var(--gap-2) 0;
  align-items: flex-start;
  justify-items: flex-start;

  .c-dso-registration-summary-files--compact & {
    margin: var(--gap-1) 0;
  }
}
