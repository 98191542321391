@import '~@sonnen/shared-web/abstracts';

.c-ribbon {
  --border-radius: 10px;
  --tanslate-offset: 10px;
}

.c-ribbon {
  position: absolute;
  right: calc(var(--tanslate-offset) * -1);
  min-width: 200px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  padding: 8px 20px calc(8px + var(--tanslate-offset));
  font-size: 14px;
  font-weight: 420;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  transition: right 400ms ease-in-out;

  @include breakpointUp(SM) {
    bottom: 50%;
    font-size: 18px;
  }

  &:hover {
    right: 0;
  }
}

.c-ribbon--warning {
  color: getTextColor('white');
  background-color: getColor('yellow');
}
