@import '~@sonnen/shared-web/abstracts';

.c-attachment {
  font-size: 14px;
  font-weight: 300;
  font-weight: 420;
  color: getTextColor('dark');
}

.c-attachment__icon {
  @include createIcon($_width: 15px, $_color: getColor('blue'));
}
