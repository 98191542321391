@import '~@sonnen/shared-web/abstracts';

.c-battery-live-card__highlight,
.c-battery-live-card__highlight * {
  animation: highlightAnimation 1s;
}

@keyframes highlightAnimation {
  from {opacity: .5;}
  to {opacity: 1;}
}
