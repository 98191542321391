@import '~@sonnen/shared-web/abstracts';

.c-list-table--setup-list {
  .c-list-table__header-item {
    flex-grow: 1;
    margin-right: var(--gap-2);

    & > * {
      margin-left: var(--gap-2);
    }

    &.c-list-table__header-item--0 {
      flex-grow: 1.1;
    }

    &.c-list-table__header-item--3 {
      flex-grow: 2.8;
    }
  }
}
