
@mixin fontSource($_name1, $_name2, $_path) {
  $_path: '/app/designSystem/fonts/' + $_path;
  src:
    local($_name1),
    local($_name2),
    url($_path + '.woff2') format('woff2'),
    url($_path + '.woff') format('woff'),
    url($_path + '.ttf') format('truetype'),
}

@font-face {
@include fontSource('Brandon Text Thin', 'BrandonText-Thin', 'BrandonText-Thin');
font-family: 'Brandon Text';
font-style: normal;
font-weight: 100;
}
@font-face {
@include fontSource('Brandon Text Light', 'BrandonText-Light', 'BrandonText-Light');
font-family: 'Brandon Text';
font-style: normal;
font-weight: 300;
}
@font-face {
@include fontSource(
  'Brandon Text Regular',
  'BrandonText-Regular',
  'BrandonText-Regular'
);
font-family: 'Brandon Text';
font-style: normal;
font-weight: 400;
}
@font-face {
@include fontSource('Brandon Text Medium', 'BrandonText-Medium', 'BrandonText-Medium');
font-family: 'Brandon Text';
font-style: normal;
font-weight: 500;
}
@font-face {
@include fontSource('Brandon Text Bold', 'BrandonText-Bold', 'BrandonText-Bold');
font-family: 'Brandon Text';
font-style: normal;
font-weight: 700;
}
@font-face {
@include fontSource('Brandon Text Black', 'BrandonText-Black', 'BrandonText-Black');
font-family: 'Brandon Text';
font-style: normal;
font-weight: 900;
}

// italics
@font-face {
@include fontSource(
  'Brandon Text Thin Italic',
  'BrandonText-ThinItalic',
  'BrandonText-ThinItalic'
);
font-family: 'Brandon Text';
font-style: italic;
font-weight: 100;
}
@font-face {
@include fontSource(
  'Brandon Text Light Italic',
  'BrandonText-LightItalic',
  'BrandonText-LightItalic'
);
font-family: 'Brandon Text';
font-style: italic;
font-weight: 300;
}
@font-face {
@include fontSource(
  'Brandon Text Regular Italic',
  'BrandonText-RegularItalic',
  'BrandonText-RegularItalic'
);
font-family: 'Brandon Text';
font-style: italic;
font-weight: 400;
}
@font-face {
@include fontSource(
  'Brandon Text Medium Italic',
  'BrandonText-MediumItalic',
  'BrandonText-MediumItalic'
);
font-family: 'Brandon Text';
font-style: italic;
font-weight: 500;
}
@font-face {
@include fontSource(
  'Brandon Text Bold Italic',
  'BrandonText-BoldItalic',
  'BrandonText-BoldItalic'
);
font-family: 'Brandon Text';
font-style: italic;
font-weight: 700;
}
@font-face {
@include fontSource(
  'Brandon Text Black Italic',
  'BrandonText-BlackItalic',
  'BrandonText-BlackItalic'
);
font-family: 'Brandon Text';
font-style: italic;
font-weight: 900;
}
