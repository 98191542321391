@import '~@sonnen/shared-web/abstracts';

.c-customer {
  padding: var(--gap-4) 0;
}

.c-customer--no-consent-given {
  --icon-size: 80px;

  padding: var(--gap-8);
}

.c-customer {
  .c-widget {
    @include breakpointDown(XS) {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
      border-radius: var(--border-radius);
    }
  }

  .c-widget__header {
    @include breakpointDown(XS) {
      box-shadow: none;
      border-bottom: 1px solid #D3D5D8;
      border-radius: 0;
      padding: 16px 0 4px 0;
      margin: 0 15px;
      height: unset;
      line-height: unset;
    }
  }

  .c-widget-status-header {
    @include breakpointDown(XS) {
      margin: 0;
    }
  }

  .c-icon--refresh {
    @include breakpointDown(XS) {
      transform: unset;
    }
  }

  .c-widget__body-wrapper {
    @include breakpointDown(XS) {
      box-shadow: none;
      margin: 0;
    }
  }

  .c-widget-accordion__header {
    @include breakpointDown(XS) {
      box-shadow: none;
      border-left: none;
      border-bottom: 1px solid #D3D5D8;
      border-radius: 0;
      padding: 0 0 var(--gap-2) 0;
      margin: var(--gap-2) var(--gap-2) var(--gap-1) var(--gap-2);
      width: unset;
    }
  }

  .c-widget-accordion__content-wrapper--opened {
    @include breakpointDown(XS) {
      box-shadow: none;
    }
  }
}
