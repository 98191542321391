@import "~@sonnen/shared-web/abstracts";

.c-lead-autosuggested-address__btn {
  font-weight: 420;
  color: getColor('blue');
  cursor: pointer;
}

.c-lead-autosuggested-address__no-results {
  color: getTextColor('dark');
}

.c-lead-autosuggested-address__info-wrapper {
  display: flex;
  margin-bottom: var(--gap-3);
}

.sw-select__input {
  padding: 10px 0;
}
