@import '~@sonnen/shared-web/abstracts';

.c-send-news-partner__success-text {
  margin-bottom: var(--gap-2);
  color: getTextColor('green');
  font-size: 16px;
}

.ql-video {
  height: 315px;
  margin: 0 auto;
  width: 560px;
}
