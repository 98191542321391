@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-pv {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-2);
}

.c-lead-configuration-pv__columns-wrapper {
  display: flex;
  flex-direction: column;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-lead-configuration-pv__column {
  @include breakpointUp(SM) {
    width: var(--configuration-column-width);

    &:first-child {
      margin-right: var(--configuration-column-space);
    }
  }
}

.c-lead-configuration-pv__input-group {
  @include breakpointUp(SM) {
    display: flex;
  }

  &.disabled {
    opacity: 0.15;
  }
}

.c-lead-configuration-pv__input {
  max-width: var(--configuration-input-width);
}

.c-lead-configuration-pv__inclination-input {
  margin-bottom: var(--gap-1);
  @include breakpointUp(SM) {
    margin-right: var(--gap-3);
  }
}

.c-lead-configuration-pv__inclination-input--error {
  margin-bottom: 0;
}

.c-lead-configuration-pv__message-yield {
  max-width: var(--configuration-input-width);
  margin: var(--gap-3) 0;
}

.c-lead-configuration-pv__message-yield--error {
  margin-bottom: var(--gap-2);
}

.c-lead-configuration-pv__message-inclination {
  max-width: var(--configuration-input-width);
  margin: var(--gap-2) 0 var(--gap-3);
}

.c-lead-configuration-pv__message-inclination--error {
  margin-top: 0;
  margin-bottom: var(--gap-1);

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-2);
  }
}

.c-lead-configuration-pv__btn {
  font-size: 11px;
  font-weight: 420;
  padding: 0;
}

.c-lead-configuration-pv__post-eeg-warning {
  margin-bottom: var(--gap-2);
}

.c-lead-configuration-pv__new-battery-question {
  font-size: 14px;
  margin-bottom: var(--gap-3);
 }

 .c-lead-configuration-pv__new-battery-radio-btns {
  margin-bottom: var(--gap-4);
 }

.c-lead-configuration-pv__multiple-pv-separator {
  width: 100%;
  height: 1px;
  margin: var(--gap-5) 0 var(--gap-2);
  background-color: getColor('grey-lightest');
}

.c-lead-configuration-pv__multiple-pv-header {
  margin-bottom: var(--gap-2);
  font-size: 14px;
  font-weight: 420;
}

.c-lead-configuration-pv__dso-checkbox {
  margin: var(--gap-4) 0 var(--gap-5);
}

.c-lead-configuration-pv__multiple-pv-warning {
  margin-bottom: var(--gap-4);
}

.c-lead-configuration-pv__second-pv-datepicker {
  .c-form-input-date {
    z-index: auto;
  }

  .c-form-input-date__datepicker-container {
    z-index: 1;
  }
}

.c-lead-configuration-pv__pv-system-name-wrapper {
  font-size: 14px;
  margin: var(--gap-4) 0 var(--gap-2);
}

.c-lead-configuration-pv__flat-direct-warning {
  margin-bottom: var(--gap-3);
}
