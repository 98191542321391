@import '~@sonnen/shared-web/abstracts';

.c-certificate-list__table {
  font-size: 14px;
}

.c-certificate-list__headers {
  display: flex;
  padding: var(--gap-1) var(--gap-1) ;
  font-weight: 420;

  @include breakpointUp(XS) {
    padding: var(--gap-1) var(--gap-4);
  }
}

.c-certificate-list__header {
  flex: 1 1 50%;
  margin: 0 var(--gap-2) var(--gap-1) 0;
}

.c-certificate-list__item {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  padding: var(--gap-1) 0;
}

.c-certificate-list__cell {
  flex: 1 1 50%;
  margin: 0 var(--gap-2) var(--gap-1) 0;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-bottom: 0;
  }
}

.c-certificate-list__tooltip {
  margin-left: 0;
}
