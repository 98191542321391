@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-inputs-with-recommendation {
  position: relative;
}

.c-lead-configuration-inputs-with-recommendation__info {
  margin-top: calc(var(--gap-2) * -1);
  margin-bottom: var(--gap-4);
}

.c-lead-configuration-inputs-with-recommendation__columns {
  display: flex;
  flex-direction: column;

  @include breakpointUp(SM) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.c-lead-configuration-inputs-with-recommendation__column {
  @include breakpointUp(SM) {
    width: var(--configuration-column-width);

    &:first-child {
      margin-right: var(--configuration-column-space);
    }
  }
}

.c-lead-configuration-inputs-with-recommendation__input {
  max-width: var(--configuration-input-width);
}
