@import '~@sonnen/shared-web/abstracts';

.c-form-textarea {
  --input-font-size: 14px;
  --input-text-align: left;
}

.c-form-textarea {
  margin-bottom: var(--gap-4);
}

.c-form-textarea--no-gap {
  margin-bottom: 0;
}

.c-form-textarea__container {
  position: relative;
  display: flex;
  align-items: center;
}

.c-form-textarea__field {
  @include borderRadius;
  flex: 1 0 auto;
  height: 150px;
  padding: 12px 15px;
  resize: none;
  font-family: var(--font-family);
  font-size: var(--input-font-size);
  font-weight: normal;
  color: getTextColor('dark');
  line-height: 1.4;
  border: 1px solid getColor('grey-light');
  background-color: getColor('white');
  box-shadow: none;
  text-align: var(--input-text-align);


  &::placeholder {
    color: getTextColor('grey');
    font-weight: normal;
  }
}

.c-form-textarea__field--disabled {
  color: getTextColor('grey');

  &::placeholder {
    color: getTextColor('grey');
  }
}

.c-form-textarea__field:focus {
  border: 1px solid getColor('blue')
}

.c-form-textarea__field[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.c-form-textarea__field[type='date'],
.c-form-textarea__field[type='time'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-ms-clear,
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.c-form-textarea__field--error {
  border-color: getColor('red');
  color: getTextColor('red');
}

.c-form-textarea__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-textarea__error-message {
  color: getTextColor('red');
  font-size: 12px;
}
