@import '~@sonnen/shared-web/abstracts';

.c-offer-form {
  --offer-input-width: 360px;
  --offer-column-space: var(--gap-4);
  --offer-column-width: calc(50% - var(--offer-column-space) / 2);
}

.c-offer-form__error-message {
  margin-bottom: var(--gap-8);
  color: getTextColor('red');
  font-size: 14px;
  font-weight: 420;
}

.c-offer-form__questions-container {
  position: relative;
}

.c-offer-form__questions-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(getColor('white'), .9);
}

.c-offer-form__loader-text {
  margin-top: var(--gap-2);
  font-size: 13px;
  font-weight: 300;
}

.c-offer-form__utility-paragraph {
  margin-bottom: var(--gap-4);
}

.c-offer-form__column {
  @include breakpointUp(SM) {
    width: var(--offer-column-width);

    &:first-child {
      margin-right: var(--offer-column-space);
    }
  }
}

.c-offer-form__utility-input {
  @include breakpointUp(SM) {
    max-width: var(--offer-input-width);
  }
}

.c-offer-form__utility-prefilled {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-4);
  font-size: 14px;
}

.c-offer-form__label {
  display: block;
  margin: 0 0 var(--gap-1);
  font-size: 13px;
  font-weight: 420;
  color: getTextColor('grey');
}

.c-offer-form__meter-id-warning {
  max-width: 650px;
  margin-bottom: var(--gap-4);

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-7);
  }
}

.c-offer-form__hw-status-wrapper {
  position: relative;
}

.c-offer-form__hw-status-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: getColor('white');
  opacity: 0.6;
  pointer-events: none;
  z-index: 1;
}
