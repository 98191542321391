@import '~@sonnen/shared-web/abstracts';

.c-nav-user-trigger {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
}

.c-nav-user-trigger__content {
  display: flex;
  flex: 1 1 280px;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 15px;

  @include breakpointUp(NAV) {
    flex-direction: row;
    align-items: center;
    padding-right: 0;
  }
}

.c-nav-user-trigger__content-item {
  .is-active > .c-nav-user-trigger & {
    color: getColor('blue-light');
  }
}

.c-nav-user-trigger__arrow {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 2px;

  @include breakpointUp(NAV) {
    width: 24px;
    height: 24px;
  }

  .is-active > .c-nav-user-trigger > & {
    transform: rotate(180deg);
  }

  &::after {
    content: config(ICONS, ANGLE);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-icons);
    font-size: 12px;
    text-align: center;
    transform: rotate(-90deg);

    @include breakpointUp(NAV) {
      font-size: 9px;
    }
  }

  .is-active > .c-nav-user-trigger > &::after {
    color: getColor('blue-light');
  }
}

.c-nav-user-trigger__icon {
  flex: 1;
  font-size: 32px;

  .is-active > .c-nav-user-trigger > & {
    color: getColor('blue-light');
  }
}
