@import '~@sonnen/shared-web/abstracts';

.c-detail-list-table {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
}

.c-detail-list-table--no-bottom-border {
  .c-detail-list-table__item:last-child {
    border-bottom: 0;
  }
}

.c-detail-list-table__item {
  position: relative;
  border-bottom: 1px solid getColor('grey-lighter');
  padding: var(--gap-1) 0;

  &:first-of-type {
    padding-top: 0;
  }

  &--with-tooltip::before {
    background-color: getColor('blue');
    content: '';
    height: 100%;
    left: calc(-2.5 * var(--gap-1));
    position: absolute;
    top: 0;
    width: 4px;
  }

  &:last-of-type::before {
    border-radius: 0 0 0 4px;
  }

  @include breakpointUp(XXS) {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  @include breakpointUp(SM) {
    padding: var(--gap-2) 0;
  }
}

.c-detail-list-table__item-title {
  flex: 0 1 35%;
  margin-bottom: 0;
  padding-right: var(--gap-2);
  font-weight: 420;
  color: getTextColor('grey');

  @include breakpointUp(SM) {
    flex: 0 1 40%;
  }
}

.c-detail-list-table__item-value,
.c-detail-list-table__edit-container {
  flex: 1 0 65%;
  min-width: 65%;
  padding-right: var(--gap-1);

  @include breakpointUp(SM) {
    flex: 1 0 60%;
    min-width: 60%;
  }
}

.c-detail-list-table__item-value {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.c-detail-list-table__value--ellipsis {
  overflow: hidden; 
  text-overflow: ellipsis;
}

.c-detail-list-table__item-value--mandatory {
  color: getTextColor('red');
  font-weight: 420;
}

.c-detail-list-table__general-error {
  margin-bottom: var(--gap-4);
  color: getTextColor('red');
  font-weight: 420;
  font-size: 14px;
}

.c-detail-list-table__actions {
  display: flex;
  flex-direction: row;
}

.c-detail-list-table__edit-icon {
  @include createIcon(
    $_color: getColor('blue'),
    $_width: 16px,
  );
  margin-left: var(--gap-1);
  cursor: pointer;
}

.c-detail-list-table__info-icon {
  @include createIcon(
    $_color: getColor('blue'),
    $_width: 18px,
  );
  background-color: transparent;
  cursor: pointer;
}

.c-detail-list-table__tooltip {
  position: relative;
  margin-left: var(--gap-1);
}

.c-detail-list-table__bubble-wrapper {
  position: absolute;
  top: 30px;
  right: -15px;
  width: 210px;
  font-size: 12px;
  line-height: 1.4;
  z-index: 2;
}

.c-detail-list-table__bubble-wrapper--wide {
  width: 210px;

  @include breakpointUp(SM) {
    width: 370px;
  }
}
