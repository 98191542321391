@import '~@sonnen/shared-web/abstracts';

.c-lead-list-decline-modal {
  --modal-layout-body-padding-v: var(--gap-4);
  --modal-layout-body-padding-h: var(--gap-4);

  @include breakpointUp(SM) {
    --modal-layout-body-padding-h: var(--gap-10);
  }
}

.c-lead-list-decline-modal {
  height: 100vh;

  @include breakpointUp(SM) {
    height: 85vh;
    max-height: 550px;
  }
}

.c-lead-list-decline-modal-form {
  width: 100%;
  height: 100%;
}

.c-lead-list-decline-modal__buttons {
  width: 100%;

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
  }
}

.c-lead-list-decline-modal__buttons-wrapper {
  @include breakpointDown(SM) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: var(--gap-3) 0;
  }
}

.c-lead-list-decline-modal__rejection {
  margin: var(--gap-2) 0;

  textarea {
    height: 260px;
  }
}

.c-lead-list-decline-modal__accept-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 1;
}
