@import '~@sonnen/shared-web/abstracts';

.c-reset-password-form {
  height: 100%;
}

.c-reset-password-form__button-wrapper {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
}

.c-reset-password-form__body {
  max-width: 400px;
}

.c-reset-password-form__input {
  margin-top: 0;
  margin-bottom: var(--gap-3);
}

.c-reset-password-form__button {
  --btn-min-width: 120px;
  @include breakpointUp(XS) {
    --btn-min-width: 160px;
  }
}
