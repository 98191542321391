@import '~@sonnen/shared-web/abstracts';

.c-lead-hardware-form-status {
  display: block;
  margin-bottom: var(--gap-2);
}

.c-lead-hardware-form-status__headline {
  font-size: 16px;
  font-weight: 420;
  margin-bottom: var(--gap-1);
}

.c-lead-hardware-form-status__subheadline {
  font-size: 14px;
  margin-bottom: var(--gap-3);
}

.c-lead-hardware-form-status__update-status-wrapper {
  display: flex;
}

.c-lead-hardware-form-status__tile-wrapper {
  display: flex;
  align-items: center;
}

.c-lead-hardware-form-status__button {
  margin-left: var(--gap-6);
}
