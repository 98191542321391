@import '~@sonnen/shared-web/abstracts';

.c-register {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--content-height);
}

.c-register__headline {
  text-align: center;
}

.c-register__email {
  font-weight: bold;
  text-align: center;
  color: getTextColor('grey');
}
