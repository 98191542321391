@import '~@sonnen/shared-web/abstracts';


.c-overview-close-lead {
  position: relative;
}

.c-overview-close-lead__radio-group {
  margin-top: var(--gap-3);
}

.c-overview-close-lead__radio {
  margin-bottom: var(--gap-3);

  &.is-active {
    margin-bottom: var(--gap-1);
  }
}

.c-overview-close-lead__line {
  border-top: 1px solid getColor('grey-light');
  margin: var(--gap-3) calc(var(--accodion-content-padding) * -1);
}

.c-overview-close-lead__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;

  @include breakpointUp(XS) {
    justify-content: flex-end;
  }
}
