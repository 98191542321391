@import '~@sonnen/shared-web/abstracts';

.c-legend {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > * {
    margin: var(--gap-1) 0;
  }

  & > *:not(:last-of-type) {
    margin-right: var(--gap-2);
  }

  @include breakpointDown(XLG) {
    flex: 1 0;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
  }
}

.c-legend__badge {
  position: relative;
}

.c-legend__tooltip {
  position: absolute;
  top: var(--gap-5);
  left: 50%;
  width: 250px;
  transform: translate(-50%, 0);
  font-size: 14px;
  line-height: 1.4;
  z-index: calc(var(--z-index-tooltip) + 2);
}
