@import '~@sonnen/shared-web/abstracts';

$_connection-height: 2px;
$_check-mark-size: 20px;

.c-setup-tool-form-wizard {
  .c-form-wizard-step__content {
    padding: var(--gap-2) var(--gap-6);

    @include breakpointDown(MD) {
      padding: var(--gap-2) var(--gap-1);
    }
  }

  .c-setup-tool__dso-summary {
    overflow: auto;
  }
  .c-overlay__title {
    box-shadow: none;
  }

  .sw-form-wizard-header__item::before {
    cursor: pointer;
  }

  .sw-form-wizard-header__label-wrapper {
    cursor: pointer;
    user-select: none;
  }

  .sw-form-wizard-header__label {
    max-width: 95px;
  }

  .c-form-banner--error {
    margin-left: var(--gap-10);
    margin-right: var(--gap-10);
    width: auto;

    @include breakpointDown(MD) {
      margin-left: var(--gap-4);
      margin-right: var(--gap-4);
    }
  }
}

.c-setup-tool-form-wizard__header {
  padding: var(--gap-3) var(--gap-8);

  @include breakpointDown(MD) {
    padding: var(--gap-3) var(--gap-3);
  }
}
