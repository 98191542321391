@import '~@sonnen/shared-web/abstracts';

.c-events-and-trainings__container {
  @include breakpointUp(XS) {
    flex-direction: row;
  }

  display: flex;
  flex-direction: column;
  margin: 0 -20px;
}

.c-events-and-trainings__text {
  max-width: 220px;
  margin-bottom: 20px;
  color: getTextColor('grey');
  font-size: 14px;
  font-weight: 400;
}

.c-events-and-trainings__img {
  @include breakpointUp(XS) {
    max-width: 100%;
    margin: 0;
  }
  
  max-width: fit-content;
  height: 100%;
  margin-bottom: 10px;
  padding: 0 20px;
}

.c-events-and-trainings__data {
  padding: 0 20px;
}
