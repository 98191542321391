@import "~@sonnen/shared-web/abstracts";

.c-lead-overview-lead-magager {
  --widget-padding-v: 0;
  --widget-padding-h: 0;
}

.c-lead-overview-lead-magager {
  .c-widget__body-wrapper {
    box-shadow: none;
  }
}

.c-lead-overview-lead-magager__accordion{
  margin-top: var(--gap-1);
}
