@import '~@sonnen/shared-web/abstracts';

.c-logo__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-logo__button {
  flex-grow: 0;
  margin-left: auto;
  padding: 0;
}

.c-logo {
  width: 100%;
}

.c-logo .c-modal-layout__body-inner {
  padding-bottom: var(--gap-1);
}

.c-logo__image {
  max-height: var(--logo-height, 100px);
}
