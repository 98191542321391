// Mixins for smooth content showing/hiding

@mixin fadeOut($_transition: all .25s ease .1s) {
    @if $_transition !=null {
        transition: $_transition;
    }

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

@mixin fadeIn() {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
}