@import '~@sonnen/shared-web/abstracts';

.c-case-report-search-results-item {
  padding: var(--gap-1) var(--gap-2);
  line-height: 1.2;
  font-size: 12px;

  @include breakpointUp(XS) {
    display: flex;
    height: 48px;
    line-height: 1.4;
  }

  &:hover {
    color: getTextColor('blue');
    cursor: pointer;
  }
}

.c-case-report-search-results-item__customer {
  @include breakpointUp(XS) {
    width: 50%;
  }
}

.c-case-report-search-results-item__name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 420;

  @include breakpointUp(XS) {
    margin-bottom: 4px;
    font-size: 14px;
  }
}

.c-case-report-search-results-item__address {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-case-report-search-results-item__asset {
  @include breakpointUp(XS) {
    width: 25%;
  }
}

.c-case-report-search-results-item__number {
  font-weight: 420;

  @include breakpointUp(XS) {
    font-size: 14px;
  }
}
