@import '~@sonnen/shared-web/abstracts';


.lead-status-modal {
  height: 100vh;
  cursor: default;

  @include breakpointUp(SM) {
    height: 100%;
  }
}

.lead-status-modal__wrapper {
  .sw-modal__dialog {
    @include breakpointUp(SM) {
      width: 720px;
      max-height: min(calc(100% - 2 * var(--gap-7)), 925px);
    }
  }

  .sw-modal-layout__body {
    overflow-y: scroll;
  }

  .sw-modal-layout__footer {
    flex-direction: column;
  }
}

.lead-status-modal-form {
  width: 100%;
  height: 100%;
}

.lead-status-modal__errors {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--gap-3) 0 0;
  color: getTextColor('red');
}

.lead-status-modal__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--gap-3) 0;
}

.lead-status-modal__button-setup {
  margin-right: var(--gap-3);

  @include breakpointUp(SM) {
    margin-right: var(--gap-6);
  }
}

.lead-status-modal__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 2;
}

.lead-status-modal__subheadline {
  margin: var(--gap-2) 0;
  font-weight: 420;
}

.lead-status-modal__status-name {
  font-size: 14px;
  font-weight: 420;
  margin-bottom: calc(var(--gap-1) / 4);
}

.lead-status-modal__status-owner {
  color: getTextColor('grey');
  font-size: 12px;
  margin: 0;
  padding-left: calc(var(--gap-1) + var(--gap-1) / 4);
  position: relative;

  &::before {
    background-color: getColor('grey');
    border-radius: 50%;
    content: '';
    height: calc(var(--gap-1) / 2);
    left: 0;
    position: absolute;
    top: calc(50% - var(--gap-1) / 4);
    width: calc(var(--gap-1) / 2);
  }
}

.lead-status-modal__status-owner--bold {
  font-weight: 420;
}

.lead-status-modal__widget-wrapper {
  margin-bottom: var(--gap-3);
}

.lead-status-modal__input {
  @include breakpointUp(SM) {
    padding-right: var(--gap-5);
    padding-left: var(--gap-5);
  }
}

.lead-status-modal__statuses-container {
  display: flex;
}

.lead-status-modal__statuses-item {
  &:first-child {
    margin-right: var(--gap-3);
  }
}

.c-lead-edit_status-modal__automatic-statuses-container {
  margin-top: var(--gap-1);
}

.c-lead-edit_status-modal__automatic-status {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-2);
}

.c-lead-edit_status-modal__automatic-status-icon {
  @include createIcon($_width: 18px, $_height: 18px);
  margin-right: var(--gap-2);
}

.c-lead-edit_status-modal__automatic-status-icon--active {
  @include createIcon($_color: getColor('green'));
}
