// TODO: this was brought raw from shared-web, clean-up if necessary

// ==========================================================================
// Default box-shadow
//
// *1. Standard box-shadow (all sides) is moved down a little compared to
//     one-sided variations.
// ==========================================================================

@mixin boxShadow($_side: 'all', $_inset: null, $_color: $grey_100, $_is-hover: false) {
    $_x: 0;
    $_y: 0;
    $_blur: 4px;
    $_spread: 1px;
    $_offset: 3px;
    $_negative-offset: ($_offset * -1);
  
    @if $_side != 'all' {
      $_spread: $_negative-offset + 1px;
    }
  
    @if $_inset {
      $_inset: inset;
    }
  
    @if ($_side == 'top') {
      $_y: $_negative-offset;
    }
  
    @if ($_side == 'top' and $_inset) {
      $_y: $_offset;
    }
  
    @if ($_side == 'right') {
      $_x: $_offset;
    }
  
    @if ($_side == 'right' and $_inset) {
      $_x: $_negative-offset;
    }
  
    @if ($_side == 'bottom') {
      $_y: $_offset;
    }
  
    @if ($_side == 'bottom' and $_inset) {
      $_y: $_negative-offset;
    }
  
    @if ($_side == 'left') {
      $_x: $_negative-offset;
    }
  
    @if ($_side == 'left' and $_inset) {
      $_x: $_offset;
    }
  
    @if $_is-hover {
      $_blur: 6px;
    }
  
    box-shadow: $_inset $_x $_y $_blur $_spread $_color;
  }
  