@import '~@sonnen/shared-web/abstracts';

.c-form-input-date {
  position: relative;
  z-index: 10;

  input[type='date']::-webkit-inner-spin-button {
    display: none;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.c-form-input-date__datepicker-container {
  display: block;
  position: absolute;
  top: 50%;
  right: var(--gap-2);
  transform: translateY(-50%);
  z-index: 1;
}

.c-form-input-date__datepicker-trigger {
  @include createIcon($_width: 20px, $_color: getColor('blue'));
  display: block;
  background-color: getColor('white');
  z-index: 1;
  cursor: pointer;
}

.c-form-input-date__datepicker-trigger--disabled {
  pointer-events: none;
}
