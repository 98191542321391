@import '~@sonnen/shared-web/abstracts';

.c-lead-offer-scroll-button {
  @include boxShadow();
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gap-1);
  color: getTextcolor('dark');
  background-color: getColor('white');
  font-size: 16px;
  font-weight: 420;
  border-radius: var(--border-radius-big);
}

.c-lead-offer-scroll-button:hover {
  color: getTextcolor('dark');
}

.c-lead-offer-scroll-button__subtext {
  color: getTextColor('blue');
  font-size: 14px;
}

.c-lead-offer-scroll-button__subtext--error {
  color: getTextColor('red');
  font-size: 14px;
}
