@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-box {
  margin-bottom: var(--gap-1);
}

.c-lead-configuration-box__body,
.c-lead-configuration-box__warning {
  @include boxShadow();
  @include borderRadius();
}

.c-lead-configuration-box__body {
  padding: var(--gap-2) var(--gap-4);
  font-size: 14px;
  line-height: 1.5;
  background-color: getColor('white');
}

.c-lead-configuration-box__warning {
  margin-top: 4px;
}

.c-lead-configuration-box__headline {
  margin-bottom: var(--gap-1);
}

.c-lead-configuration-box__headline--large {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  border-bottom: 1px solid getColor('grey-light');
}

.c-lead-configuration-box__headline-helper-text {
  margin-bottom: 8px;
  color: $grey_500;

  &--large {
    margin-top: 8px;
  }
}

.c-lead-configuration-box__subheadline-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.c-lead-configuration-box__subheadline {
  font-weight: 420;
  margin-bottom: 0;
}

.c-lead-configuration-box__subheadline--right-margin {
  margin-right: var(--gap-1);
}

.c-lead-configuration-box__subheadline--large {
  font-size: 18px;
}
