@import '~@sonnen/shared-web/abstracts';

.c-dso-registration-file__download {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 130px;

  .c-dso-registration-summary-files--compact & {
    font-size: 12px;
  }
}

.c-dso-registration-file__download-icon {
  @include createIcon(
    $_width: 18px,
    $_color: getColor('blue')
  );
  margin-right: var(--gap-1);
}

.c-dso-registration-file__file-container {
  display: flex;
  align-items: center;

  .sw-link__label {
    word-break: break-all;
  }
}

.c-dso-registration-file__empty-container {
  cursor: default;
  display: flex;
  flex: 1;
}

.c-dso-registration-file__file-icon {
  @include createIcon(
    $_width: 20px,
    $_color: #9b9b9b,
  );

  margin-right: var(--gap-2);
}

.c-dso-registration-file__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid getColor('grey');
  padding: var(--gap-2) 0;
  color: getTextColor('dark');
  font-size: 14px;

  .c-dso-registration-summary-files--compact & {
    padding: var(--gap-1) 0;
    font-size: 14px;
    border-bottom-color: getColor('grey');

    @include breakpointDown(LG) {
      padding: 15px 0;
    }

    .c-dso-registration-file__file-icon {
      @include breakpointDown(LG) {
        margin-right: var(--gap-1);
      }
    }
  }

  &--precommissioning {
    border-bottom: none;
    padding: 0;

    .c-dso-registration-file__file-icon {
      cursor: default;
      display: flex;
      height: 20px;
      margin-right: var(--gap-1);
    }

    .c-dso-registration-file__download-icon {
      height: inherit;
    }

    .c-form-banner {
      cursor: default;
    }
  }
}
