@import '~@sonnen/shared-web/abstracts';

.c-notification {
  position: sticky;
  top: var(--header-height);
  left: 0;
  z-index: var(--z-index-alert);
  width: 100%;
}

.c-notification__wrapper {
  @include boxShadow($_side: 'bottom');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: var(--gap-1);
  transition:
    transform 300ms ease-in-out,
    visibility 300ms ease-in-out;
  transform: translate(0, -100%);
  visibility: hidden;

  &.is-open {
    transform: translate(0, 0);
    visibility: visible;
  }
}

.c-notification__wrapper--theme-info {
  background-color: map-get($hint-colors, info);
}

.c-notification__wrapper--theme-success {
  background-color: map-get($hint-colors, success);
}

.c-notification__wrapper--theme-error {
  background-color: getColor('red');
}

.c-notification__wrapper--theme-warning {
  background-color: map-get($hint-colors, warning);
}

.c-notification__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.c-notification__text {
  color: getTextColor('white');
  font-size: 16px;
  line-height: 1.5;
  font-weight: 420;
}

.c-notification__button {
  @include createIcon(
    $_width: 16px,
    $_color: getColor('white')
  );
  position: absolute;
  right: 20px;
  cursor: pointer;
}
