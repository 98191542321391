@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-pv-hint {
  --loader-size: 4px;
}

.c-lead-configuration-pv-hint {
  display: flex;
  align-items: center;
  margin-top: var(--gap-2);
  margin-bottom: var(--gap-4);
}

.c-lead-configuration-pv-hint__loader {
  margin: var(--gap-1);
  margin-left: 0;
}

.c-lead-configuration-pv-hint__text {
  margin: 0;
  font-size: 10px;
  color: getTextColor('dark');
}

.c-lead-configuration-pv-hint__text--dark {
  color: getTextColor('dark');
  font-weight: 420;
}
