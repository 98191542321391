@import "~@sonnen/shared-web/abstracts";

.c-lead-vpp-check-modal {
  height: 100vh;

  @include breakpointUp(SM) {
    height: 550px;
  }
}

.c-lead-vpp-check-modal--shorter {
  height: 100vh;

  @include breakpointUp(SM) {
    height: 450px;
  }
}

.c-lead-vpp-check-modal__form {
  width: 100%;
  height: 100%;
}

.c-lead-vpp-check-modal__buttons {
  width: 100%;

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
  }
}

.c-lead-vpp-check-modal__buttons-wrapper {
  @include breakpointDown(SM) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--gap-3) 0;
  }
}

.c-lead-vpp-check-modal__footer-button{
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    margin-right: var(--gap-3);
    margin-bottom: unset;
  }
}

.c-lead-vpp-check-modal__warning {
  margin-top: var(--gap-3);
}

.c-lead-vpp-check-modal__questions-container {
  position: relative;
}
