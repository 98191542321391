// TODO: this was brought raw from shared-web, clean-up if necessary

// ==========================================================================
// Global CSS Custom Properties
//
// *1. Grid base.
// *2. Horizontal space between page content and screen edge. Very useful
//     for setting or resetting components' spaces on mobiles.
// *3. Vertical space between page sections.
// *4. Horizontal space between columns. Must not be bigger than
//     (2 * var(--gutter-layout)).
// *5. Main padding inside card components.
// *6. Vertical space between headline and next section.
// *7. For headlines with smaller space on desktops.
// ==========================================================================

// stylelint-disable custom-property-empty-line-before, custom-property-pattern

:root {
    --font-family: 'Brandon Text';
    --font-family-icons: 'fontello';
    --font-size: 12px;
  
    --gap: 8; // 1
    --gap-1: calc(var(--gap) * 1px);
    --gap-2: calc(var(--gap) * 2px);
    --gap-3: calc(var(--gap) * 3px);
    --gap-4: calc(var(--gap) * 4px);
    --gap-5: calc(var(--gap) * 5px);
    --gap-6: calc(var(--gap) * 6px);
    --gap-7: calc(var(--gap) * 7px);
    --gap-8: calc(var(--gap) * 8px);
    --gap-9: calc(var(--gap) * 9px);
    --gap-10: calc(var(--gap) * 10px);
  
    --header-height: var(--gap-7);
    --footer-height: 200px; // @TODO: Delete
    --content-height: calc(100vh - (var(--header-height) + var(--footer-height))); // @TODO: Delete after layout refactor
    --bottom-nav-height: var(--gap-7); // @TODO: Move to mobile Layout component
  
    // commented out because they require more imports from shared-web
    // --container-max-width: #{mapDeepGet($config, BREAKPOINTS, XLG)};

    --container-narrow-max-width: 1100px; // @TODO: Delete after layout refactor
    --side-nav-width: 250px; // @TODO: Delete, let <Layout /> handle it.
  
    --gutter-h: var(--gap-3); // @TODO: Replace with --gutter-layout
    --gutter-v: var(--gap-4); // @TODO: Replace with --gutter-section
    --gutter-layout: var(--gap-3); // 2
    --gutter-section: var(--gap-3); // 3 @TODO: Add to components in both portals
    --gutter-columns: var(--gap-6); // 4
    --gutter-card: var(--gap-3); // 5 @TODO: Add to components in both portals
    --gutter-headline: var(--gap-3); // 6
    --gutter-headline-small: var(--gap-1); // 6
    --gutter-headline-static: var(--gap-3); // 7
  
    --border-radius: 3px;
    --border-radius-big: 5px;
  
    --z-index-map: 401; // @TODO: Delete after CP map refactor
    --z-index-modal: 104;
    --z-index-popup: 103;
    --z-index-dropdown: 102;
    --z-index-mobile-nav: 101;
    --z-index-header: 100;
    --z-index-bottom-nav: 99;
    --z-index-pace: 97; // @TODO: Delete
    --z-index-alert: 96;
    --z-index-banner: 95;
    --z-index-tooltip: 94;
    --z-index-controls-menu: 94;
  
    // commented out because they require more imports from shared-web
    // @include breakpointUp(SM) {
    //   --footer-height: 80px;
    //   --gutter-h: var(--gap-5);
    //   --gutter-layout: var(--gap-5);
    //   --gutter-section: var(--gap-4);
    //   --gutter-card: var(--gap-4);
    //   --gutter-headline: var(--gap-5);
    // }
  
    // @include breakpointUp(NAV) {
    //   --header-height: 80px;
    // }
  }
  