@import '~@sonnen/shared-web/abstracts';

.c-under-construction {
  --icon-size: 150px;

  display: flex;
  justify-content: center;
  padding: var(--gap-3) 0 var(--gap-10);

  @include breakpointUp(NAV) {
    --icon-size: 200px;

    padding: var(--gap-8) 0;
  }
}
