@import '~@sonnen/shared-web/abstracts';
@import '../DatePicker/DatePicker.component.scss';

.c-time-range-picker {
  @include fadeOut();
  position: relative;
  width: 100%;
  height: auto;
  transition: visibility 200ms ease 150ms,
              opacity 200ms ease 150ms;

  &.is-active {
    @include fadeIn();
  }
}
.c-time-range-picker__content {
  position: relative;
  background: getColor('white');
  transform: translateX(-35%); // position modal farther from browser window left side

  @include breakpointUp(XS) {
    width: 870px;
  }
}
.c-time-range-picker__content--arrow-top {
  @include createTriangleArrow(
    $_side: 'top',
    $_offset: '24% - 6px', // move arrow underneath first date input field
  );
  @include dropdown(
    $_top: var(--gap-4),
    $_left: 50%,
    $_padding: 0
  );
}
.c-time-range-picker--arrow-top {
  @include boxShadow($_side: 'bottom', $_inset: inset);
}
.c-time-range-picker--arrow-bottom {
  @include boxShadow($_side: 'bottom');
}

// these are classes from shared-web
// only shared-web classes inside this parent class are affected by these changes
.wrapper-for-shared-web-modified-classes {
  .sw-time-range-picker {
    &__footer {
      padding: 12px; // removes padding under footer buttons
    }
    &__separator {
      width: 100%; // separator line is longer than container; 100% makes it be equally long
      margin: 0; // less padding bottom -> no need to scroll to press buttons
    }
    &__tab-wrapper {
      height: 300px;
    }
    &__day-picker {
      flex: 0; // doesn't let arrow container expand to entire width of invisible content
    }
  }
  .sw-day-picker .DayPicker-Caption {
    margin: 0; // less space under months -> no need to scroll to press buttons
  }
  .sw-month-picker {
    width: 620px; // limits width of Month tab
    margin-left: 40px; // adds space on the left
  }
  .sw-day-picker .DayPicker-Month {
    width: 280px; // limits month width
  }
  .sw-time-range-picker__quick-links.sw-time-range-picker__quick-links--day {
    padding: 0 10px; // positions quick selector labels
  }
  .sw-time-range-picker__view-wrapper.sw-time-range-picker__view-wrapper--spacer {
    height: 300px; // sets height of arrow container so they don't jump upo and down when month is longer
  }
}