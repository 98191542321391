@import '~@sonnen/shared-web/abstracts';

.news-read-tag {
  display: inline-block;
  height: var(gap--3);
  padding: 4px 10px;
  border-radius: 4px;
  background-color: getColor('blue');
  line-height: 1.4;
}

.news-read-tag__text {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: getTextColor('white');
}
