@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-step-list-table {
  @include borderRadius();
  @include boxShadow();
  margin-bottom: var(--gap-4);

  .c-list-table__header {
    border-top: none;
    border-bottom: 1px solid getColor('grey-lighter');
    display: flex;
    flex-direction: column;
    padding: 0;
    text-align: center;
  }

  .c-list-table__header__main-content,
  .c-list-table__header__additional-content {
    padding: var(--gap-5) var(--gap-7);
    position: relative;

    @include breakpointDown(LG) {
      padding: var(--gap-2) var(--gap-10);
    }

    @include breakpointDown(XS) {
      padding: var(--gap-2) var(--gap-5);
    }
  }

  .c-list-table__header__additional-content {
    background-color: getColor('white');
    padding-top: calc(var(--gap-1) * 3.5);
    border-bottom: 1px solid getColor('grey-lighter');
  }
  
  .c-list-table__arrow {
    background-color: getColor('blue');
    border-radius: 50%;
    height: 22px;
    margin-top: -11px;
    position: absolute;
    right: var(--gap-5);
    top: 50%;
    transition: transform 400ms ease-in-out;
    width: 22px;

    @include breakpointDown(XS) {
      right: var(--gap-1);
    }

    @include breakpointDown(LG) {
      right: 20px;
    }

    &::after {
      content: config(ICONS, ANGLE);
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-family: var(--font-family-icons);
      font-size: 12px;
      text-align: center;
      transform: rotate(90deg);
      // padding top due to rotation
      padding-top: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .c-list-table__headline {
    color: getTextColor('dark');
    font-size: 20px;
    font-weight: 330;
    margin-bottom: var(--gap-2);
  }

  .c-list-table__description {
    color: getTextColor('grey');
    font-size: 14px;
    font-weight: 390;
    margin-bottom: 0;
  }

  .c-list-table__table {
    border: none;
    padding: var(--gap-4) var(--gap-7);

    @include breakpointDown(LG) {
      padding: var(--gap-4);
    }
  }

  .c-list-table__table-row {
    background-color: getColor('grey-lightest');
    margin-bottom: var(--gap-3)
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--collapsed {
    .c-list-table__arrow {
      transform: rotate(180deg);
    }
  }
}
