@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-step-status {
  border-radius: 10px;
}

$_status_colors: (
  done: (
    getColor('green-lighter'),
    getTextColor('green-dark'),
  ),
  inProgress: (
    getColor('yellow-lighter'),
    getTextColor('yellow-dark'),
  ),
  needsRegeneration: (
    getColor('yellow-lighter'),
    getTextColor('yellow-dark'),
  ),
);

@each $_status, $_color in $_status_colors {
  .c-setup-tool-step-status--#{$_status} {
    background-color: nth($_color, 1);
    color: nth($_color, 2);
  }
}
