@import '~@sonnen/shared-web/abstracts';

.c-widget {
  --widget-card-color-protected: var(--widget-card-color, #{getColor('white')});
  --widget-padding-v-protected: var(--widget-padding-v, 25px);
  --widget-padding-h-protected: var(--widget-padding-h, 20px);

  &--clickable {
    cursor: pointer;
  }

  &__header {
    @include borderRadius();
    @include boxShadow();
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 15px;
    background-color: getColor('white');
    line-height: 50px;
    z-index: 1;
  
    &.clickable {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__header-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
  }

  &__header-item {
    margin: 0 10px;
  }

  &__icon {
    @include createIcon($_width: 22px, $_color: getColor('grey-darkest'));
    display: flex;
    font-size: 22px;
    margin-right: var(--gap-1);
    color: getColor('grey-darkest');
    @include breakpointDown(XS) {
      display: none;
    }
  }

  &__heading {
    font-size: 16px;
    font-weight: 420;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include breakpointDown(XS) {
      font-size: 20px;
      font-weight: 330;
    }
  }
  
  &__link {
    cursor: pointer;
    line-height: 0;
    margin-left: auto;
  }
  
  &__body-wrapper {
    @include borderRadius();
    @include boxShadow();
    background-color: var(--widget-card-color-protected);
  }
  
  &__img {
    width: 100%;
  }
  
  &__body {
    padding: var(--widget-padding-v-protected) var(--widget-padding-h-protected);
  }
  
  &__notification {
    padding-top: var(--gap-2);
    padding-bottom: var(--gap-1);
  
    &:before {
      content: '';
      position: absolute;
      left: 24px;
      right: 24px;
      margin: 0 24px;
      border-top: 1px solid getColor('grey-lighter');
    }
  }
}






