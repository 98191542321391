@import '~@sonnen/shared-web/abstracts';

.c-form-input {
  --input-font-size: 14px;
  --input-text-align: left;
  --input-icon-width: var(--gap-5);
  --input-margin-bottom-protected: var(--input-margin-bottom, var(--gap-4));
  position: relative;
  margin-bottom: var(--input-margin-bottom-protected);
}

.c-form-input--no-gap {
  margin-bottom: 0;
}

.c-form-input__container {
  position: relative;
  display: flex;
  align-items: center;
}

.c-form-input__label-wrapper {
  display: flex;
}

.c-form-input__label-wrapper--disabled {
  opacity: 0.7;
}

.c-form-input__unit-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
}

.c-form-input__unit-wrapper--gap-right {
  right: var(--input-icon-width);
}

.c-form-input__unit {
  padding-right: var(--gap-2);
  padding-left: var(--gap-2);
  color: getTextColor('grey');
  text-align: right;
}

.c-form-input__icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--input-icon-width);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.c-form-input__icon-checkmark {
  @include createIcon($_width: 12px, $_height: 12px, $_color: getColor('green'));
}

.c-form-input__icon-warning {
  @include createIcon($_width: var(--gap-2), $_height: var(--gap-2), $_color: getColor('yellow'));
}

.c-form-input__icon-checkmark,
.c-form-input__icon-warning {
  margin-right: var(--gap-2);
}

.c-form-input__icon-warning-wrapper {
  cursor: auto;
}

.c-form-input__icon-warning-wrapper--hoverable {
  cursor: pointer;
}

.c-form-input__bubble-wrapper {
  position: absolute;
  right: -1px;
  margin-top: var(--gap-1);
  width: 210px;
  line-height: 1.4;
  z-index: 2;
}

.c-form-input__field {
  @include borderRadius;
  flex: 1 0 auto;
  height: var(--gap-5);
  padding: 12px 15px;
  font-family: var(--font-family);
  font-size: var(--input-font-size);
  font-weight: 300;
  line-height: 1.4;
  color: getTextColor('dark');
  border: 1px solid getColor('grey-light');
  background-color: getColor('white');
  box-shadow: none;
  text-align: var(--input-text-align);

  &::placeholder {
    color: getTextColor('grey');
    font-weight: 300;
  }
}

.c-form-input__field:focus {
  border: 1px solid getColor('blue');
}

.c-form-input__field[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.c-form-input__field[type='date'],
.c-form-input__field[type='time'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;

  &::-ms-clear,
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.c-form-input__field--error {
  border-color: getColor('red');
  color: getTextColor('red');
}

.c-form-input__field--disabled {
  color: getTextColor('grey');
  background-color: getColor('grey-lightest');
}

.c-form-input__field--clickable {
  cursor: pointer;
}

.c-form-input__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-input__error-message {
  color: getTextColor('red');
  font-size: 12px;
}

.c-form-input__info-icon {
  @include createIcon($_color: getColor('blue'), $_width: 18px, $_height: 18px);
  background-color: transparent;
  cursor: pointer;
}

.c-form-input__info {
  position: relative;
  margin-left: var(--gap-1);
}

.c-form-input__info-bubble-wrapper {
  position: absolute;
  top: 30px;
  right: -116px;
  width: 250px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
  z-index: 11;
}

.c-form-input__bottom-warning {
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  line-height: normal;
  text-align: left;
  margin-top: var(--gap-1);
}

.c-form-input__bottom-warning--lowered {
  margin-top: var(--gap-3);
}

.c-form-input__bottom-warning-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--gap-1);
  padding: 0 3px;
  width: var(--gap-2);
  height: var(--gap-2);
  border-radius: 50%;
  background: getColor('yellow');
}

.c-form-input__bottom-warning-icon {
  @include createIcon($_color: getColor('white'), $_width: 8px);
}

.c-form-input__helper-label {
  position: absolute;
  padding: var(--gap-1) 0 0 var(--gap-1);
  font-weight: 390;
  font-size: 12px;
  line-height: 16px;
}
