@import '/src/app/designSystem/styles/';

.dropdown-content {
  user-select: none;
  pointer-events: none;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;

  &.is-enter,
  &.is-enter-done,
  &.is-exit-active {
    @include fadeIn();
  }

  &.is-exit-done {
    @include fadeOut($_transition: null);
  }
}

.dropdown-label-and-button {
  box-sizing: border-box;
  background-color: $white;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $grey_200;
  margin: 0;

  &__collapsed {
    width: 100%;
    height: 100%;
    text-align: justify;
    padding: 10px;
    padding-left: 14px;

    &:hover,
    >* {
      cursor: pointer;
    }
  }

  .label {
    white-space: nowrap;
    font-size: 14px;
  }

  .toggle-dropdown-button {
    top: 7px;
    right: 8px;
    position: absolute;
    z-index: 1;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    transition: transform 300ms ease-in-out;

    &>svg {
      margin-top: 1px;
      stroke: $blue_500;
      height: 24px;
      transition: transform 300ms ease-in-out;
    }
  }
}
