@import '~@sonnen/shared-web/abstracts';

.c-form-editor-input {
  margin-bottom: var(--gap-4);
  margin-top: var(--gap-4);
}

.c-form-editor-input--no-gap {
  margin-bottom: 0;
}

.c-form-editor-input__label {
  display: block;
  margin: 0 0 var(--gap-1);
  font-size: 13px;
  font-weight: 420;
  color: getTextColor('grey');
}

.c-form-editor-input__warning {
  display: flex;
  margin-bottom: var(--gap-1);
}

.c-form-editor-input__warning-icon {
  @include createIcon($_width: 16px, $_color: getColor('grey'));
  margin-right: var(--gap-1);
}

.c-form-editor-input__warning-text {
  line-height: 1.2;
  color: getTextColor('grey');
}

.c-form-editor-input__field {
  font-family: var(--font-family);
  color: getTextColor('dark');

  .public-DraftEditor-content {
    min-height: var(--gap-8);
    font-size: 14px;
  }
}

.c-form-editor-input__field--error {
  border-color: getColor('red');
}

.c-form-editor-input__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-editor-input__error-message {
  color: getTextColor('red');
  font-size: 12px;
}

.ql-toolbar.ql-snow {
  border-color: getColor('grey-light');
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.ql-container.ql-snow {
  border-color: getColor('grey-light');
  border-radius: 0 0 var(--border-radius) var(--border-radius);

}

.ql-editor {
  font-family: 'Brandon Text';
  font-size: 16px;
  min-height: 120px;

  a {
    color: getTextColor('blue');
    font-size: 16px;
    font-style: normal;
    font-weight: 420;
  }

  blockquote {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    border: none !important;
    padding-left: 0 !important;
    margin: 0 !important;
  }
}

.ql-toolbar .ql-font .ql-picker-label {
  width: 120px;
}

.ql-font-headline2 {
  font-weight: 420;
}

.ql-font-headline3 {
  font-weight: 400;
}

.ql-font-headline2,
.ql-font-headline3 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  margin: var(--gap-3) 0 var(--gap-2);
}

.ql-font-bodyText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.ql-font-bodyTextBold {
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
}
