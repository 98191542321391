@import '~@sonnen/shared-web/abstracts';

.c-status-filter__trigger {
  --field-height: 45px;
}

.c-status-filter {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpointUp(XS) {
    align-items: center;
  }

  @include breakpointUp(SM) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.c-status-filter__trigger {
  @include borderRadius();
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  width: 100%;
  height: var(--field-height);
  border: 1px solid getColor('grey-light');
  padding: 5px 0 5px 15px;
  background-color: getColor('white');
  color: getTextColor('dark');
}

.c-status-filter__trigger-content {
  display: inline-flex;
  align-content: center;
  align-items: center;
  width: calc(100% - var(--gap-4));
  font-size: 14px;
}

.c-status-filter__trigger-icon {
  position: relative;
  flex: 0;
  align-self: center;
  min-width: var(--gap-4);
  height: inherit;

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-icons);
    font-size: 12px;
    color: getColor('blue');
    text-align: center;
    content: config(ICONS, ANGLE);
    transition: transform 400ms ease-in-out;
    transform: rotate(-90deg);

    .c-status-filter__dropdown.is-active & {
      transform: rotate(90deg);
    }
  }
}

.c-status-filter__dropdown-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpointUp(XS) {
    flex-direction: row;
    align-items: center;
  }
}

.c-status-filter__label {
  font-size: 12px;
  line-height: 1.4;
  white-space: nowrap;
  margin: var(--gap-1) 0;

  @include breakpointUp(SM) {
    font-size: 14px;
  }
}

.c-status-filter__dropdown {
  min-width: 305px;
  position: relative;
  display: flex;

  @include breakpointUp(MD) {
    width: 270px;
  }
}

.c-status-filter__dropdown-wrapper,
.c-status-filter__label {
  margin-right: var(--gap-1);

  @include breakpointUp(MD) {
    margin-right: var(--gap-2);
  }
}

.c-status-filter__dropdown-content {
  @include borderRadius();
  @include boxShadow($_side: 'bottom');
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  max-height: var(--height-protected);
  margin: 0;
  border: 1px solid getColor('grey-light');
  padding: 15px;
  background-color: getColor('white');
  opacity: 0;
  list-style: none;
  overflow-y: auto;
  transform: scaleY(0);
  transform-origin: center top;
  z-index: calc(var(--z-index-header) - 1);
  -webkit-overflow-scrolling: touch;

  .is-enter > &,
  .is-enter-done > & {
    opacity: 1;
    transform: scaleY(1);
    transition: opacity var(--transition-enter-ms) ease-in-out, transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    opacity: 0;
    transform: scaleY(0);
    transition: opacity var(--transition-leave-ms) ease-in-out, transform var(--transition-leave-ms) ease-in-out;
  }
}

.c-status-filter__status-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--gap-1) 0;
  border-top: 1px solid getColor('grey-light');

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
}

.c-status-filter__info {
  font-size: 14px;
  color: getTextColor('dark');
  padding-bottom: var(--gap-2);
}

.c-status-filter__button-wrapper,
.c-status-filter__dropdown-wrapper {
  @include breakpointDown(SM) {
    margin-bottom: var(--gap-1);
  }
}
