@import '~@sonnen/shared-web/abstracts';

.c-case-report-form-info {
  margin: 0 calc( var(--gap-4) * -1) var(--gap-4);

  @include breakpointUp(SM) {
    padding: var(--gap-6) 0;
    background: getColor('grey-lightest');
  }

  @include breakpointUp(NAV) {
    margin: 0 -100px var(--gap-4);
  }
}

.c-case-report-form-info__wrapper {
  @include boxShadow();
  margin: 0 auto;
  padding: var(--gap-4);
  border-radius: var(--border-radius);
  background: getColor('white');

  @include breakpointUp(SM) {
    max-width: 600px;
    padding: var(--gap-4) var(--gap-5);
  }

  @include breakpointUp(MD) {
    max-width: 800px;
  }
}

.c-case-report-form-info__header {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-2);
}

.c-case-report-form-info__headline {
  font-size: 18px;
  font-weight: 420;
}

.c-case-report-form-info__icon {
  @include createIcon($_width: 21px, $_color: getColor('blue'));
  margin-right: var(--gap-3);
}

.c-case-report-form-info__text { 
  margin-left: 45px;
  line-height: 1.5;
  font-size: 16px;
  color: getTextColor('dark');

  ol {
    margin: 0;
    padding: 0;
  }

  ul {
    padding-left: var(--gap-2);
  }
}