@import '~@sonnen/shared-web/abstracts';

.c-nav-link {
  display: flex;
  width: auto;

  @include breakpointUp(NAV) {
    min-height: inherit;
  }
}

.c-nav-link__action-container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 280px;
  min-height: inherit;
  font-weight: 420;
  font-family: inherit;
  transition: color 0.25s ease;
  text-align: left;

  .c-nav-link--desktop > & {
    justify-content: center;
    padding: 0 15px;

    // this breakpoint is where the navbar breaks because of introducing the Toolbox button
    @include breakpointUp(1300px) {
      padding: 0 20px;
    }

    @include breakpointDown(SM) {
      padding: 0 10px;
    }

    &.is-active::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .c-nav-link--desktop-dark > & {
    color: getTextColor('grey');
    font-size: 14px;

    &:hover,
    &.is-active {
      color: getTextColor('blue');
    }

    &.is-active::after {
      border-top: 4px solid getColor('blue');
      bottom: -2px;
      border-radius: 4px 4px 0 0;
    }
  }

  .c-nav-link--desktop-white > & {
    color: getTextColor('white');
    font-size: 16px;

    &:hover,
    &.is-active {
      color: getColor('blue-light');
    }

    &.is-active {
      font-weight: 600;
    }

    &.is-active::after {
      border-top: 4px solid getColor('blue-light');
      bottom: 0px;
      border-radius: 4px 4px 0 0;
    }
  }

  .c-nav-link--mobile > & {
    justify-content: flex-start;
    max-width: 100%;
    padding: 15px 30px;
    border-top: 1px solid getColor('grey-light');
    color: getTextColor('white');
    background-color: getColor('grey-darkest');
    font-size: 14px;

    &.is-active {
      color: getColor('blue-light');
    }

    &:hover {
      color: getColor('blue-light');
    }
  }

  .c-nav-link--mobile:first-of-type & {
    border-top: 0;
  }

  .c-nav-link--no-border & {
    border: 0;
  }

  .c-nav-link--nowrap & {
    white-space: nowrap;
  }
}

.c-nav-link--w-warning {
  position: relative;
}

.c-nav-link--desktop:last-child > .c-nav-link__action-container {
  padding-right: 0px;
}
