@import '~@sonnen/shared-web/abstracts';

.c-lead-impact-analysis-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-lead-impact-analysis-tile__action-container {
  display: flex;
}

.c-lead-impact-analysis-tile__header {
  margin-bottom: var(--gap-1);
  font-size: 18px;
  color: getTextColor('dark');
}

.c-lead-impact-analysis-tile__header--blue {
  color: getColor('blue');
}

.c-lead-impact-analysis-tile__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.c-lead-impact-analysis-tile__header--gray,
.c-lead-impact-analysis-tile__body-text--gray {
  color: getTextColor('grey');
}

.c-lead-impact-analysis-tile__body-text {
  margin-bottom: 0;
  margin-right: 4px;
  font-size: 12px;
}

.c-lead-impact-analysis-tile__body-status-wrapper {
  margin: var(--gap-1) 0;
}

.c-lead-impact-analysis-tile__start-icon {
  @include createIcon(
    $_width: 12px,
    $_color: getColor('white')
  );
}

.c-lead-impact-analysis-tile__footer-status {
  display: flex;
  align-items: center;
  margin-top: 9px;
}

.c-lead-impact-analysis-tile__footer-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
}

.c-lead-impact-analysis-tile__vpp-warning {
  margin: var(--gap-2) calc(-1 * var(--gap-3)) calc(-1 * var(--gap-3));
}
