@import '~@sonnen/shared-web/abstracts';

.c-lead-overview__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(getColor('white'), 0.8);
  z-index: 99;
}

.c-lead-overview__grid {
  margin: 0 calc(var(--gutter-h) / -2);
}

.c-lead-overview__grid-item {
  padding: 0 0 calc(var(--gutter-h));
  width: 100%;
}
