@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-configurations {
  align-items: stretch;

  &__loader {
    margin: var(--gap-6) 0;
  }

  &__pagination__nav {
    display: flex;
    margin-bottom: var(--gap-6);

    @include breakpointUp(MD) {
      display: none;
    }
  }

  &__pagination__btn {
    width: 50%;
    padding: 2px;
    text-align: center;
  }

  &__general-error {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--gap-3) 0 var(--gap-4);
    color: getTextColor('red');
    font-weight: 420;
    font-size: 14px;

    @include breakpointUp(MD) {
      margin: 0 0 var(--gap-4);
    }
  }

  &__warning {
    margin: var(--gap-4) 0;
    display: flex;
    justify-content: center;
    margin: var(--gap-4) 0;

    @include breakpointUp(MD) {
      margin-bottom: 0;
    }
  }

  &__max-configurations-warning-wrapper {
    margin-bottom: var(--gap-3);
  }

  &__products {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--gap-3);

    @include breakpointUp(SM) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__header {
    font-size: 20px;
    margin-bottom: 20px;

    &:not(:first-of-type) {
      margin-top: var(--gap-4);
    }
  }

  &__max-offers-warning {
    margin-bottom: var(--gap-3);
  }
}
