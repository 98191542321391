@import '~@sonnen/shared-web/abstracts';

.c-form-rich-text-input {
  position: relative;
  z-index: 1;
}

.c-send-news-carrera__success-text {
  margin-bottom: var(--gap-2);
  color: getTextColor('green');
  font-size: 16px;
}
