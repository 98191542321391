@import '~@sonnen/shared-web/abstracts';

.c-warning-box {
  @include boxShadow();
  @include borderRadius();
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 var(--gap-2);
  padding: var(--gap-1) var(--gap-2);
}

.c-warning-box__icon {
  margin-right: var(--gap-1);
  font-size: 16px;
}
