@import '~@sonnen/shared-web/abstracts';

.news-container {
  @include boxShadow();
  padding: 30px;

  @include breakpointUp(NAV) {
    max-width: calc((53 / 81) * 100%); // news-widget -> max-width:calc((25 / 81) * 100%)
  }
}

.news-container__label {
  color: getTextColor('grey');
  font-size: 12px;
  font-weight: 420;
  text-transform: uppercase;

  @include breakpointUp(NAV) {
    font-size: 14px;
  }
}

.news-container__title {
  font-size: 20px;
  font-weight: 330;

  @include breakpointUp(NAV) {
    font-size: 24px;
  }
}

.news-container__empty-message {
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 20px;
}

.news-container__loader {
  padding: 10px;
}

.news-inbox__sidebar {
  @include breakpointUp(NAV) {
    .news-container__label,
    .news-container__title,
    .news-container__empty-message {
      margin-left: 67px;
      padding-right: 45px;
      position: relative;
    }
  }

  .news-container__label {
    margin-bottom: 14px;
  }

  .news-container__title {
    margin-bottom: 18px;
  }

  .news-container__list {
    padding: 0;

    @include breakpointUp(NAV) {
      padding: 0 45px 0 0;
    }
  }
}

.news-container__pagination {
  display: flex;
  flex-flow: initial;
  justify-content: center;
  padding: 28px 0;

  .c-pagination__page-list,
  .c-pagination__rest {
    padding: 0 4px;
  }

  .c-pagination__page {
    font-size: 14px;
    padding: 4px;
  }

  .c-pagination__btn-prev, .c-pagination__btn-next {
    font-size: 12px;
  }
}
