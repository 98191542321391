@import '~@sonnen/shared-web/abstracts';

.c-battery-live-card-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0;
}

.c-battery-live-card-body__cell-wrapper {
  flex: 0 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-battery-live-card-body__element-right {
  align-items: flex-end;
}

.c-battery-live-card-body__element-left {
  align-items: flex-start;
}
