@import '~@sonnen/shared-web/abstracts';

.c-request-access__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-request-access__button {
  flex-grow: 0;
  margin-left: auto;
  padding: 0;
}

.c-request-access {
  width: 100%;
}
