@import '~@sonnen/shared-web/abstracts';

.c-news-form-carrera--vertical {
  display: flex;
  flex-direction: column;
}

.c-news-form-carrera__column-separator {
  border-right: 1px solid #ededed;
}

.c-news-form-carrera__column-separator--horizontal {
  margin-bottom: var(--gutter-section);
  border-right: 0;
  border-bottom: 1px solid #ededed;
}

.c-news-form-carrera__date-input {
  max-width: 240px;
}

.c-news-form-carrera__section--wide {
  max-width: unset;
}

.c-news-form-carrera__section-title {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 420;
}

.c-news-form-carrera__btn-wrapper {
  margin-left: auto;
  padding: 0 var(--gap-3);
}

.c-news-form-carrera__news-style-dropdown > div > div > div > ol {
  z-index: 11;
}

.c-news-form-carrera__countries-dropdown > div > div > ol {
  overflow: auto;
}
