@import '~@sonnen/shared-web/abstracts';

.c-lead-modal-lead-name {
  display: flex;
  align-items: center;
  position: static;
  top: 0;
  height: var(--gap-6);
  margin: calc(var(--modal-layout-body-padding-v-protected) * -1)
    calc(var(--modal-layout-body-padding-h-protected) * -1) 0;
  padding: 0 var(--modal-layout-body-padding-h-protected);
  background-color: getColor('white');
  border-bottom: 1px solid getColor('grey-light');
  font-size: 14px;
  z-index: 1;

  @include breakpointUp(SM) {
    position: sticky;
  }
}

.c-lead-modal-lead-name__lead-title {
  color: getTextColor('grey');
  margin-right: var(--gap-3);
}

.c-lead-modal-lead-name__lead-address {
  font-weight: 420;
}
