@import "~@sonnen/shared-web/abstracts";

.c-vpp-documentation-form__action-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.c-vpp-documentation-form__save-button {
  margin-right: 32px;
}
