@import '~@sonnen/shared-web/abstracts';

$_social-items: (
  twitter: $color-twitter,
  facebook: $color-facebook,
  youtube: $color-youtube,
  linkedin: $color-linkedin
);

#instagramGradientId {
  --color-stop-1: #f09433;
  --color-stop-2: #e6683c;
  --color-stop-3: #dc2743;
  --color-stop-4: #bc1888;
}

.c-footer-social {
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
  background: getColor('white');
  transition: all .25s ease;
  --ds-icon-height: 30px;
  --ds-icon-width: 30px;

  @include breakpointUp(SM) {
    margin: 0 0 0 10px;
  }
}

@each $_name, $_color in $_social-items {
  .c-footer-social--#{$_name} {
    --ds-icon-color: #{$blue_900};

    &:hover {
      --ds-icon-color: #{$_color};
    }
  }
}

.c-footer-social--instagram {
  --ds-icon-color: #{$blue_900};

  &:hover {
    --ds-icon-color: #{url(#instagramGradientId)};
  }
}
