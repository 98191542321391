@import '~@sonnen/shared-web/abstracts';

.c-case-report-search-no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--gap-6);
  padding-left: var(--gap-2);
}

.c-case-report-search-no-results__title {
  font-size: 14px;
  font-weight: 420;
  line-height: 1.4;
}

.c-case-report-search-no-results__description {
  font-size: 12px;
}
