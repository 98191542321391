.c_battery_time_of_use_schedule {
  &__container {}
  &__header {
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
  }
}

.c-tariff-window-list-item__new {
  margin-bottom: 26px;

  &--button {
    margin-bottom: 28px;

    .sw-button__label {
      padding-left: 0;
      font-size: 14px;

    }
  }
  .sw-form-checkbox__text {
    font-size: 14px;
    margin-left: 10px;
  }
}
