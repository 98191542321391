@import '~@sonnen/shared-web/abstracts';

.c-asset-count-badge {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 16px;
  justify-content: flex-start;
  margin-bottom: 20px;

  @include breakpointUp(XS) {
    @include boxShadow;
    @include borderRadius;
    justify-content: flex-start;
    margin-bottom: 0;
    padding: 12px 24px;
    font-size: 26px;
  }
}

.c-asset-count-badge__number {
  padding: 0 var(--gap-1);
  color: getColor('green');
  font-size: 22px;
  font-weight: 420;
  display: flex;

  @include breakpointUp(XS) {
    font-size: 40px;
    padding: 0 12px;
  }
}

.c-asset-count-badge__text-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: center;
  height: var(--gap-3);

  @include breakpointUp(XS) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 35px;
  }
}

.c-asset-count-badge__text {
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin-left: 2px;
}

.c-asset-count-badge__icon {
  @include createIcon(
    $_width: 26px,
  );
}
