@import '/src/app/designSystem/styles/';

$dropdown-menu-width: 250px;
$dropdown-menu-height: 408px;

.filters-area {
  box-sizing: border-box;
  background-color: $grey_25;
  padding: 16px 32px;

  &__menus {
    display: flex;
    align-items: center;

    &--postal-code {
      margin-right: 8px;
    }
    &--meter-installation-status {
      margin-right: 14px;
    }
  }

  &__filter-by {
    margin-right: 16px;
  }
}
