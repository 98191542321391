@import '~@sonnen/shared-web/abstracts';

.c-missing-battery-banner__button-wrapper {
  margin: var(--gap-2) 0;
}

.c-missing-battery-banner__close-icon {
  @include createIcon(
    $_width: 20px,
  );
}
