@import '~@sonnen/shared-web/abstracts';

.c-battery-live-status-list {
  margin: -4px 0;
  line-height: 1.4;
  font-size: 14px;
  color: rgba($color-dark, .7);
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--align-right {
    align-items: flex-end;
  }

  &--align-left {
    align-items: flex-start;
  }
}

.c-battery-live-status-list__row {
  display: flex;
  flex-direction: column;
  margin: 0 var(--gap-4);
  flex: 0 1 40%;

  @include breakpointDown(XS) {
    margin: 0 var(--gap-2);
  }

  &--align-right {
    align-items: flex-end;
  }

  &--align-left {
    align-items: flex-start;
  }
}

.c-battery-live-status-list__label {
  font-weight: 390;
  color: getTextColor('grey');

  &--align-right {
    text-align: end;
  }

  &--align-left {
    text-align: start;
  }
}

.c-battery-live-status-list__value {
  font-weight: 420;
  color: getTextColor('dark');
  &--start {
    text-align: start;
  }
  &--end {
    text-align: end;
  }
}
