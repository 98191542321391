@import '~@sonnen/shared-web/abstracts';
// @TODO: Remove as soon as new colors are implemented
@import '~@sonnen/shared-web/src/colors/_color-base.scss';

.battery-operating-mode-confirm-modal {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.battery-operating-mode-confirm-modal__icon-stack {
  flex: 3;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-end;
}

.battery-operating-mode-confirm-modal__info-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.battery-operating-mode-confirm-modal__info {
  font-size: 24px;
  padding-top: var(--gap-2);
}

.battery-operating-mode-confirm-modal__warning {
  flex: 2;
  justify-content: center;
  align-self: center;
  color: getTextColor('dark');
  font-size: 14px;
  max-width: 70%;
  padding-top: var(--gap-2);
}

.battery-operating-mode-confirm-modal__confirmation-container {
  display: flex;
  flex: 2;
  text-align: justify;
  align-items: center;
}

.battery-operating-mode-confirm-modal__confirmation {
  font-size: 14px;
  color: getTextColor('dark');
}

.battery-operating-mode-confirm-modal__footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.battery-operating-mode-confirm-modal__icon-self-consumption {
  @include createIcon(
    $_width: 53px,
    $_color: getColor('blue'),
  );
  position: relative;
  z-index: 2;
}

.battery-operating-mode-confirm-modal__icon-module-extension {
  @include createIcon(
    $_width: 53px,
    $_color: getColor('blue')
  );
  position: relative;
  margin-left: -91px;
  margin-top: -12px;
  z-index: 3;
  // right: 38px;
  // bottom: 12px;
}
