@import '~@sonnen/shared-web/abstracts';

$_icon-size: 24px;
$_container-size: 30px;

.c-hamburger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $_container-size;
  height: $_container-size;
}

.c-hamburger__icon {
  @include createTransformicon(
    $_name: 'menu',
    $_size: $_icon-size,
    $_color: getColor('white')
  );

  .is-active > & {
    @include createTransformicon(
      $_name: 'cross',
      $_size: $_icon-size,
      $_color: getColor('white')
    );
  }
}
