@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-invitation {
  display: flex;
  align-items: center;

  @include breakpointDown(XS) {
    margin-top: var(--gap-1);
  }

  .sw-button__label {
    white-space: nowrap;
  }
}

.c-lead-overview-invitation__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
}

.c-lead-overview-invitation__sent {
  display: flex;
  align-items: center;
  position: relative;
}

.c-lead-overview-invitation__sent-icon {
  @include createIcon($_color: getColor('green'), $_width: 12px);
  margin-right: var(--gap-1);
}

.c-lead-overview-invitation__sent-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 420;
}

.c-lead-overview-invitation__resend-button {
  flex-grow: 0;
  margin-left: 10px;
  padding: 0;
}

.c-lead-overview-invitation__send-invitation {
  position: relative;
}

.c-lead-overview-invitation__bubble-wrapper {
  position: absolute;
  top: 30px;
  right: -15px;
  width: 270px;
  font-size: 12px;
  font-weight: 300;
  z-index: calc(var(--z-index-banner) + 1);
  line-height: 1.4;
  white-space: pre-wrap;
}

.c-lead-overview-invitation__info-icon {
  @include createIcon($_color: getColor('blue'),
  $_width: 18px);
  background-color: transparent;
  cursor: pointer;
}

.c-lead-overview-invitation__tooltip {
  position: relative;
  margin-left: 4px;
}

.c-lead-overview-invitation__sent-text {
  display: flex;
  align-items: center;
}
