@import '~@sonnen/shared-web/abstracts';

.c-certificate-warning {
  @include boxShadow();
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--gap-2);
  padding: var(--gap-1) var(--gap-2);

  @include breakpointUp(XS) {
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--gap-3);
  }
}

.c-certificate-warning__icon {
  margin-right: var(--gap-1);
  font-size: 16px;
}

.c-certificate-warning__link-button {
  margin-left: auto;
}

.c-certificate-warning__message {
  margin: var(--gap-1) 0;
  font-size: 14px;
  font-weight: 300;

  @include breakpointUp(XS) {
    margin: 0;
  }
}
