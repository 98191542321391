@import '~@sonnen/shared-web/abstracts';
@import '/src/app/designSystem/styles/';

.c-form-header__title {
  display: flex;
  align-items: flex-end;
  vertical-align: middle;
  padding-right: var(--gutter-h);
  font-size: 26px;
  font-weight: 300;
  color: getTextColor('dark');
  line-height: 1.4;

  @include breakpointUp(SM) {
    font-size: 32px;
  }

  @include breakpointUp(NAV) {
    max-width: 50%;
  }
}

.c-form-header__wrapper {
 padding: 0;
}

.c-form-header__icon {
  display: inline-block;
  fill: getTextColor('blue');
  height: 10px;
  margin-right: 5px;
}