@import '~@sonnen/shared-web/abstracts';

.c-lead-vpp-question {
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: var(--gap-3);

    @include breakpointUp(XS) {
      margin-bottom: var(--gap-4);
    }
  }
}

.c-lead-vpp-question__radio-group {
  display: flex;
  align-items: center;
  margin-top: var(--gap-2);
}
