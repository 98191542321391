@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  @include breakpointUp(SM) {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: var(--gap-1);
  }

  @include breakpointDown(MD) {
    margin-bottom: var(--gap-3);
  }
}

.c-lead-overview__offer-statuses {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: var(--gap-3);

  @include breakpointUp(SM) {
    flex-wrap: nowrap;
    padding-right: var(--gap-6);
  }
}

.c-lead-overview-header__upper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.c-lead-overview-header__lower {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-lead-overview-header__lower-right {
  display: flex;
  align-items: center;
}

.c-lead-overview-header__partner {
  display: flex;
  align-items: center;
}

.c-lead-overview-header__partner-wrapper {
  display: flex;
}

.c-lead-overview-header__separator {
  height: 24px;
  border-right: 1px solid getColor('grey');
  margin: 0 var(--gap-2);
}

.c-lead-overview-header__separator--bottom {
  width: 100%;
  border-bottom: 1px solid getColor('grey-lighter');
  margin: var(--gap-2) 0;
}

.c-lead-overview-header__reassign {
  margin-left: var(--gap-2);
}

.c-lead-overview-header__reassign-icon {
  @include createIcon($_width: 16px, $_color: getColor('blue'));

  &:hover {
    @include createIcon(
      $_width: 16px,
      $_color: lighten(getColor('blue'), 15%)
    );
  }
}

.c-lead-overview-header__unassigned {
  margin: auto;
}

.c-lead-overview-header__lead-statuses {
  display: flex;
}

.c-lead-overview-header__lead-statuses--mobile {
  display: flex;
  margin-top: var(--gap-3);
}

.c-lead-overview-header__accordion {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  transition: all 200ms ease;
}

.c-lead-overview-header__mobile-upper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
}

.c-lead-overview-header__mobile-lower {
  width: 100%;
}

.c-lead-overview-header__mobile-lower-right {
  display: flex;
  align-items: flex-end;
}

.c-lead-overview-header__accordion-wrapper {
  margin-bottom: 10px;
}
