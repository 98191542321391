@import '~@sonnen/shared-web/abstracts';

.c-form-input-checkbox-group {
  margin-right: var(--gap-3);
  margin-bottom: var(--gap-4);
}

.c-form-input-checkbox-group__text {
  color: getTextColor('grey');
  font-size: var(--font-size);
  font-weight: 420;
  line-height: 1.5;
}

.c-form-input-checkbox-group__container {
  display: flex;
}

.c-form-input-checkbox-group__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-input-checkbox-group__error-message {
  color: getTextColor('red');
  font-size: 12px;
}

.c-form-input-checkbox-group--modal-form {
  .c-form-input-checkbox-group__text {
    font-size: 13px;;
  }

  .sw-form-checkbox {
    margin: 0;
  }
}
