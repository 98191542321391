@import '/src/app/designSystem/styles/';

.sortable-header-cell {
  cursor: pointer;
  display: flex;
  align-items: flex-end;

  &__arrows {
    display: inline-grid;
    margin: 0 0 6px 6px;
  }
  &__arrow {
    width: 12px;
    fill: $grey_900;
  }
  &__arrow-greyed-out {
    fill: $grey_200;
  }
  &__arrow-hidden {
    visibility: hidden;
  }
  &__arrow-up {
    margin-bottom: 3px;
  }
  &:hover {
    text-decoration: underline;
  }
  &.disabled-sorting {
    cursor: text;
    &:hover {
      text-decoration: none;
    }
  }
}
