@import '~@sonnen/shared-web/abstracts';

.battery-operating-mode-operation {
  &__container {
    padding-bottom: var(--gap-3);
  }

  &__radio-group label {
    font-size: 14px;
    font-weight: 400;
    color: getTextColor('dark');
  }

  &__link {
    display: inline-block;
    padding: 0;
  }

  &__info {
    font-size: 14px;
    line-height: 1.4;
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin: 0 calc(var(--accodion-content-padding) * -1);
    padding: var(--gap-3) var(--accodion-content-padding) 0;
    border-top: 1px solid getColor('grey-lighter');

    @include breakpointUp(XXS) {
      flex-direction: row;
    }
  }
}
