@import "~@sonnen/shared-web/abstracts";

.c-dso-commissioning-data__item {
  width: 45.5%;
}

.c-dso-commissioning-form__container {
  background: #ffffff;
  padding: var(--gap-5) var(--gap-10);

  @include breakpointDown(MD) {
    padding: var(--gap-5);
  }
}

.c-dso-commissioning-form__submit-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-dso-commissioning-go-to-setup-button {
  padding: 0;
}