@import '~@sonnen/shared-web/abstracts';

$_grey: $grey_400;

.product-card {
  @include boxShadow();
  @include borderRadius();

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--gap-1);
  padding: var(--gap-3);
  border-radius: 5px;
  transition: box-shadow 0.2s ease-in-out;

  &:not(&--disabled):not(&--decommissioned):hover {
    box-shadow: 0 0 10px 0 $_grey;
  }

  @include breakpointUp(SM) {
    width: 48%;
    max-width: 630px;
    margin-bottom: 0;
  }

  &__content {
    margin-bottom: 32px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    @include createIcon($_width: var(--gap-4), $_color: $blue_400);
    margin-right: 10px;

    &--disabled {
      @include createIcon($_color: $_grey);
    }
  }

  &__headline {
    margin-right: var(--gap-2);
  }

  &__list {
    margin-top: 16px;
    font-size: 14px;

    .title-bold {
      padding-bottom: 12px;
    }

    ul {
      padding-top: 10px;
      padding-left: 26px;
      list-style-type: disc;

      li {
        line-height: 24px;
      }
    }
  }

  &__tooltip {
    display: inline-block;
  }

  &--disabled {
    border-top: 5px solid $_grey;
    color: $_grey;
  }
  &--decommissioned {
    background-color: $grey_25;
    color: $grey_900;
  }
  &--flatDirect {
    border-top: 5px solid $blue_600;
  }
}
