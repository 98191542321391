@import '~@sonnen/shared-web/abstracts';

.c-account-header {
  position: relative;
}

.c-account-header__title {
  display: flex;
  align-items: flex-end;
  vertical-align: middle;
  padding-right: var(--gutter-h);
  font-size: 26px;
  font-weight: 330;
  color: getTextColor('dark');
  line-height: 1.4;

  @include breakpointUp(SM) {
    font-size: 32px;
  }

  @include breakpointUp(NAV) {
    max-width: 50%;
  }
}

.c-account-header__nav-icon {
  font-size: 24px;

  @include breakpointUp(SM) {
    margin-right: var(--gap-1);
  }
}

.c-account-header__icon {
  font-size: 32px;
  height: 32px;
  fill: currentColor;

  @include breakpointUp(SM) {
    margin-right: var(--gap-1);
  }
}
