@import '/src/app/designSystem/styles/';

.warning {
  display: flex;
  flex-direction: row;
  border-width: 1px 1px 1px 4px;
  border-radius: 4px;
  padding: 10px 0px 8px 0px;
  background-color: $red_50;

  &__info {
    background-color: $blue_100;
  }

  &__warning {
    background-color: $yellow_50;
  }

  &__icon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 40px;
  }

  &__text {
    line-height: 20px;
    font-weight: $_regular;
    font-size: $_14;
    margin-bottom: 0;
  }
}
