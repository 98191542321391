@import '~@sonnen/shared-web/abstracts';

.c-reset-password__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-reset-password__button {
  flex-grow: 0;
  margin-left: auto;
  padding: 0;
}

.c-reset-password .c-modal-layout__body-inner {
  padding-bottom: var(--gap-1);
}
