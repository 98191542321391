@import '~@sonnen/shared-web/abstracts';

.c-news-list-table-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: var(--gap-1) 0;

  @include breakpointUp(XS) {
    flex-direction: row;
  }
}

.c-news-list-table-item__status-decorator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
}

.c-news-list-table-item__cell {
  flex: 1 1 50%;
  margin-bottom: var(--gap-2);
  align-items: flex-start;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-bottom: 0;
  }
}

.c-news-list-table-item__text-wrapper {
  margin-right: 0;

  @include breakpointUp(XS) {
    margin-right: var(--gap-3);
  }
}

.c-news-list-table-item__text-wrapper--ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.c-news-list-table-item__cell {
  @include breakpointUp(SM) {
    flex-grow: 1;
  }
}

.c-list-table--news-list--sp {
  .c-news-list-table-item__cell:nth-child(1),
  .c-news-list-table-item__cell:nth-child(2) {
    @include breakpointUp(SM) {
      flex-grow: 2;
    }
  }
}

.c-list-table--news-list--sp {
  .c-news-list-table-item__cell:nth-child(1) {
    font-weight: 420;
  }
}

.c-list-table--news-list--sp {
  .c-news-list-table-item__cell:nth-child(3) {
    @include breakpointUp(SM) {
      flex-grow: 1.5;
    }
  }
}

.c-list-table--news-list--cp {
  .c-news-list-table-item__cell:nth-child(4),
  .c-news-list-table-item__cell:nth-child(5){
    @include breakpointUp(SM) {
      flex-grow: 2;
    }
  }

  .c-news-list-table-item__cell:nth-child(6){
    @include breakpointUp(SM) {
      flex-grow: 0.5;
    }
  }
}

.c-news-list-table-item__icon-wrapper {
  position: relative;
  margin-right: var(--gap-2);

  &:last-child {
    margin-right: 0;
  }
}

.c-news-list-table-item__icon-with-text {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 10px;
}

.c-news-list-table-item__icon {
  @include createIcon($_width: 14px, $_color: getColor('blue'));
  height: 14px;
  margin-bottom: var(--gap-1);
}

.c-news-list-table-item__dot {
  border-radius: 50%;
  height: var(--gap-1);
  margin: 4px var(--gap-1) 0 0;
  width: var(--gap-1);
}

.c-news-list-table-item__dot--active {
  background-color: getColor('green');
}

.c-news-list-table-item__dot--inactive {
  background-color: getColor('red');
}

.c-news-list-table-item__active {
  color: getTextColor('green');
  font-weight: 420;
}

.c-news-list-table-item__inactive {
  color: getTextColor('red');
  font-weight: 420;
}
