@import '~@sonnen/shared-web/abstracts';

.c-customer-battery-widget__tooltip {
	display: inline;
	margin-left: var(--gap-1);
}

.c-customer-battery-widget__grayed-out {
	opacity: 0.3;
}
