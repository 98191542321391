@import '~@sonnen/shared-web/abstracts';

.c-case-list__header {
  --case-list-header-field-height: 45px;
}

.c-case-list__search-field {
  --search-field-height: var(--case-list-header-field-height);

  @include breakpointUp(SM) {
    --search-field-width: 250px; 
  }
}

.c-case-list {
  position: relative;
}

.c-case-list__container {
  padding-top: 0;
}

.c-case-list__header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-case-list__search-field {
  flex: 1;

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-start;
    margin-right: var(--gap-2);
    margin-bottom: 0;
  }

  @include breakpointUp(MD) {
    margin-right: var(--gap-4);
  }
}

.c-case-list__status-filter {
  flex: 3;
}

.c-case-list__headline-wrapper {
  padding-top: var(--gap-3);
}

.c-case-list__pagination-summary {
  display: block;
  margin-bottom: var(--gap-1);

  @include breakpointUp(NAV) {
    margin-bottom: 0;
  }

  @include breakpointUp(MD) {
    display: flex;
    align-items: baseline;
    flex-direction: row;
  }
}

.c-case-list__pagination {
  justify-content: center;
  flex-flow: initial;
}

.c-case-list__summary {
  display: flex;

  @include breakpointUp(XS) {
    align-items: center;
  }
}