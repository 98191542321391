@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-autarky-form {
  margin-bottom: var(--gap-4);

  @include breakpointDown(MD) {
    margin-bottom: var(--gap-2);
  }
}

.c-lead-configuration-autarky-form--error {
  margin-bottom: var(--gap-6);

  @include breakpointDown(MD) {
    margin-bottom: var(--gap-4);
  }
}

.c-lead-configuration-autarky-form__inputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include breakpointDown(LG) {
    flex-direction: column;
    align-items: stretch;
  }

  @include breakpointDown(MD) {
    align-items: flex-end;
    flex-direction: row;
  }
}

.c-lead-configuration-autarky-form__input {
  margin-right: var(--gap-2);
  margin-bottom: 0;
  max-width: 160px;
  min-width: 100px;
  --input-font-size: 16px;
  --input-text-align: center;

  @include breakpointDown(LG) {
    max-width: none;
    margin-right: 0;
  }

  @include breakpointDown(MD) {
    max-width: 160px;
    margin-right: var(--gap-2);
  }
}

.c-lead-configuration-autarky-form__button {
  @include breakpointDown(LG) {
    margin-top: var(--gap-2);
  }

  @include breakpointDown(MD) {
    margin-top: none;
  }
}

.c-lead-configuration-autarky-form__button--error {
  @include breakpointDown(LG) {
    margin-top: var(--gap-4);
  }

  @include breakpointDown(MD) {
    margin-top: none;
  }
}
