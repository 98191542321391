@import '~@sonnen/shared-web/abstracts';

.c-analysis-tooltip-item {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.c-analysis-tooltip-item__inner {
  display: flex;
  align-items: flex-start;
  flex: 1 1;
}

.c-analysis-tooltip-item__text {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.c-analysis-tooltip-item__text--inline {
  flex: 1 0 0;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
}

.c-analysis-tooltip-item__value {
  font-size: 12px;
  font-weight: 420;
  line-height: 1em;
  white-space: nowrap;

  @include breakpointUp(SM) {
    margin-left: var(--gap-1);
  }
}

.c-analysis-tooltip-item__label {
  margin-top: 2px;
  font-size: 10px;
  color: getTextColor('dark');
  line-height: 1em;
  white-space: nowrap;

  @include breakpointUp(SM) {
    font-size: 12px;
  }

  .c-analysis-tooltip-item__text--inline & {
    font-size: 12px;
  }
}

.c-analysis-tooltip-item__symbol {
  display: inline-block;
  margin: 2px 4px 0 0;
  width: var(--gap-1);
  height: var(--gap-1);
  background-color: getColor('grey');

  @include breakpointUp(SM) {
    margin-top: 4px;
  }
}

.c-analysis-tooltip-item__symbol--round {
  border-radius: 50%;
}

.c-analysis-tooltip-item__symbol--line {
  margin-top: 4px;
  width: 10px;
  height: 3px;
  border-radius: 2px;

  @include breakpointUp(SM) {
    margin-top: 6px;
  }
}
