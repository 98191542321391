@import '~@sonnen/shared-web/abstracts';

.c-lead-zip-code-not-fount-modal {
  display: flex;
  text-align: center;

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 32px 0;
    padding-right: 8px;
  }

  &__button-cancel {
    color: getColor('grey-darkest') !important;
    padding: 0;
    margin-right: 40px;
  }

  &__content {
    margin: 0 88px 16px 88px;
  }

  &__icon {
    @include createIcon($_height: 80px, $_color: getColor('blue'));
    margin: 24px 0 16px 0;
  }

  &__text {
  }

  &__headline {
    font-size: 20px;
    font-weight: 330;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__info {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}
