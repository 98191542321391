@import '~@sonnen/shared-web/abstracts';

.c-setup-tool-registration-subject-modal {
  .sw-modal__dialog {
    width: 720px;
    height: 408px;
  }

  .sw-modal-layout__header {
    height: calc(12 * var(--gap-1));
  }

  .sw-modal-layout__title {
    color: black;
    font-size: 32px;
    font-weight: 300;
  }

  .sw-modal-layout__body-inner {
    font-size: 18px;
    line-height: 1.78;
    padding: var(--gap-5) var(--gap-10) var(--gap-5) var(--gap-10);

    p {
      margin: 0;
    }
  }

  .sw-modal-layout__footer {
    height: var(--gap-10);
    justify-content: flex-end;
    padding: calc(2.5 * var(--gap-1)) var(--gap-6) calc(2.5 * var(--gap-1)) var(--gap-6);
  }

  .sw-button__label {
    color: #f6f6f7;
  }

  .sw-modal__close {
    top: var(--gap-1);
    right: var(--gap-1);
  }

  .sw-modal__close-icon {
    fill: #8b8c93;
  }
}
