@import "~@sonnen/shared-web/abstracts";

.c-vpp-documentation__container {
  background: $color-white;
  padding: var(--gap-5) var(--gap-10);

  @include breakpointDown(MD) {
    padding: var(--gap-5);
  }
}

.c-vpp-documentation__success-banner {
  padding: var(--gap-2);
  text-align: center;
  background-color: $color-light-blue;
  color: $color-white;
  font-size: 16px;
  font-weight: 500;
}
