@import '~@sonnen/shared-web/abstracts';

.c-battery-operations-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--gap-1) 24px;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 420;
  color: getTextColor('dark');
}

.c-battery-operations-header__text {
  font-weight: 390;
  color: getTextColor('grey');
}

.c-battery-operations-header__label {
  display: inline-block;
  margin-left: var(--gap-3);
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 420;
  text-transform: uppercase;
  color: getTextColor('white');
  line-height: 1.33;
}

.c-battery-operations-header__label--blue {
  background-color: getColor('blue-lighter');
  color: getTextColor('blue-dark');
}

.c-battery-operations-header__label--red {
  background-color: getColor('red-lighter');
  color: getTextColor('red-dark');
}

.c-battery-operations-header__label--green {
  background-color: getColor('green-lighter');
  color: getTextColor('green-dark');
}

.c-battery-operations-header__label--yellow {
  background-color: getColor('yellow-lighter');
  color: getTextColor('yellow-dark');
  white-space: nowrap;
}

.c-battery-operations-header__label--gray {
  background-color: getColor('grey-lighter');
  color: getTextColor('grey-dark');
}

.c-battery-operations-header__info {
  margin-left: 2px;
  height: var(--gap-2);
  vertical-align: middle;
  @include createIcon(
    $_color: getColor('blue')
  );
}
