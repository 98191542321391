@import '~@sonnen/shared-web/abstracts';

.c-register-card {
  --card-width: 400px;
}

.c-register-card {
  position: relative;
  width: var(--card-width);
  z-index: 1;
}
