@import '~@sonnen/shared-web/abstracts';
.c-case-card {
  margin-bottom: var(--gap-3);
}
.c-case-card__header {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.c-case-card__header-icon {
  @include createIcon($_width: 18px);
  margin-right: var(--gap-1);
}

.c-case-card__header-text {
  font-weight: 420;
  margin-right: var(--gap-1);
}

.c-case-card__tooltip {
  .sw-tooltip__content {
    min-width: 150px;
  }
}
