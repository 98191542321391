@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-new__container {
  display: block;
}

.c-lead-configuration-new {
  flex-direction: column-reverse;

  @include breakpointUp(MD) {
    flex-direction: row;
  }
}

.c-lead-configuration-new__header-wrapper {
  .c-page-header {
    z-index: 1;
  }
}
