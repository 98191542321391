@import '/src/app/designSystem/styles/';

.controls-menu {
    font-size: $_14;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    &--count {
        display: flex;
        align-items: center;

        &>p {
            margin-bottom: 0;
        }

        .total {
            font-weight: $_medium;
            margin-right: 10px;
        }

        .count-highlight {
            margin: 0 10px;
            padding: 4px 10px;
            font-weight: $_medium;
            background: $green_100;
            border-radius: 12px;
        }
    }

    &--control-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // This is necessary to position the c-new-feature-tooltip__active-element-background
        width: 62px;

        &>.button-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 14px;
            cursor: pointer;

            .open.filter {
                stroke: $blue_500;
            }

            .filter {
                stroke: $grey_900;

                &:hover {
                    stroke: $blue_500;
                }
            }

            .settings {
                fill: $grey_900;

                &:hover {
                    fill: $blue_500;
                }
            }
        }
    }
}