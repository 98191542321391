@import '~@sonnen/shared-web/abstracts';

.c-help-faq {
  position: relative;
}

.c-help-faq__loader {
  padding: var(--gap-4) 0;
}

.c-help-faq__select {
  --width: 100%;
  margin-bottom: var(--gap-5);
}

.c-help-faq__bold-header {
  font-weight: 420;
  margin-top: var(--gap-4);
  margin-bottom: var(--gap-1);
}

.c-help-faq__answer {
  display: block;
  padding: var(--gap-1) 0;
  line-height: 1.4;
  font-size: 14px;
  color: getTextColor('grey');
  word-break: break-word;

  ul {
    padding: var(--gap-1) 0 var(--gap-1) var(--gap-4);
    list-style-type: circle;
  }

  li {
    padding: 0 0 var(--gap-1);
  }

  a {
    color: getTextColor('blue');
    
    &:hover {
    text-decoration: underline;
    }
  }
}

.c-help-faq__accordion-title {
  @include typographySectionTitle();
  margin-bottom: 0;
  word-break: break-word;
}
