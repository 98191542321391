@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-pv-commissioning-modal__buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--gap-3) 0;
}

.c-lead-configuration-pv-commissioning-modal__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.c-lead-configuration-pv-commissioning-modal__body-image {
  padding: var(--gap-1);
}

.c-lead-configuration-pv-commissioning-modal__body-info {
  padding: var(--gap-1);
  text-align: center;
  max-width: 500px;
  font-size: 14px;
  line-height: 1.4;
}

.c-lead-configuration-pv-commissioning-modal__body-info--bolded {
  font-weight: bold;
}
