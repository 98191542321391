@import '~@sonnen/shared-web/abstracts';

.c-lead-inbox-empty {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: calc(100vh - 490px);
  min-height: 250px;
  max-width: 330px;
  margin: 0 auto;
  text-align: center;
}

.c-lead-inbox-empty__icon {
  height: 100px;
}

.c-lead-inbox-empty__headline {
  font-size: 20px;
  margin: var(--gap-4) 0 var(--gap-2);
}

.c-lead-inbox-empty__description {
  font-size: 14px;
  color: getTextColor('grey');
}
