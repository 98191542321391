@import '~@sonnen/shared-web/abstracts';

.c-form-input-radio-group {
  margin-right: var(--gap-3);
}

.c-form-input-radio-group__text {
  font-size: var(--font-size);
  font-weight: 420;
  line-height: 1.5;
  color: getTextColor('grey');
}

.c-form-input-radio-group__container {
  display: flex;
}

.c-form-input-radio-group__container--vertical {
  flex-direction: column;

  .c-form-input-radio-group__item {
    margin-top: var(--gap-3);
    margin-right: 0;
  }
}

.c-form-input-radio-group__container--vertical__margin--bottom {
  flex-direction: column;

  .c-form-input-radio-group__item {
    margin-bottom: var(--gap-3);
    margin-right: 0;
  }
}
