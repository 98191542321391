@import '~@sonnen/shared-web/abstracts';

.c-sort {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: getTextColor('grey');
  font-size: 13px;
  font-weight: 420;
  z-index: 96;
}

.c-sort--reordered {
  display: inline-block;
  color: getTextColor('grey');
  font-size: 13px;
  font-weight: 420;
  order: 3;

  @include breakpointUp(XS) {
    margin-left: var(--gap-2);
  }
  
  @include breakpointUp(XS) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .c-sort__select {
    @include breakpointUp(XS) {
      display: inline-block;
    }
  }
}

.c-sort__label {
  display: none;

  @include breakpointUp(XS) {
    display: initial;
    margin-right: var(--gap-1);
  }
}

.c-sort__select {
  position: relative;
  width: 180px;
}
