@import '/src/app/designSystem/styles/';

.basic-table {
  width: 100%;
  border-collapse: collapse;
}

.basic-table__header-row {
  text-align: left;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 420;
  border-bottom: 2px $grey_200 solid;
  background-color: $white;
}

.basic-table__header-cell {
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}

.basic-table__data-row {
  height: 56px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 390;

  &:nth-child(even) {
    background-color: $white;
  }

  &:nth-child(odd) {
    background-color: $grey_25;
  }

  &:hover {
    cursor: pointer;
    background-color: $grey_50;
  }
  
  &--locked:hover {
    cursor: not-allowed;
  }
}

.basic-table__data-cell {
  padding-left: 16px;
  padding-right: 16px;
}
