@import './_borderRadius.scss';
@import './_boxShadow.scss';
@import './_colors.scss';
@import './_customProperties.scss';

// This file only contains reusable classes.
// ".reusable-class__" is a prefix for classes that can be reused in multiple places or
// for multiple components that have the same styling. They should not be altered in any way, 
// because they will affect all components they are applied to.

// Example: all tooltips will have the same css rule which contains box shadow, white background etc.

// For adding a particular style rule to a tooltip that also uses a reusable class, chain classes together,
// where the second class provides additional css rules.
// e.g.: .reusable-class__tooltip.some-class-with-additional-rules

.reusable-class {
  $open-close-speed: 0.3s;

  &__dropdown-content {
    @include borderRadius();
    @include boxShadow($_side: 'bottom');
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% - 1px);
    left: -1px;
    right: 0;
    margin: 0;
    border: 1px solid $grey_200;
    padding: 14px;
    background-color: $white;
    list-style: none;
    overflow-y: hidden;
    transform: scaleY(0);
    transform-origin: center top;
    z-index: var(--z-index-dropdown);
    -webkit-overflow-scrolling: touch;
    
    .is-enter > &,
    .is-enter-done > & {
      opacity: 1;
      transform: scaleY(1);
      transition:
      opacity $open-close-speed ease-in-out,
      transform $open-close-speed ease-in-out;
    }
    
    .is-exit > &,
    .is-exit-done > & {
      opacity: 0;
      transform: scaleY(0);
      transition:
      opacity $open-close-speed ease-in-out,
      transform $open-close-speed ease-in-out;
    }
  }

  &__tooltip {
    @include borderRadius();
    @include boxShadow();
    background-color: $white!important;
    opacity: 1!important;
    color: $grey_900!important;
    z-index: var(--z-index-tooltip);
    
    &--arrow {
      background-color: $white!important;
      opacity: 1!important;
    }
  }

  &__no-padding {
    padding: 0;
  }

  &__center-loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}