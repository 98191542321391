@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpointDown(MD) {
    @include breakpointUp(XS) {
      max-height: 500px;
    }
  }
}

.c-lead-configurtion-preview__box {
  @include breakpointDown(MD) {
    @include breakpointUp(XS) {
      width: calc(50% - var(--gap-3));

      &:nth-child(n+3) {
        align-self: flex-end;
      }
    }
  }
}

.c-lead-configuration-preview__button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}
