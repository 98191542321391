@import '/src/app/designSystem/styles/';

$_height: 16px;
$_inner-gap: 2px;
$_aspect-ratio: 2;
$_width: $_height * $_aspect-ratio;
$_border-width: 1px;

.switch-button {
  display: inline-block;
  width: $_width;
  height: $_height;
}

.switch-button__checkbox {
  display: none;
}

.switch-button__label {
  position: relative;
  display: block;
  height: 100%;
  border-radius: ($_height * .5);
  border: $_border-width solid $grey_500;
  background: $grey_500;
  cursor: pointer;
  transition: background-color 250ms ease;

  &--checked {
    background-color: $blue_500;
    border-color: $blue_500;
  }
}

.switch-button__label-circle {
  $_size: ($_height - ($_inner-gap * 2));

  position: absolute;
  top: ($_inner-gap - $_border-width);
  left: ($_inner-gap - $_border-width);
  width: $_size;
  height: $_size;
  border-radius: 50%;
  border: $_border-width solid $white;
  background-color: $white;
  transition: transform 250ms cubic-bezier(.5, .05, .5, 1.5);

  &--checked {
    transform: translateX(calc($_width/2));
  }
}
