@import '~@sonnen/shared-web/abstracts';

.news-inbox {
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.news-inbox__container {
  min-height: calc(100vh - (var(--header-height) + (var(--footer-height))));
  padding: 0;
}

.news-inbox__sidebar {
  padding: var(--gap-4) 0;
  max-width: 520px;

  @include breakpointUp(NAV) {
    height: calc(100vh - 62px);
    padding: 62px 0 62px 0;
    position: fixed;
    top: 62px;
    width: 27%;

    &::after,
    &::before {
      background-color: getColor('grey-lighter');
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 1px;
      z-index: -1;
    }

    &::after {
      right: 6px;
    }

    &::before {
      left: 2px;
    }

    // scrollbar
    & > div > div {
      overflow-x: hidden !important;

      &:last-child div {
        background-color: getColor('grey-darkest') !important;
        border-radius: 1.5px !important;
        width: 3px !important;
      }
    }
  }
}

.news-inbox__content {
  padding: var(--gap-8) 0;
  word-break: break-word;

  @include breakpointUp(NAV) {
    display: block;
    float: right;
    width: calc(70% - 45px);
  }

  @include breakpointUp(XLG) {
    width: calc(64% - 45px);
  }
}

.news-inbox__go-back {
  color: getTextColor('blue');
  font-size: 14px;
  font-weight: 420;
  margin-bottom: 0;

  &:hover {
    color: lighten(getColor('blue'), 15%);
  }
}

.news-inbox__go-back__icon {
  width: 16px;
}
