@import '~@sonnen/shared-web/abstracts';

.news-item__tag {
  align-items: center;
  border: 1px solid getColor('grey-light');
  border-radius: 4px;
  display: flex;
  margin-right: 10px;
  padding: 2px 10px 3px;
}

.news-tag__icon {
  border-radius: 50%;
  height: 8px;
  margin-right: 5px;
  width: 8px;

  &--info {
    background-color: getColor('blue');
  }

  &--warning {
    background-color: getColor('yellow');
  }

  &--danger {
    background-color: getColor('red');
  }
}

.news-tag__text {
  color: getTextColor('dark');
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
}
