@import '~@sonnen/shared-web/abstracts';

.c-battery-intelligent-charging-confirm-modal {
  display: flex;
}

.c-battery-intelligent-charging-confirm-modal__warning {
  padding: var(--gap-2);
  background-color: getColor('yellow');
}

.c-battery-intelligent-charging-confirm-modal__info {
  padding: var(--gap-5) 0 var(--gap-3);
  font-size: 16px;
}

.c-battery-intelligent-charging-confirm-modal__footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}
