@import '~@sonnen/shared-web/abstracts';

.tutorial-step {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1.43;
  padding: 0 var(--gap-7);

  &__content {
    &--text {
      padding-right: 98px;
      width: calc(100% - 98px);
    }
  }

  &__navigation {
    color: getColor('grey');
    font-weight: bold;
    margin-bottom: var(--gap-1);
  }

  &__title {
    color: getTextColor('dark');
    font-size: 22px;
    font-weight: 300;
    margin-bottom: var(--gap-1);
  }

  &__description {
    line-height: 19px;
    margin-bottom: 0;
    padding-right: var(--gap-4);
  }

  &__link {
    color: getColor('blue');
    display: inline-block;
    font-weight: 420;
    margin-top: var(--gap-2);

    &:hover {
      text-decoration: underline;
    }
  }

  &--text-only {
    padding: 0 188px;

    .tutorial-step__content--text {
      max-width: inherit;
      padding-right: 0;
      width: 100%;
    }
  }
}
