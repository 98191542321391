@import '~@sonnen/shared-web/abstracts';

.c-form-field-error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-field-error__message {
  color: getTextColor('red');
  font-size: 12px;
}
