@import '~@sonnen/shared-web/abstracts';

.c-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--gap-3);
  text-align: center;

  @include breakpointUp(SM) {
    padding: var(--gap-5);
  }
}

.c-not-found--full-screen {
  min-height: var(--content-height);
}

.c-not-found__header {
  margin-bottom: var(--gap-2);
  font-size: 18px;
  font-weight: 300;

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-4);
    font-size: 32px;
  }
}

.c-not-found__text {
  margin-bottom: var(--gap-2);
  max-width: 250px;
  line-height: 1.4;
  font-size: 12px;
  font-weight: 300;

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-4);
    max-width: 400px;
    font-size: 18px;
  }
}

.c-not-found__icon {
  @include createIcon($_height: 100px, $_color: getColor('blue'));
  margin-bottom: var(--gap-2);

  @include breakpointUp(SM) {
    @include createIcon($_height: 180px, $_color: getColor('blue'));
    margin-bottom: var(--gap-4);
  }
}
