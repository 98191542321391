@import '~@sonnen/shared-web/abstracts';

.c-my-account {
  padding-top: var(--gap-4);
  padding-bottom: var(--gap-4);

  @include breakpointUp(XLG) {
    padding-top: calc(var(--gutter-v) * 2);
    padding-bottom: calc(var(--gutter-v) * 2);
  }
}

.c-my-account__column {
  margin-bottom: var(--gap-5);
}