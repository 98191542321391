@import '~@sonnen/shared-web/abstracts';

.c-lead-offer-composer {
  display: block
}

.c-lead-offer-composer__sidebar {
  display: flex;
  justify-content: center;
}
