@import '~@sonnen/shared-web/abstracts';

.c-search-field {
  --button-width: 50px;
  --search-field-height-protected: var(--search-field-height, 45px);
  --search-field-width-protected: var(--search-field-width, calc(240px - var(--button-width) - (2 * var(--gutter-h))));

  @include breakpointUp(SM) {
    --search-field-width-protected: var(
      --search-field-width,
      calc(550px - var(--button-width) - (2 * var(--gutter-h)))
    );
  }
}

.c-search-field {
  @include borderRadius();
  display: flex;
  align-items: center;
  height: var(--search-field-height-protected);

  @include breakpointUp(SM) {
    display: inline-flex;
  }
}

.c-search-field--full-width {
  width: 100%;
}
.c-search-field__input {
  @include borderLeftRadius;
  @include placeholder(getTextColor('grey'));
  flex: 1 1 100%;
  min-width: var(--search-field-width-protected);
  height: 100%;
  border: 1px solid getColor('grey-light');
  border-right: 0;
  padding: 0 var(--gap-2);
  font-size: 14px;
  font-weight: 390;
  color: getTextColor('dark');
  text-overflow: ellipsis;
  background: getColor('white');
  transition: all 0.25s ease 0.1s;
  -webkit-appearance: textfield;
}

.c-search-field__button {
  @include borderRightRadius;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--button-width);
  height: 100%;
  color: getColor('white');
  background: getColor('blue');
  transition: all 0.25s ease 0.1s;

  &:hover,
  &:focus {
    background: darken(getColor('blue'), 10%);
  }
}

.c-search-field__button-icon {
  font-size: 20px;
  transform: scaleX(-1);
}
