@import "~@sonnen/shared-web/abstracts";

.sw-modal-layout--setup-precommissioning {
  .precommissioning-modal {
    width: 100%;
    height: 100%;
    cursor: default;

    @include breakpointUp(SM) {
      height: 100%;
      width: 100%;
    }
  }

  .precommissioning-modal-form {
    height: 100%;
    width: 100%;
  }

  .precommissioning-modal-form__description {
    font-size: 14px;
    line-height: 20px;
  }

  .precommissioning-modal-form__generate-button {
    height: calc(var(--gap-5) * 0.95);
  }

  .precommissioning-modal-form__widget {
    margin-top: var(--gap-3);

    .c-widget__header {
      height: 56px;
      padding: 0 var(--gap-3);
    }

    .c-widget__body {
      padding: 0 var(--widget-padding-h-protected);
    }

    .c-widget__header,
    .c-widget__body-wrapper {
      @include boxShadow();
    }

    .c-widget__icon {
      font-size: var(--gap-3);
      line-height: 1;
      margin-right: var(--gap-2);
    }

    .c-widget__heading {
      font-size: 16px;
    }

    .c-detail-list-table__item {
      padding: var(--gap-4) 0;
    }

    .c-detail-list-table__item:last-child {
      border-bottom: none;
    }

    .c-detail-list-table__item-title {
      font-weight: 400;
    }

    .c-detail-list-table__item-title,
    .c-detail-list-table__item-value {
      line-height: 20px;
    }

    .c-detail-list-table__info-icon {
      min-width: 22px;
      width: 22px;
    }

    .c-detail-list-table__bubble-wrapper {
      bottom: 34px;
      right: -13px;
      top: auto;
      width: 376px;
    }
  }

  .sw-modal__dialog {
    width: 720px;
  }

  .sw-modal__close-icon:not(:hover) {
    fill: getColor('grey');
  }

  .sw-modal-layout__header {
    background-color: getColor('grey-lightest');
    border-radius: 3px 3px 0 0;
    padding-top: calc(var(--gap-3) + var(--gap-1)/2);
    z-index: 2;
  }

  .sw-modal-layout__title {
    font-size: 26px;
  }

  .sw-modal-layout__body {
    padding: var(--gap-1) var(--modal-layout-padding-h);
  }

  .sw-modal-layout__body-inner {
    padding: var(--gap-2) 0;
  }

  .sw-modal-layout__footer {
    justify-content: flex-end;
  }
}
