@import '~@sonnen/shared-web/abstracts';

.c-icon--refresh {
  @include createIcon($_width: 22px, $_color: getColor('blue'));
  width: 22px;
  margin-left: var(--gap-1);
  transform: translateY(6px);
  cursor: pointer;
}

.c-icon--refresh:hover {
  @include createIcon($_width: 22px, $_color: darken(getColor('blue'), 10%));
}
