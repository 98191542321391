@import '~@sonnen/shared-web/abstracts';

$color-icon-blue: #4ccfef;

.c-setup-tool-step-list-table-item__precommissioning-infobox {
  @include boxShadow();
  align-items: center;
  background-color: getColor('white');
  display: flex;
  margin: var(--gap-1) 0;
  padding: 14px var(--gap-3);
}

.c-setup-tool-step-list-table-item__precommissioning-infobox__icon {
  fill: $color-icon-blue;
  height: 21px;
  margin-right: var(--gap-2);
  min-height: 21px;
  min-width: 21px;
  width: 21px;
}

.c-setup-tool-step-list-table-item__precommissioning-infobox__text {
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: normal;
}
