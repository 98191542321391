@import '~@sonnen/shared-web/abstracts';

.c-nav--overlayed {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: var(--header-height);
  background-color: getColor('white');
  overflow-y: scroll;
  transition: all 400ms ease-in-out;
  z-index: var(--z-index-mobile-nav);
  -webkit-overflow-scrolling: touch;

  &:not(.is-active) {
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    visibility: hidden;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.c-nav__navigation {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: var(--header-height);
  text-align: left;
  list-style-type: none;

  @include breakpointUp(NAV) {
    text-align: center;
  }

  & > * {
    min-width: 100%;

    @include breakpointUp(NAV) {
      min-width: auto;
    }
  }
}

.c-nav__navigation--mobile {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.c-nav__logo {
  max-width: 145px;
  max-height: var(--logo-height, 30px);
  height: auto;
  object-fit: cover;
}

.switch-to-toolbox-btn {
  padding-right: 18px;
}
