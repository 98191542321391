@import '~@sonnen/shared-web/abstracts';

.c-impostor-banner {
  position: relative;
  top: 2px;
  z-index: var(--z-index-banner);
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: var(--gap-1) 0;
  background: getColor('blue');
}

.c-impostor-banner__content {
  display: flex;
  align-items: center;
  color: getColor('white');
  font-size: 14px;
}

.c-impostor-banner__icon {
  margin-right: var(--gap-1);
  font-size: 30px;
}
