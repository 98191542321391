@import '/src/app/designSystem/styles/';

.card {
  --card-header-height: 32px;
  --card-footer-height: 80px;
}

.card {
  @include borderRadius('big');
  @include boxShadow();
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
}

.card__main {
  flex: 1 1;
  padding: var(--gutter-card);
}

.card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: var(--card-header-height);
  border-bottom: 1px solid $grey_200;
  padding: 24px 32px;
}

.card__header--no-gap {
  padding: 0;
}

.card__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--card-footer-height);
  border-top: 1px solid $grey_200;
  font-size: 18px;
  font-weight: 420;
  color: rgba($grey_900, 0.4);
}