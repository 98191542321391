@import '~@sonnen/shared-web/abstracts';

.c-doc-upload-form {
  height: 100%;
}

.c-doc-upload-form__hint {
  margin-bottom: var(--gap-3);
  color: getTextColor('grey');
  font-size: 14px;
  line-height: 20px;
}

.c-doc-upload-form__upload-input {
  margin-bottom: var(--gap-3);
  padding: 0;
}

.c-doc-upload-form__upload-input .c-form-upload-input__drag-area {
  max-height: 40px;
  margin: 0 auto;
}

.c-doc-upload-form__upload-input .c-form-upload-input__files {
  max-width: 100%;
  max-height: 100%;
}
