@import '~@sonnen/shared-web/abstracts';

.lead-current-status {
  display: flex;
  flex-direction: row;
}

.lead-current-status__tiles-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.lead-current-status__tiles-wrapper--compact {
  flex-wrap: wrap;
}

.lead-current-status__button {
  margin-left: var(--gap-2);
}
