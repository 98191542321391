@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-nav-icon {
  font-size: 24px;
  height: 24px;
  fill: currentColor;

  @include breakpointUp(SM) {
    margin-right: var(--gap-1);
  }
}

.c-lead-header__breadcrumb {
  padding: 0;
}

.c-lead-header__breadcrumb--icon {
  fill: getColor('blue');
  height: 10px;
  margin-right: 5px;
}
