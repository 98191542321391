@import '~@sonnen/shared-web/abstracts';

.c-form-rich-text-input {
  margin-bottom: var(--gap-4);
}

.c-form-rich-text-input--no-gap {
  margin-bottom: 0;
}

.c-form-rich-text-input__label {
  display: block;
  margin: 0 0 var(--gap-1);
  font-size: 13px;
  font-weight: 420;
  color: getTextColor('grey');
}

.c-form-rich-text-input__warning {
  display: flex;
  margin-bottom: var(--gap-1);
}

.c-form-rich-text-input__warning-icon {
  @include createIcon($_width: 16px, $_color: getColor('grey'));
  margin-right: var(--gap-1);
}

.c-form-rich-text-input__warning-text {
  line-height: 1.2;
  color: getTextColor('grey');
}

.c-form-rich-text-input__field {
  font-family: var(--font-family);
  color: getTextColor('dark');

  .public-DraftEditor-content {
    min-height: var(--gap-8);
    font-size: 14px;
  }
}

.c-form-rich-text-input__field--error {
  border-color: getColor('red');
}

.c-form-rich-text-input__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-rich-text-input__error-message {
  color: getTextColor('red');
  font-size: 12px;
}
