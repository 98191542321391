@import '~@sonnen/shared-web/abstracts';

.setup-tool-refresh-page-modal {
  z-index: 1501;

  .sw-modal__dialog {
    height: 576px;
    width: 624px;
  }

  .sw-modal-layout__body-inner {
    padding: var(--gap-5);
    text-align: center;
  }

  .setup-tool-refresh-page-modal__ufo-icon {
    fill: getColor('blue');
    margin: var(--gap-5) auto 0 auto;
    width: 135px;
  }

  .setup-tool-refresh-page-modal__title {
    font-size: 32px;
    font-weight: 300;
    height: 46px;
    margin: var(--gap-4) 0 30px 0;
  }

  .setup-tool-refresh-page-modal__description {
    font-size: 18px;
    line-height: 25px;
  }

  .setup-tool-refresh-page-modal__button {
    margin: 0 auto;
    width: 160px;
  }

  .sw-modal__close {
    top: var(--gap-1);
    right: var(--gap-1);
  }

  .sw-modal__close-icon {
    fill: #8b8c93;
  }
}
