@import '~@sonnen/shared-web/abstracts';

$_box-colors: (
  'blue': getColor('blue'),
  'yellow': getColor('yellow'),
  'green': getColor('green'),
);


.c-lead-configuration-action-box {
  @include boxShadow();
  width: 100%;
  margin-top: var(--gap-1);
  padding: var(--gap-3);
  border-radius: 4px;
  background-color: getColor('white');
}

.c-lead-configuration-action-box--hoverable {
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    @include boxShadow();
  }
}

@each $_name, $_color in $_box-colors {
  .c-lead-configuration-action-box--color-#{$_name} {
    &.c-lead-configuration-action-box--border-left {
      border-left: 5px solid $_color;
    }
  }
}

.c-lead-configuration-action-box--disabled {
  cursor: not-allowed;
}
