// THIS COMPONENT AND ITS STYLE SHEET WERE COPIED UNALTERED FROM SHARED-WEB
@import '~@sonnen/shared-web/abstracts';

.sw-modal-layout {
  --modal-layout-padding-h: var(--gap-4);
  --modal-layout-body-padding-v-protected: var(--modal-layout-body-padding-v, var(--gap-4));
  --modal-layout-body-padding-h-protected: var(--modal-layout-body-padding-h, var(--modal-layout-padding-h));
}

.sw-modal-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.sw-modal-layout__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--gap-8);
  border-bottom: 1px solid getColor('grey-light');
  padding: var(--gap-2) var(--modal-layout-padding-h);
  text-align: center;
}

.sw-modal-layout__body {
  @include createCustomScrollbar();
  position: relative;
  z-index: 1;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sw-modal-layout__body-inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding: var(--modal-layout-body-padding-v-protected) var(--modal-layout-body-padding-h-protected);
}

.sw-modal-layout__footer {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  min-height: var(--gap-8);
  padding: 0 var(--modal-layout-padding-h);
}

.sw-modal-layout__footer--with-border {
  @include boxShadow($_side: 'top');
}

.sw-modal-layout__title-wrapper {
  display: flex;
  align-items: center;
}

.sw-modal-layout__title {
  @include typographySubheadline($_include-box-model: false);
}

.sw-modal-layout__title--big {
  @include typographyHeadline($_include-box-model: false);
}

.sw-modal-layout__subtitle {
  max-width: 615px;
  margin-top: var(--gap-2);
}

.sw-modal-layout__status {
  margin-left: var(--gap-1);
}
