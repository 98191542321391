// PRIMARY COLORS
$white: #FFFFFF;

// grey
$grey_25: #F4F4F5; // replacing getColor('grey-lightest')
$grey_50: #EFEFF0;
$grey_100: #D3D5D8; // replacing getColor('grey-lighter')
$grey_200: #B4B8BF; // replacing getColor('grey-light')
$grey_300: #959AA5;
$grey_400: #79808E;
$grey_500: #61687A;
$grey_600: #4B5468;
$grey_700: #374158;
$grey_800: #242F49;
$grey_900: #0A1535; // replacing getColor('grey-darkest')

// blue
$blue_50: #D6ECFB;
$blue_100: #BBE2FB;
$blue_200: #7BC7F8;
$blue_300: #4EACE8;
$blue_400: #2493D9;
$blue_500: #0B7AC2; // replacing getColor('blue')
$blue_600: #0965A0;
$blue_700: #075180;
$blue_800: #053D60;
$blue_900: #03273E;

// turquoise
$turquoise_50: #D1F7FF;
$turquoise_100: #A3EEFF;
$turquoise_200: #76E6FE;
$turquoise_300: #48DDFE;
$turquoise_400: #1AD5FE;
$turquoise_500: #01BFE9;
$turquoise_600: #02A1C4;
$turquoise_700: #01738C;
$turquoise_800: #004C5D;
$turquoise_900: #00262F;

// SECONDARY COLORS
// green
$green_50: #D6F7C4;
$green_100: #BEECA6;
$green_200: #8AD364;
$green_300: #5BBB28;
$green_400: #41A10E;
$green_500: #338707;
$green_600: #296C06;
$green_700: #1F5104;
$green_800: #143603;
$green_900: #0A1B01;

// yellow
$yellow_50: #FFF2D4;
$yellow_100: #FFE5A9;
$yellow_200: #FFD87E;
$yellow_300: #FFCB52;
$yellow_400: #FFBF27;
$yellow_500: #FBB000;
$yellow_600: #C98D00;
$yellow_700: #976A00;
$yellow_800: #644600;
$yellow_900: #322300;

// red
$red_50: #FBE0E0;
$red_100: #FED1D1;
$red_200: #F7AAAA;
$red_300: #F68181;
$red_400: #EF5656;
$red_500: #E22929;
$red_600: #BC1919;
$red_700: #8D1313;
$red_800: #5E0D0D;
$red_900: #2F0606;

// SPECIAL COLORS
$special-dark: #121212;

// special turquoise
$special_turquoise_200: #B8EFFF;
$special_turquoise_300: #9CE9FE;
$special_turquoise_400: #7CE4FE;
$special_turquoise_500: #51DEFE;

// special blue 1
$special_blue_1_200: #A0CFE1;
$special_blue_1_300: #7DC0D8;
$special_blue_1_400: #55B0CE;
$special_blue_1_500: #02A1C4;

// special blue 2
$special_blue_2_400: #718FAA;
$special_blue_2_500: #547A9A;

// special blue 3
$special_blue_3_200: #C1DFE7;
$special_blue_3_300: #ACD5E0;
$special_blue_3_400: #97CAD8;
$special_blue_3_500: #80C0D0;

// special green 1
$special_green_1_200: #BECF98;
$special_green_1_300: #A8C077;
$special_green_1_400: #91B056;
$special_green_1_500: #7AA132;

// special green 2
$special_green_2_400: #508435;
$special_green_2_500: #296C06;

// special green 3
$special_green_3_400: #7DD2BB;
$special_green_3_500: #5DC9AE;

// special red
$special_red_200: #E6A9A5;
$special_red_300: #DB8D89;
$special_red_400: #CE716E;
$special_red_500: #C05454;

// special purple
$special_purple_200: #BEB1E8;
$special_purple_300: #A798E0;
$special_purple_400: #8F80D7;
$special_purple_500: #7668CF;

// special pink
$special_pink_400: #DEBAD1;
$special_pink_500: #D5A9C6;
