@import "~@sonnen/shared-web/abstracts";

.c-setup-lead-statuses-tooltip__wrapper {
  --guide-text-with-tooltip-visibility: hidden;
  --guide-text-with-tooltip-opacity: 0;
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 var(--gap-1);

  &:hover {
    --guide-text-with-tooltip-visibility: visible;
    --guide-text-with-tooltip-opacity: 1;
  }

  &:first-of-type {
    margin-left: 0;

    .c-setup-lead-statuses-tooltip__bubble {
      left: 0;
    }

    .c-setup-lead-statuses__item__icon {
      margin-left: 0;
    }
  }

  &:last-of-type {
    .c-setup-lead-statuses__item__icon {
      margin-top: 1px;
    }
  }
}

.c-setup-lead-statuses-tooltip__bubble {
  position: absolute;
  z-index: calc(var(--z-index-header) + 1);
  left: var(--gap-1);
  bottom: 30px;
  width: 224px;
  visibility: var(--guide-text-with-tooltip-visibility);
  opacity: var(--guide-text-with-tooltip-opacity);
  transition: visibility 200ms, opacity 200ms;
  background-color: getColor('grey-lightest');

  .sw-bubble {
    @include boxShadow();
    padding: calc(var(--gap-1) * 1.5);

    &:after {
      left: calc(var(--gap-3) - 2px);
      box-shadow: -4px 4px 5px -2px getColor('grey-light');
    }
  }
}

.c-setup-lead-statuses-tooltip__bubble__status-name {
  display: inline-block;
  margin: var(--gap-1) 0 0 0;
  padding: 3px var(--gap-1);
  font-size: 12px;
  font-weight: 420;
  text-transform: uppercase;
  color: getTextColor('green-dark');
  background-color: getColor('green-lighter');
  border-radius: var(--gap-2);

  &:first-of-type {
    margin-top: 0;
  }
}
