@import '~@sonnen/shared-web/abstracts';

.c-customer-analysis-statistics {
  margin: 0 calc(-1 * var(--gap-3));
  @include breakpointUp(XS) {
    margin: 0 0 var(--gap-5);
  }
}

.c-customer-analysis-statistics__content {
  margin: 0 calc(-1 * var(--gutter-card));
  position: relative;

  @include breakpointUp(MD) {
    margin: 0;
  }
}

.c-customer-analysis-statistics__bar-chart {
  position: relative;
  padding-bottom: 20px;
}

.c-customer-analysis-statistics__pie-charts-container {
  display: flex;
  flex-direction: row;

  @include breakpointUp(MD) {
    flex-direction: column;
  }
}



.c-customer-analysis-statistics__card-content {
  position: relative;
  margin: 0 calc(var(--gutter-card) * -1);

  @include breakpointUp(SM) {
    margin: 0;
  }
}

.c-customer-analysis-statistics__pie-chart {
  display: flex;
  flex: 1;
  flex-direction: column;

  @include breakpointUp(SM) {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
  }

  @include breakpointUp(MD) {
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  @include breakpointUp(LG) {
    flex-direction: row;
    align-items: center;
  }
}

.c-customer-analysis-statistics__banner {
  --icon-size: 52px;
}

.c-customer-analysis-statistics__tooltip {
  display: flex;
  justify-content: center;
  margin: 2px var(--gap-1);

  @include breakpointUp(XS) {
    margin: 0 0 var(--gap-1);
  }
}
