@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-edit-warning {
  display: flex;
  position: absolute;
  bottom: var(--gap-2);
  left: 0;
  color: getColor('yellow');
  font-weight: 420;
  font-size: 12px;
  line-height: 1.5;
}

.c-lead-overview-edit-warning__icon {
  height: 20px;
  fill: getColor('yellow');
  margin-right: var(--gap-1);
}
