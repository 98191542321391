@import '~@sonnen/shared-web/abstracts';

.dashboard-greeting {
  line-height: normal;
  margin: 12px 30px 42px 30px;
  max-width: calc((53 / 81) * 100%); // news-widget -> max-width:calc((25 / 81) * 100%)

  @include breakpointUp(NAV) {
    margin: 60px 30px 60px 30px;
  }

  &__hello {
    font-size: 30px;
    font-weight: 420;

    @include breakpointUp(NAV) {
      font-size: 42px;
    }
  }

  &__date {
    font-size: 16px;
    font-weight: 420;

    @include breakpointUp(NAV) {
      font-size: 18px;
    }
  }

  &__location {
    font-size: 16px;
    margin-left: 37px;
    position: relative;

    @include breakpointUp(NAV) {
      font-size: 18px;
    }

    &::before {
      background-color: getColor('grey-lighter');
      border-radius: 50%;
      content: '';
      height: 7px;
      left: -22px;
      position: absolute;
      top: 9px;
      width: 7px;
    }
  }
}
