@import '~@sonnen/shared-web/abstracts';
.c-lead-list__header {
  --lead-list-header-field-height: 45px;
}

.c-lead-list__search-field {
  --search-field-height: var(--lead-list-header-field-height);

  @include breakpointUp(SM) {
    --search-field-width: 250px;
  }
}

.c-lead-list__pagination {
  justify-content: center;
  flex-flow: initial;
}

.c-list-table--lead-list {
  .c-list-table__header-item.c-list-table__header-item--status {
    @include breakpointUp(SM) {
      flex-grow: 3.5;
    }
  }
}

.c-lead-list__header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-lead-list__search-field {
  flex: 1;

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-start;
    margin-right: var(--gap-2);
    margin-bottom: 0;
  }

  @include breakpointUp(MD) {
    margin-right: var(--gap-4);
  }
}

.c-lead-list__status-filter {
  flex: 3;
}

.c-lead-list__status-filter,
.c-lead-list__search-field {
  margin-bottom: var(--gap-2);
}
