@import '~@sonnen/shared-web/abstracts';

.c-add-video__modal-form {
  height: 100%;
}

.c-add-video__footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.c-add-video__cancel-button {
  margin: 0 var(--gap-1);
}

.c-add-video__open-modal {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.c-add-video__open-modal-button {
  margin: var(--gap-2);
}
