@import '~@sonnen/shared-web/abstracts';

.lead-name {
  @include breakpointDown(LG) {
    margin-bottom: 12px;
  }
  
  & > .company {
    margin-bottom: 6px;
    
    @include breakpointDown(LG) {
      margin: 0px 6px 12px 0px;
    }
  }
}

