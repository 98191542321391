@import '~@sonnen/shared-web/abstracts';

.c-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1500;

  .sw-card {
    box-shadow: none;
  }
}

.c-overlay__dialog {
  position: relative;
  width: auto;
  margin: auto;
  pointer-events: none;
  max-width: #{mapDeepGet($config, BREAKPOINTS, MD)}; //1024
}

.c-overlay__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  border-radius: 3px;
  // max-height: 85vh;
  // overflow: hidden;
}

.c-overlay__close-container {
  display: flex;
  justify-content: flex-end;
  padding: 25px 25px 0;
}

.c-overlay__close-icon {
  width: 17px;
  height: 17px;
}

.c-overlay__title {
  @include typographyHeadline();
  @include boxShadow('bottom');
  border-bottom: none;
  padding-bottom: var(--gap-4);
  padding-left: var(--gap-10);
  padding-right: var(--gap-10);
  margin-bottom: 0;
  z-index: 1;

  @include breakpointDown(LG) {
    font-size: 28px;
  }
}

.c-overlay__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: getColor('modal-overlay');
  opacity: 0.5;
}

.c-overlay__body {
  padding: 0 var(--gap-10);
}

.c-overlay__inner-content {
  padding: var(--gap-5) var(--gap-10);

  @include breakpointDown(MD) {
    padding: var(--gap-5);
  }

  &--without-top-padding {
    padding-top: 0;
  }

  &:empty {
    @include breakpointDown(LG) {
      display: none;
    }
  }
}

.c-overlay__footer {
  padding-bottom: var(--gap-4);
  display: flex;
}
