@import '~@sonnen/shared-web/abstracts';

.c-video-list-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: var(--gap-1) 0;

  @include breakpointUp(XS) {
    flex-direction: row;
  }
}

.c-video-list-item__cell {
  flex: 1 1 50%;
  margin-bottom: var(--gap-2);
  align-items: flex-start;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-bottom: 0;
  }
}

.c-video-list-item__cell--title {
  @include breakpointUp(SM) {
    flex-grow: 2;
    margin-right: var(--gap-1);
  }
}

.c-video-list-item__cell--youtube-id {
  @include breakpointUp(SM) {
    flex-grow: 1;
  }
}

.c-video-list-item__cell--actions {
  @include breakpointUp(SM) {
    flex-grow: 0.5;
  }
}
