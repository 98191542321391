@import '~@sonnen/shared-web/abstracts';

.c-nav-user {
  --icon-padding: 10px;
  --icon-size: 48px;
  --transition-enter-timeout: 300;
  --transition-leave-timeout: 300;
  --transition-enter-ms: calc(var(--transition-enter-timeout) * 1ms);
  --transition-leave-ms: calc(var(--transition-leave-timeout) * 1ms);
}

.c-nav-user {
  display: flex;
  align-items: center;
}

.c-nav-user__dropdown-content {
  display: flex;
  flex-flow: column;
  user-select: none;
}

.c-nav-user__dropdown-content--desktop {
  @include dropdown(
    $_top: calc(100% - 10px),
    $_right: -25px,
    $_padding: 0,
  );
  @include createTriangleArrow(
    $_side: 'top',
    $_offset: '100% - 47px',
    $_color: $grey_900,
  );
  opacity: 0;
  transform: translateY(20px);
  min-width: 340px;
  box-shadow: none;
  
  &::after {
    box-shadow: none;
  }

  .is-enter > &,
  .is-enter-done > & {
    opacity: 1;
    transform: translateY(0);
    transition: 
      opacity var(--transition-enter-ms) ease-in-out,
      transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    opacity: 0;
    transform: translateY(20px);
    transition: 
      opacity var(--transition-leave-ms) ease-in-out,
      transform var(--transition-leave-ms) ease-in-out;
  }

  @include breakpointUp(MD) {
    max-width: 500px;
  }
}

.c-nav-user__dropdown-content--mobile {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;

  .is-enter > &,
  .is-enter-done > & {
    opacity: 1;
    transform: scaleY(1);
    transition: 
      opacity var(--transition-enter-ms) ease-in-out,
      transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    opacity: 0;
    transform: scaleY(0);
    transition: 
      opacity var(--transition-leave-ms) ease-in-out,
      transform var(--transition-leave-ms) ease-in-out;
  }
}

.c-nav-user__user {
  display: flex;
  flex-flow: row;
  padding: 25px var(--gap-3);
  border-bottom: 1px solid getColor('white');
  background-color: getColor('grey-darkest');
}

.c-nav-user__user-image {
  flex: 0 1 auto;
  width: 60px;
  height: 60px;
  margin-right: 14px;
  border-radius: 50%;
  line-height: 1;
  font-size: var(--icon-size);
  color: getColor('white');
}

.user-icon {
  padding-right: 16px;
}

.c-nav-user__user-details {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: calc(100% - var(--icon-size) - (var(--icon-padding) * 2));
  margin-right: var(--gap-1);
  text-align: left;
}

.c-nav-user__user-details-name {
  margin: 0;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 420;
  color: getColor('white');
  text-overflow: ellipsis;
}

.c-nav-user__user-details-info {
  display: inline-block;
  margin: 0;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 400;
  color: getColor('white');
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-nav-user__navigation {
  margin-top: 20px;
  border-left: 1px solid getColor('grey-light');
  padding-left: 15px;
  list-style: none;

  @include breakpointUp(NAV) {
    margin: 0;
    border-left: 0;
    padding: 0;
  }
}
