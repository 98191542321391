@import 'src/app/designSystem/styles/';

.lead-new {
  @include borderRadius('big');
  @include boxShadow();
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 30px 18px 30px 18px;

  @include breakpoint('tablet') {
    padding: 50px 40px;
  }
  @include breakpoint('desktop') {
    padding: 60px 116px;
  }

  &__main {
    margin-bottom: 24px;

    @include breakpoint('tablet') {
      margin-bottom: 28px;
    }
    @include breakpoint('desktop') {
      margin-bottom: 32px;
    }
  }

  &__checkboxes {
    margin-bottom: 20px;

    @include breakpoint('tablet') {
      margin-bottom: 28px;
    }
    @include breakpoint('desktop') {
      margin-bottom: 32px;
    }

    & > .c-form-checkbox {
      margin-bottom: 26px;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.c-lead-new-delivery-address__label-spacing {
  margin-top: 24px;
  margin-bottom: 14px;
}

.lead-new__invoice-address-checkbox-wrapper {
  margin-top: 24px;
  margin-bottom: 28px;

  &--title {
    margin-bottom: 14px;
  }
}
