@import '~@sonnen/shared-web/abstracts';

.c-round-button {
  --size: 22;
  --direction: 180;
}

.c-round-button {
  $parent: &;
  position: relative;
  z-index: 1;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px);
  border-radius: 50%;

  &--info {
    background: getColor('blue');
    color: getTextColor('white');

    &#{$parent}--outline {
      background: transparent;
      border: 1px solid getColor('blue');
      color: getColor('grey-darkest')

      #{$parent}__inner {
        font-weight: 400;
      }
    }
  }

  &--success {
    background: getColor('green');
    color: getTextColor('white');
  }

  &--warning {
    background: getColor('yellow');
    color: getTextColor('white');
  }

  &--danger {
    background: getColor('red');
    color: getTextColor('white');
  }

  &--inactive {
    background: getColor('grey-lighter');
    color: getTextColor('white');
    cursor: not-allowed;
  }

  &--inactive-dark {
    background: getColor('grey');
    color: getTextColor('dark');
    cursor: not-allowed;
  }

}

.c-round-button__inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: calc(var(--size) / 2 * 1px);
  line-height: calc(var(--size) / 2 * 1px);
  font-weight: 900;
  transform: rotate(calc(var(--direction) * 1deg));
  transition: background-color .2s ease-in-out;

  .sw-round-button:hover & {
    background: rgba(getColor('grey-darkest'), .1);
  }
}
