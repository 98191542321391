// TODO: this component was brought unchanged from shared-web
// only the import in line 4 was updated

@import '~@sonnen/shared-web/abstracts';
// @TODO: Refactor, so that stylelint disabling is not needed

.select {
  --width: 100%;
  --height-protected: var(--select-height, 350px);
}

.select {
  position: relative;
  display: flex;
  width: 100%;
}

.select__trigger {
  @include borderRadius();
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  width: 100%;
  height: var(--gap-5);
  border: 1px solid getColor('grey-light');
  padding: 5px 0 5px 15px;
  background-color: getColor('white');
  color: getTextColor('dark');
}

.select__trigger--error {
  border-radius: var(--border-radius);
  border: 1px solid getColor('red');
}

.select__trigger-content {
  display: inline-flex;
  align-content: center;
  align-items: center;
  width: calc(100% - 32px);
}

.select__trigger-items {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select__trigger-items--multiple-values {
  direction: rtl;
}

.select__trigger-placeholder {
  color: getTextColor('grey');
  font-weight: 300;
}

.select__trigger-icon {
  position: relative;
  flex: 0;
  align-self: center;
  min-width: 32px;
  height: inherit;

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-icons);
    font-size: 12px;
    color: getColor('blue');
    text-align: center;
  }

  &-arrow::after {
    content: config(ICONS, ANGLE); // stylelint-disable-line value-keyword-case
    transition: transform 400ms ease-in-out;
    transform: rotate(-90deg);

    .select.is-active & {
      // stylelint-disable-line selector-max-class
      transform: rotate(90deg);
    }
  }

  &-clear::after {
    content: config(ICONS, X); // stylelint-disable-line value-keyword-case
  }
}

.select__input {
  border: none;
  font-family: var(--font-family);

  &::placeholder {
    color: getTextColor('grey');
    font-weight: 300;
  }
}

.select__list {
  @include borderRadius();
  @include boxShadow($_side: 'bottom');
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  max-height: var(--height-protected);
  margin: 0;
  border: 1px solid getColor('grey-light');
  padding: 15px;
  background-color: getColor('white');
  opacity: 0;
  list-style: none;
  overflow-y: auto;
  transform: scaleY(0);
  transform-origin: center top;
  z-index: var(--z-index-dropdown);
  -webkit-overflow-scrolling: touch;

  .is-enter > &,
  .is-enter-done > & {
    opacity: 1;
    transform: scaleY(1);
    transition: opacity var(--transition-enter-ms) ease-in-out,
      transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    opacity: 0;
    transform: scaleY(0);
    transition: opacity var(--transition-leave-ms) ease-in-out,
      transform var(--transition-leave-ms) ease-in-out;
  }
}

.select__list--bottom-gap {
  margin-bottom: var(--gap-2);
}

// stylelint-disable scss/selector-no-redundant-nesting-selector, selector-max-universal, no-descending-specificity,
// stylelint-disable value-keyword-case, selector-max-class, selector-max-compound-selectors, selector-max-specificity

.select__list-item {
  position: relative;
  padding: 0 var(--gap-2) 0 0;
  color: getTextColor('dark');

  & > * {
    display: flex;
    flex: 1 0 auto;
    min-height: 14px;
    padding: 0 0 25px;
    transition: color 400ms ease-in-out;

    @include breakpointUp(SM) {
      padding: 0 0 15px;
    }
  }

  &:last-of-type > * {
    padding: 0;
  }

  &:hover > * {
    color: getTextColor('blue');
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.select__list-item--selected {
  position: relative;

  & > *,
  &::before {
    color: getColor('blue');
  }
}

.select.is-active .select__list-item--selected::after {
  content: config(ICONS, TICK);
  position: absolute;
  top: 1px;
  right: 0;
  font-family: var(--font-family-icons);
  font-size: 12px;
  color: getColor('blue');
}

// stylelint-disable selector-no-qualifying-type, selector-max-type

.select__list-item--info {
  display: flex;
  padding-top: var(--gap-2);

  .select__info-icon {
    fill: getColor('blue');
    flex-grow: 0;
    height: 22px;
    margin-right: 5px;
    width: 22px;
  }

  .select__info-text {
    color: getTextColor('grey');
    flex-basis: 0;
    font-size: 14px;
    line-height: 1.43;
  }

  a {
    font-weight: 420;

    &[href^='http'] {
      color: getTextColor('blue');
    }
  }
}

.select__info {
  font-size: 14px;
  color: getTextColor('grey');
  padding-bottom: 25px;

  @include breakpointUp(SM) {
    padding-bottom: 15px;
  }
}

.select__icon-search {
  @include createIcon($_width: var(--gap-2), $_height: var(--gap-2), $_color: getColor('blue'));
}

.select__icon-search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
