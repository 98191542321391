@import '~@sonnen/shared-web/abstracts';

.tutorial {
  @include boxShadow();
  background-color: getColor('white');
  border-radius: 3px;
  flex: 0 0 100%;
  margin-bottom: var(--gap-5);
  position: relative;
  width: 100%;

  & + .c-page-block {
    padding: 0;
  }

  &::before {
    background-color: getColor('grey-darkest');
    border-radius: 3px 0 0 3px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &__header {
    @include boxShadow();
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: var(--gap-3) var(--gap-8);

    .tutorial__title {
      font-size: 18px;
      font-weight: 300;
      margin: 0;
    }

    .tutorial__icon {
      height: 19px;
      margin-right: var(--gap-2);
      transform: translateY(3px);
    }
  }

  &__content {
    color: getTextColor('grey');
    font-size: 14px;
    padding: var(--gap-4) var(--gap-8);

    .sw-carousel-arrow__button {
      fill: getColor('grey-darkest');
      height: 28px;
      opacity: 0.3;
    }

    .slick-list {
      max-width: 1280px; // fix for FF 83.0
    }
  }
}
