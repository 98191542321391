.truncable-text {
  position: relative;

  .truncable-text__content {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

.truncable-text-tooltip-content {
  padding: 8px 10px;
}