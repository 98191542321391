// TODO: clean-up
// this was brought raw from shared-web

// stylelint-disable indentation
@import '~@sonnen/shared-web/abstracts';

// Button custom colors
$_button-colors: (
  'blue-darker': $blue_700,
  'turquoise-darker': $turquoise_700,
  'grey-darker': $grey_700,
  'grey-dark': $grey_800,
  'text-disabled': $grey_200,
);

@function getButtonColor($_color-name) {
  @return map-get($_button-colors, $_color-name);
}

// Button properties
$_button-type: [ primary, secondary, tertiary, quaternary ];
$_button-status: [ enabled, disabled ]; // Remaining statuses: focused, loading, success, failure;
$_button-style: [ default, 'left_icon', 'right_icon' ];
$_button-size: [ small, medium, large ];

// Button sizes
$_button-sizes: (
  'small': (
    'padding': 8px 16px,
    'font-size': 12px,
    'line-height': 16px,
    'arrow-height': 16px,
    // NOTE: mind the arrow SVG has padding, therefor to reflect design, we need a bigger height.
  ),
  'medium': (
    'padding': 10px 24px,
    'font-size': 14px,
    'line-height': 20px,
    'arrow-height': 16px,
    // NOTE: mind the arrow SVG has padding, therefor to reflect design, we need a bigger height.
  ),
  'large': (
    'padding': 12px 32px,
    'font-size': 16px,
    'line-height': 24px,
    'arrow-height': 24px,
    // NOTE: mind the arrow SVG has padding, therefor to reflect design, we need a bigger height.
  ),
);

// Button styles
$_button-styles: (
  'primary': (
    'enabled': (
      'background-color': getColor('grey-darkest'),
      'color': getTextColor('white'),
      'arrow-color': getColor('turquoise'),
      'hovered': (
        'color': getButtonColor('text-disabled'),
        'arrow-color': getButtonColor('turquoise-darker'),
      ),
      'pressed': (
        'background-color': getButtonColor('grey-dark'),
        'color': getTextColor('white'),
        'arrow-color': getColor('turquoise'),
      ),
      'focused': (
        'box-shadow': 0 0 4px 1px getColor('blue'),
      ),
    ),
    'disabled': (
      'background-color': getColor('grey-lighter'),
      'color': getButtonColor('text-disabled'),
      'outline': 1px getColor('grey-light') solid,
      'arrow-color': currentColor,
    ),
  ),
  'secondary': (
    'enabled': (
      'background-color': getColor('white'),
      'color': getTextColor(),
      'outline': 1px getColor('grey-darkest') solid,
      'arrow-color': currentColor,
      'fill': getColor('grey-darkest'),
      'hovered': (
        'background-color': getColor('blue-lightest'),
        'outline': 1px getColor('grey-darkest') solid,
        'arrow-color': currentColor,
      ),
      'pressed': (
        'background-color': getColor('blue-lighter'),
        'outline': 1px getColor('grey-darkest') solid,
        'arrow-color': currentColor,
      ),
      'focused': (
        'box-shadow': 0 0 4px 1px getColor('blue'),
      ),
    ),
    'disabled': (
      'background-color': getColor('white'),
      'color': getButtonColor('text-disabled'),
      'outline': 1px getColor('grey-light') solid,
      'outline-offset': -2px,
      'arrow-color': currentColor,
    ),
  ),
  'tertiary': (
    'enabled': (
      'color': getTextColor('blue'),
      'arrow-color': currentColor,
      'hovered': (
        'text-decoration': underline,
        'arrow-color': currentColor,
      ),
      'pressed': (
        'color': getButtonColor('blue-darker'),
        'arrow-color': currentColor,
        'text-decoration': underline,
      ),
    ),
    'disabled': (
      'color': getTextColor('grey'),
      'arrow-color': currentColor,
    ),
  ),
  'quaternary': (
    'enabled': (
      'color': getTextColor('dark'),
      'arrow-color': currentColor,
      'hovered': (
        'text-decoration': underline,
      ),
      'pressed': (
        'color': getButtonColor('grey-darker'),
        'arrow-color': currentColor,
        'text-decoration': underline,
      ),
    ),
    'disabled': (
      'color': getTextColor('grey'),
      'arrow-color': currentColor,
    ),
  ),
);

.ds-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  font-weight: 420;
}

.ds-button__arrow {
  display: inline-block;
}

.ds-button__arrow--left {
  transform: rotate(90deg);
}

.ds-button__arrow--right {
  transform: rotate(270deg);
}

.ds-button__loader {
  display: inline-block;
  padding-right: var(--gap-1);
}

.ds-button__success {
  display: inline-block;
  margin: 0;
  padding-right: var(--gap-1);
}

// Loader sizes
$_loader-sizes: (
  small: 3px,
  medium: 4px,
  large: 5px,
);

@each $_size in $_button-size {
  .ds-button__loader--#{$_size} {
    --loader-size: #{map-get($_loader-sizes, $_size)};
  }
}

// Success mark sizes
$_success-sizes: (
  small: 12px,
  medium: 14px,
  large: 16px,
);

@each $_size in $_button-size {
  .ds-button__success--#{$_size} {
    top: calc(#{map-get($_success-sizes, $_size)} / 4);
    position: relative;

    svg {
      height: #{map-get($_success-sizes, $_size)};
      width: #{map-get($_success-sizes, $_size)};
    }
  }
}

$_success-fill: (
  'primary': getColor('white'),
  'secondary': getColor('grey-darkest'),
  'tertiary': getTextColor('blue'),
  'quaternary': getTextColor('dark'),
);

@each $_type in $_button-type {
  .ds-button__success--#{$_type} {
    path {
      stroke: #{map-get($_success-fill, $_type)};
    }
  }
}

// Button sizes
@each $_size in $_button-size {
  .ds-button--#{$_size} {
    --ds-icon-height: #{mapDeepGet($_button-sizes, $_size, 'arrow-height')};

    padding: mapDeepGet($_button-sizes, $_size, 'padding');
    font-size: mapDeepGet($_button-sizes, $_size, 'font-size');
    line-height: mapDeepGet($_button-sizes, $_size, 'line-height');
  }
}

// Button styles
@each $_type in $_button-type {
  @each $_status in $_button-status {
    .ds-button--#{$_type}.ds-button--#{$_status} {
      --ds-icon-color: #{mapDeepGet($_button-styles, $_type, $_status, 'arrow-color')};

      background-color: mapDeepGet($_button-styles, $_type, $_status, 'background-color');
      color: mapDeepGet($_button-styles, $_type, $_status, 'color');
      outline: mapDeepGet($_button-styles, $_type, $_status, 'outline');
      outline-offset: mapDeepGet($_button-styles, $_type, $_status, 'outline-offset');
      text-decoration: mapDeepGet($_button-styles, $_type, $_status, 'text-decoration');

      @if mapDeepGet($_button-styles, $_type, $_status, 'hovered') {
        &:hover {
          --ds-icon-color: #{mapDeepGet(
              $_button-styles,
              $_type,
              $_status,
              'hovered',
              'arrow-color'
            )};

          background-color: mapDeepGet(
            $_button-styles,
            $_type,
            $_status,
            'hovered',
            'background-color'
          );
          color: mapDeepGet($_button-styles, $_type, $_status, 'hovered', 'color');
          text-decoration: mapDeepGet(
            $_button-styles,
            $_type,
            $_status,
            'hovered',
            'text-decoration'
          );
        }
      }

      @if mapDeepGet($_button-styles, $_type, $_status, 'pressed') {
        &:active:not(:disabled) {
          --ds-icon-color: #{mapDeepGet(
              $_button-styles,
              $_type,
              $_status,
              'pressed',
              'arrow-color'
            )};

          background-color: mapDeepGet(
            $_button-styles,
            $_type,
            $_status,
            'pressed',
            'background-color'
          );
          color: mapDeepGet($_button-styles, $_type, $_status, 'pressed', 'color');
          text-decoration: mapDeepGet(
            $_button-styles,
            $_type,
            $_status,
            'pressed',
            'text-decoration'
          );
        }
      }

      @if mapDeepGet($_button-styles, $_type, $_status, 'focused') {
        &:focus {
          box-shadow: mapDeepGet($_button-styles, $_type, $_status, 'focused', 'box-shadow');
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.ds-button--cursor-disabled {
  cursor: not-allowed;
}

.ds-button--block {
  min-width: 100%;
}
