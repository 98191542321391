@import '~@sonnen/shared-web/abstracts';
@import '../../../../designSystem/styles/index.scss';

.c-impact-analysis-consumption-headline-large {
  position: relative;
  margin-bottom: 46px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 45px; // line-heigt plus 5px
    width: 100%;
    border-bottom: 1px solid $grey_100;

    @include breakpoint("tablet") {
      width: 50%;
    }
  }
}

.c-impact-analysis-consumption-label-large {
  margin-bottom: 25px;
}

.c-impact-analysis-consumption-input-group {
  margin-bottom: 40px;
  
  @include breakpoint("tablet") {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.c-impact-analysis-consumption__column {
  @include breakpoint("tablet") {
    width: var(--ia-column-width);

    &:first-child {
      margin-right: var(--ia-column-space);
    }
  }
}

.c-impact-analysis-consumption__alert {
  margin-bottom: 25px;
}

.c-impact-analysis-consumption-input {
  max-width: var(--ia-input-width);
  flex: 1 1;
}
