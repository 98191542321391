@import '~@sonnen/shared-web/abstracts';

.c-contact-list-table-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--gap-1) 0;
}

.c-contact-list-table-item__cell {
  flex: 1 1 50%;
  margin: 0 var(--gap-2) var(--gap-1) 0;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-bottom: 0;
  }
}

.c-contact-list-table-item__cell:nth-child(2),
.c-contact-list-table-item__cell:nth-child(4) {
  @include breakpointUp(SM) {
    flex-grow: 1.5;
  }
}

.c-contact-list-table-item__cell--configuration {
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.c-contact-list-table-item__cell--status {
  align-items: center;
  flex-wrap: wrap;

  @include breakpointUp(SM) {
    flex-grow: 1.5;
  }
}

.c-contact-list-table-item__cell--status .c-select {
  @include breakpointUp(SM) {
    max-width: 152px;
  }
}

.c-contact-list-table-item__cta {
  @include breakpointUp(SM) {
    height: var(--gap-4);
  }
}

.c-contact-list-table-item__error {
  color: getColor('red');
}
