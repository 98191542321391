@import '~@sonnen/shared-web/abstracts';


.c-lead-configuration-flat-direct-illustration {
  @include breakpointDown(MD) {
    margin-left: auto;
  }

  @include breakpointDown(XS) {
    margin: 0;
  }
}

.c-lead-configuration-flat-direct-illustration__image-wrapper {
  display: flex;
  justify-content: center;
}

.c-lead-configuration-flat-direct-illustration__image {
  height: 220px;

  @include breakpointDown(MD) {
    height: 125px;
  }
}
