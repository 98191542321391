@import "~@sonnen/shared-web/abstracts";

.c-battery-operations-card {
  --widget-padding-v: 0;
  --widget-padding-h: 0;
}

.c-battery-operations-card {
  .c-widget__body-wrapper {
    box-shadow: none;
  }
}

.c-battery-operations-card__operations {
  padding-top: var(--gap-1);
}
