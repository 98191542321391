@import '/src/app/designSystem/styles/';

.certificate-empty-message {
  &__link {
    color: $blue_500;
    font-size: $_14;
    font-weight: $_medium;
    display: inline;
  
    &:hover {
      text-decoration: underline;
    }
  }

  &__display-inline {
    display: inline;
  }
}



