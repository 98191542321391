@import '~@sonnen/shared-web/abstracts';

.c-contract-energy-meter {
  max-width: 800px;
  margin: 0 auto;

  @include breakpointUp(MD) {
    padding: 0 var(--gap-6);
  }
}

.c-contract-energy-meter__footer {
  margin-top: var(--gap-1);
}
