@import '~@sonnen/shared-web/abstracts';

.c-customer-warnings {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 var(--gap-2);
  list-style: none;
}

.c-customer-warnings__item {
  @include boxShadow();
  @include borderRadius();
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 var(--gap-2);
  overflow: hidden;
}

.c-customer-warnings__inner {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: var(--gap-1) var(--gap-2);
  font-size: 14px;

  @include breakpointUp(XS) {
    flex-direction: row;
    align-items: center;
  }
}

.c-customer-warnings__hint-wrapper {
  padding-right: var(--gap-2);
}

.c-customer-warnings__message-extended {
  margin-top: 4px;

  @include breakpointUp(XS) {
    margin-top: 0;
  }
}
