@import "~@sonnen/shared-web/abstracts";

.c-form-checkbox {
  display: flex;
  position: relative;
}

.c-form-checkbox__star {
  position: absolute;
  top: calc(var(--gap-1)*(-1));
  right: -8px;
  font-size: 18px;
  color: getColor('blue');
}
