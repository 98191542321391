@import "~@sonnen/shared-web/abstracts";

.c-videos {
  padding-top: var(--gap-4);
  padding-bottom: var(--gap-4);

  @include breakpointUp(XLG) {
    padding-top: calc(var(--gutter-v) * 2);
    padding-bottom: calc(var(--gutter-v) * 2);
  }
}

.c-videos__select {
  @include breakpointUp(SM) {
    max-width: calc(100% / 2 - 15px);
  }

  @include breakpointUp(LG) {
    max-width: calc(100% / 3 - 15px);
  }
  
  margin-bottom: var(--gap-4);
}

.c-videos__bold-header {
  margin-bottom: var(--gap-1);
  font-weight: 420;
}

.c-videos__grid {
  display: flex;
  flex-wrap: wrap;
  margin: var(--gap-2) calc(var(--gap-2)*(-1)) var(--gap-8) calc(var(--gap-2)*(-1));
}
