@import '/src/app/designSystem/styles/';

.filters-area__applied-filters-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 18px;
  row-gap: 6px;

  & > .line-divider {
    margin-left: 5px;
    margin-right: 8px;
  }

  & > .button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    cursor: pointer;

    &:hover {
      .reset-filters {
        fill: $blue_500;
      }
    }

    .reset-filters {
      fill: $grey_900;
    }
  }

  & > ul {
    display: flex;
    align-items: center;
  }

  &--filter {
    margin-right: 6px;
  }
}
