@import '~@sonnen/shared-web/abstracts';

.pv-specs-card {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  color: rgba($color-dark, 0.7);
  padding: 30px 0;

  &__list {
    margin-top: 14px;

    .value {
      font-size: 24px;
      font-weight: 420;
    }

    p {
      margin-bottom: 5px;
    }

    &--align-right {
      align-items: flex-end;
      text-align: right;
    }
  }

  .pv-specs__svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 166px;

    & > svg {
      fill: getColor('grey-darkest');
    }
  }
}
