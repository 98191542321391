@import "~@sonnen/shared-web/abstracts";

.vpp-completion-notification {
  border-bottom: 1px solid getColor('grey-light');
  border-top: 1px solid getColor('grey-light');
  padding-top: var(--gap-7);
}

.vpp-completion-notification__title {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: var(--gap-2);
}

.vpp-completion-notification__description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
}

.vpp-completion-notification__download-button {
  margin-bottom: var(--gap-4);
}

.vpp-completion-notification__download-link {
  display: none;
}
