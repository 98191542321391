@import '~@sonnen/shared-web/abstracts';

.c-list-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-list-table-header__icon {
  font-size: 18px;
  margin-right: 10px;
}

.c-list-table-header__label {
  font-size: 16px;
  font-weight: 420;
  margin-right: 5px;
}
