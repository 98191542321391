@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-email-changed-modal {
  @include breakpointUp(SM) {
    > .c-modal__dialog {
      height: 200px;
    }
  }
}

.c-lead-overview-email-changed-modal__buttons {
  width: 100%;
  margin-bottom: var(--gap-3);
  padding: var(--gap-2) 0;

  @include breakpointUp(XS) {
    margin-bottom: 0;
  }

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-3) 0;
  }
}

.c-lead-overview-email-changed-modal__buttons-wrapper {
  @include breakpointDown(SM) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}

.c-lead-overview-email-changed-modal__link-button {
  margin-bottom: var(--gap-3);
  font-size: 16px;
  padding: 0;

  @include breakpointUp(SM) {
    margin-right: var(--gap-7);
    margin-bottom: unset;
  }
}

.c-lead-overview-email-changed-modal__inner {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: var(--gap-4);
  padding-bottom: var(--gap-2);
}

.c-lead-overview-email-changed-modal__icon-plane {
  @include createIcon($_width: var(--gap-6), $_color: getColor('blue'));
}

.c-lead-overview-email-changed-modal__heading {
  margin-top: var(--gap-1);
  font-size: 18px;
}

.c-lead-overview-email-changed-modal__content {
  max-width: 400px;
  line-height: 1.4;
  text-align: center;
  padding-top: var(--gap-1);
  padding-bottom: var(--gap-5);
}

.c-lead-overview-email-changed-modal__paragraph {
  color: getTextColor('grey');
  font-size: 14px;
}

.c-lead-overview-email-changed-modal__email {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 420;
  line-height: 1.4;
}

.c-lead-overview-email-changed-modal__icon-envelope {
  @include createIcon($_width: var(--gap-3), $_color: getColor('blue'));
  margin-right: var(--gap-1);
}
