@import '~@sonnen/shared-web/abstracts';

$tableHeaderColor: #8b8c93;

.list-installers {
  display: table;
  font-size: 14px;
  font-weight: 420;
  margin-top: 36px;
  width: 100%;
}

.list-installers__row {
  align-items: center;
  display: table-row;
  padding: 0 var(--gap-3);
  cursor: pointer;

  &:nth-child(even) {
    background-color: getColor('grey-lightest');
  }

  &--titles {
    color: $tableHeaderColor;
    cursor: initial;
  }

  .c-form-controlled-checkbox {
    display: table-cell;
    padding: 20px var(--gap-1) 20px var(--gap-3);
  }
}

.list-installers__value,
.list-installers__title {
  display: table-cell;
  padding: 20px;
  word-break: break-word;

  &:nth-child(3) {
    min-width: 15vw;
  }
}

.list-installers__title {
  padding: 4px 20px;

  p {
    margin-bottom: 0;
  }
}

.list-installers__value:nth-child(3) {
  font-weight: 400;
}
