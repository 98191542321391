@import '~@sonnen/shared-web/abstracts';

.c-container--with-h-padding {
  @include breakpointUp(XLG) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.c-container--with-v-padding {
  padding-top: calc(var(--gutter-v) / 2);
  padding-bottom: calc(var(--gutter-v) / 2);

  @include breakpointUp(NAV) {
    padding-top: calc(var(--gutter-v) * 2);
    padding-bottom: calc(var(--gutter-v) * 2);
  }
}
