@import '~@sonnen/shared-web/abstracts';

.c-contract-energy-meter-error__wrapper {
  max-width: 800px;
  margin: 0 auto;

  @include breakpointUp(MD) {
    padding: 0 var(--gap-6);
  }
}
