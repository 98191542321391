@import '/src/app/designSystem/styles/';

.label-large {
  font-weight: $_medium;
  font-size: $_16;
  line-height: 24px;
  display: block;
}

.label-medium {
  font-weight: $_medium;
  font-size: $_14;
  line-height: 20px;
  display: block;
}

.label-small {
  font-weight: $_medium;
  font-size: $_12;
  line-height: 16px;
  display: block;
}

.label-small-caps {
  font-weight: $_medium;
  font-size: $_12;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
}