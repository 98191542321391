@import 'src/app/designSystem/styles/';

.lead-new-address {
  display: flex;
  flex-direction: column;
  gap: 0;

  @include breakpoint('tablet') {
    align-content: stretch;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    gap: 62px;
  }

  &__input-field {
    flex-basis: 70%;

    &--short {
      flex-basis: 30%;
    }
  }

  &--single {
    width: 100%;
    padding: 0px;

    @include breakpoint('tablet') {
      width: 50%;
      padding-right: 28px;
    }
  }

  &--pair {
    display: flex;
    flex-direction: column;
    gap: 0px;

    @include breakpoint('tablet') {
      flex-direction: row;
      width: 100%;
      gap: 8px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__loading-overlay-wrapper {
    position: relative;
    width: 70%;

    &--short {
      position: relative;
      width: 30%;
    }
  }

  &__loading-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.lead-new__warning-wrapper {
  margin-bottom: 14px;
}
