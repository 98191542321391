@import '~@sonnen/shared-web/abstracts';

.c-customer-overview__tile {
  margin-bottom: var(--gutter-h);
}

.c-customer-overview__grid {
  margin: 0 calc(var(--gutter-h) / -2);
}

.c-customer-overview__grid-sizer,
.c-customer-overview__grid-item {
  padding: 0 calc(var(--gutter-h) / 2);
  width: 100%;

  @include breakpointUp(XS) {
    width: 50%;
  }

  @include breakpointUp(LG) {
    width: (100% / 3);
  }
}
