@import '~@sonnen/shared-web/abstracts';

.c-lead-offer-pv-key-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-4);

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-lead-offer-pv-key-inputs__column {
  @include breakpointUp(SM) {
    width: var(--offer-column-width);

    &:first-child {
      margin-right: var(--offer-column-space);
    }
  }
}

.c-lead-offer-pv-key-inputs__input {
  @include breakpointUp(SM) {
    max-width: var(--offer-input-width);
  }
}
