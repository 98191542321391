@import '~@sonnen/shared-web/abstracts';

.c-missing-battery-claim-form {
  height: 100%;
}

.c-missing-battery-claim-form__banner-wrapper {
  margin-bottom: var(--gap-3);
}

.c-missing-battery-claim-form__form-banner-icon {
  @include createIcon(
    $_width: 20px,
  );
}
