@import 'src/app/designSystem/styles/';

.c-lead-new-customer-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;

  &__radio-group {
    display: flex;
    margin: 16px 0 24px;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;

    &--short {
      display: flex;
      flex-direction: row;
      gap: 30px;

      @include breakpoint('tablet') {
        gap: 62px;
      }
    }

    @include breakpoint('tablet') {
      flex-direction: row;
      width: 100%;
      gap: 62px;
    }

    &--single {
      display: flex;
      width: 100%;
      padding: 0px;

      @include breakpoint('tablet') {
        width: 50%;
        padding-right: 28px;
      }
    }
  }

  &__input-field {
    flex: 1 0 33%;
  }
}
