@import '~@sonnen/shared-web/abstracts';

@mixin text() {
    display: inline;
    font-size: 14px;
    color: getTextColor('dark');
    line-height: var(--alert-line-height);
}

.alert {
    --alert-theme: #{getTextColor('dark')};
    --alert-theme-light: #{getTextColor('grey')};
    --alert-base-height: var(--gap-3);
    --alert-line-height: 20px;
    --alert-action-icon-height: var(--gap-2);
}

.alert {
    @include borderRadius('big');
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid var(--alert-theme);
    padding: 12px var(--gap-2) 12px 12px;
    background-color: var(--alert-theme-light);
}

.alert--info {
    --alert-theme: #{getColor('blue')};
    --alert-theme-light: #{rgba(getColor('blue'), .15)};
}

.alert--success {
    --alert-theme: #{getColor('green')};
    --alert-theme-light: #{rgba(getColor('green'), .15)};
}

.alert--warning {
    --alert-theme: #{getColor('yellow')};
    --alert-theme-light: #{rgba(getColor('yellow'), .15)};
}

.alert--danger {
    --alert-theme: #{getColor('red')};
    --alert-theme-light: #{rgba(getColor('red'), .15)};
}

.alert__marker {
    --icon-height: var(--alert-base-height);
    --icon-color: var(--alert-theme);
    margin-inline-end: var(--gap-1);
}

.alert__content {
    flex: 1;
    margin: calc((var(--alert-base-height) - var(--alert-line-height)) * .5) 0;
}

.alert__headline {
    @include text();
    margin: 0;
    font-weight: 700;
    margin-inline-end: 1ex;
}

.alert__text {
    @include text();
    font-weight: 400;
}

.alert__actions {
    --alert-actions-gap: calc((var(--alert-base-height) - var(--alert-action-icon-height)) * .5);
    margin: var(--alert-actions-gap) 0 var(--alert-actions-gap) var(--gap-1);
}

.alert__button {
    --icon-height: var(--alert-action-icon-height);
    --icon-color: getColor('grey-darkest');
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        border-radius: 50%;
        background-color: rgba(getColor('grey-darkest'), .1);
        opacity: 0;
        transform: scale(0);
        transition:
            opacity 200ms ease 150ms,
            transform 200ms ease 150ms;
    }

    &:focus::before {
        opacity: 1;
        transform: scale(1);
    }

    &:hover:not(:focus)::before {
        opacity: 0;
        transform: scale(0);
    }
}