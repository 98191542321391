@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-consumption {
  margin-top: var(--gap-2);
}

.c-lead-configuration-consumption__subheadline {
  font-size: 16px;
  font-weight: 420;
  margin-bottom: var(--gap-4);
}

.c-lead-configuration-consumption__inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-2);

  @include breakpointUp(SM) {
    margin-bottom: var(--gap-4);
  }
}

.c-lead-configuration-consumption__input {
  max-width: var(--configuration-input-width);

  @include breakpointUp(SM) {
    width: var(--configuration-column-width);

    &:first-child {
      margin-right: var(--configuration-column-space);
    }
  }
}

.c-lead-configuration-consumption__checkbox-wrapper {
  display: flex;
  width: 100%;
}

.c-lead-configuration-consumption__checkbox {
  margin: var(--gap-2) 0;
  width: 50%;

  & > * {
    margin: 0;
  }
}

.c-lead-configuration-consumption__input-label {
  display: block;
  margin: 0;
  font-size: 14px;
}

.c-lead-configuration-consumption__tooltip-wrapper {
  display: flex;
}

.c-lead-configuration-consumption__tooltip-bubble {
  position: absolute;
  top: 30px;
  right: -167px;
  width: 200px;
  font-size: 12px;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 1.4;
  z-index: calc(var(--z-index-banner) + 1);

  @include breakpointUp(SM) {
    right: -327px;
    width: 360px;
  }
}

.c-lead-configuration-consumption__tooltip-icon {
  @include createIcon($_color: getColor('blue'));
  width: 18px;
  background-color: transparent;
  cursor: pointer;
}

.c-lead-configuration-consumption__tooltip {
  position: relative;
  margin-left: 4px;
}
