@import '~@sonnen/shared-web/abstracts';

.c-select-button {
  @include boxShadow();
  background-color: getColor('white');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 100%;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }

  &--disabled {
    border: 1px solid getColor('grey-light');
    box-shadow: initial;
    color: getTextColor('grey');
    cursor: initial;

    &:hover {
      transform: initial;
    }

    .c-select-button__icon {
      @include createIcon($_color: getColor('grey'));
    }
  }

  &--active {
    
    border: 1px solid getColor('blue');

    .c-select-button__text {
      color: getTextColor('blue');
    }

    .c-select-button__icon {
      @include createIcon($_color: getColor('blue'));
    }
  }
}

.c-select-button__icon {
  height: 21px;
  fill: getColor('grey');;
}

.c-select-button__text {
  font-size: 12px;
  font-weight: 420;
  color: getTextColor('grey');
  margin-top: 2px;
}
