@import "~@sonnen/shared-web/abstracts";

.c-form-input-multiselect {
  position: relative;
  margin-bottom: var(--gap-4);

  @include breakpointUp(SM) {
    width: 240px;
  }

  .sw-select:not(.c-form-input-multiselect__field--error) {
    border: 1px solid transparent;
  }
}

.c-form-input-multiselect--no-gap {
  margin-bottom: 0;
}

.c-form-input-multiselect__label {
  display: block;
  margin: 0 0 var(--gap-1);
  font-size: 13px;
  font-weight: 420;
  color: getTextColor('grey');
}

.c-form-input-multiselect__field--error {
  border: 1px solid getColor('red');
  color: getTextColor('red');
}

.c-form-input-multiselect__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-input-multiselect__error-message {
  color: getTextColor('red');
  font-size: 12px;
}
