@import '~@sonnen/shared-web/abstracts';

.c-case-status__trigger {
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 420;
  font-size: 12px;
  text-transform: uppercase;

  &--open {
    background: getColor('blue-lighter');
    color: getTextColor('blue-dark');
  }

  &--in-progress {
    background: getColor('yellow-lighter');
    color: getTextColor('yellow-dark');
  }

  &--resolved {
    background: getColor('green-lighter');
    color: getTextColor('green-dark');
  }
}

.c-case-status__tooltip-text {
  min-width: 110px;
  color: getTextColor('dark');
}
