@import '~@sonnen/shared-web/abstracts';

.c-lead-list-table-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--gap-1) 0;
}

.c-lead-list-table-item__cell {
  flex: 1 1 50%;
  margin: 0 var(--gap-2) 0 0;

  @include breakpointUp(XS) {
    flex-basis: 0;
  }
}

.c-lead-list-table-item__cell--status {
  margin-right: 0;

  @include breakpointDown(XS) {
    margin-top: var(--gap-1);
    padding-top: var(--gap-2);
    border-top: 1px solid getColor('grey-light');
  }

  @include breakpointUp(SM) {
    flex-grow: 4;
  }
}

.c-lead-list-table-item__cell--partner {
  @include breakpointUp(MD) {
    margin-right: 0;
    padding-left: 4px;
  }
}

.c-lead-list-table-item__cell--status .c-select {
  @include breakpointUp(SM) {
    max-width: 152px;
  }
}

.c-lead-list-table-item__partner {
  display: flex;
  align-items: center;
}

.c-lead-list-table-item__reassign {
  margin-left: var(--gap-2);
}

.c-lead-list-table-item__reassign-icon {
  @include createIcon($_width: 16px, $_color: getColor('blue'));

  &:hover {
    @include createIcon($_width: 16px, $_color: lighten(getColor('blue'), 15%));
  }
}

.c-lead-list-table-item__round-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--gap-1) 0;

  &:hover {
    color: lighten(getColor('grey-darkest'), 25%);

    .c-lead-list-table-item__round-btn {
      background-color: lighten(getColor('blue'), 10%);
    }
  }

  p {
    margin-bottom: 0;
    text-align: left;
  }
}

.c-lead-list-table-item__round-btn {
  margin-left: var(--gap-1);
  margin-right: 0;

  .c-round-button__inner {
    width: 22px;
  }
}

.c-lead-list-table-item__unassigned {
  margin-bottom: 0;
  font-weight: 420;
}

.c-lead-list-table-item__status {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-direction: column;

  @include breakpointUp(SM) {
    padding-left: var(--gap-2);
    align-items: center;
    flex-direction: row;
  }

  .lead-current-status__button {
    margin-left: 0;
    margin-top: var(--gap-1);

    @include breakpointUp(SM) {
      min-width: 160px;
      margin-left: var(--gap-2);
      margin-top: 0;
    }

    @include breakpointUp(LG) {
      min-width: 210px;
    }
  }
}

.c-lead-list-table-item__hint {
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: var(--gap-1);
  }
}

