@import '~@sonnen/shared-web/abstracts';

.c-case-list-table__track-visibility { 
  width: 100%;
}

.c-case-list-table {
  .c-list-table__header-item {
    margin-right: var(--gap-2);
  }
  .c-list-table__header-item--0 {
    flex: .5;
  }
  .c-list-table__header-item--1 {
    flex: .5;
  }
  .c-list-table__header-item--3 {
    flex: 1.5;
  }
  .c-list-table__header-item--5 {
    flex: 0 0 40px;
  }
}
