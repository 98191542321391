@import '~@sonnen/shared-web/abstracts';

.c-case-report-site {
  margin-bottom: var(--gap-4);
  font-size: 14px;

  @include breakpointUp(SM) {
    display: flex;
  }
}

.c-case-report-site__info-wrapper {
  padding-top: 2px;
}

.c-case-report-site__info {
  margin-bottom: var(--gap-1);
  color: getTextColor('dark');
}

.c-case-report-site__value {
  font-weight: 420;
  color: getTextColor('dark');
}

.c-case-report-site__button-wrapper {
  margin: var(--gap-1) 0 0 0;

  @include breakpointUp(SM) {
    margin: 0 0 0 var(--gap-1);
  }
}
