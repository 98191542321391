@import '~@sonnen/shared-web/abstracts';

.c-impact-analysis-page-select-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  @include breakpointDown(MD) {
    flex-direction: column;
    margin: 0 -(var(--gap-1));
  }
}

.c-impact-analysis-page-select-inputs-wrapper {
  @include breakpointUp(MD) {
    width: 45%;
    margin: 0 var(--gap-1);
  }
}

.c-impact-analysis-page-select-input-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  div {
    word-break: break-word;
  }
}

.c-impact-analysis-page-select-checkbox {
  display: inherit;
  flex: 1;
}

.c-impact-analysis-page-select-show-btn {
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  width: var(--gap-6);
  @include breakpointUp(MD) {
    margin-left: var(--gap-1);
  }
}

.c-impact-analysis-page-select-image-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
  @include breakpointUp(MD) {
    padding-left: var(--gap-6);
    margin: 0 var(--gap-1);
  }
}

.c-impact-analysis-page-select-image {
  border: 1px solid black;
  width: 100%;
}

.c-impact-analysis-page-select-image-info-icon {
  @include createIcon($_width: var(--gap-2), $_color: getColor('blue'));
  margin-right: var(--gap-1);
}

.c-impact-analysis-page-select-warning-wrapper {
    margin-bottom: var(--gap-2);
}

.c-impact-analysis-page-select-image-info {
  display: inherit;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: var(--gap-1);
}

.c-impact-analysis-page-select-image-info-text {
  display: inherit;
  align-items: center;
  height: var(--gap-2);
}

.c-lead-impact-analysis-pdf-creation__checkbox {
  margin: var(--gap-2) 0 var(--gap-3);
}
