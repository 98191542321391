@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-vpp-action-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-lead-configuration-vpp-action-box__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--gap-1);
  font-size: 18px;
  color: getTextColor('dark');
}

.c-lead-configuration-vpp-action-box__header--no-margin {
  margin-bottom: 0;
}

.c-lead-configuration-vpp-action-box__header--blue {
  color: getColor('blue');
}

.c-lead-configuration-vpp-action-box__header--gray,
.c-lead-configuration-vpp-action-box__body-text--gray {
  color: getTextColor('grey');
}

.c-lead-configuration-vpp-action-box__body-text {
  margin-bottom: 0;
  font-size: 12px;
}

.c-lead-configuration-vpp-action-box__icon-start {
  @include createIcon(
    $_width: 12px,
    $_color: getColor('white')
  );
}

.c-lead-configuration-vpp-action-box__upper {
  width: 100%;
}
