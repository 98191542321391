@import '~@sonnen/shared-web/abstracts';

.c-setup-lead-statuses {
  display: flex;
}

.c-setup-lead-statuses__item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 420;

  .c-setup-lead-statuses__item__icon {
    width: 16px;
    height: 16px;
    margin: 0 var(--gap-1);
  }
}

.c-setup-lead-statuses__button {
  margin-left: var(--gap-3);
  min-width: inherit;
}

.c-lead-overview-header,
.c-lead-list-table-item__cell--mobile-bottom {
  @include breakpointDown(XS) {
    .c-setup-lead-statuses {
      flex-wrap: wrap;
    }

    .c-setup-lead-statuses__button {
      margin-left: 0;
      margin-top: var(--gap-3);
    }
  }
}
