@import '~@sonnen/shared-web/abstracts';

.c-list-table--setup-list {
  .c-lead-list-table-item__cell {
    flex-grow: 1;
    margin-right: var(--gap-2);

    & > * {
      margin-left: var(--gap-2);
    }
  }

  .c-lead-list-table-item__cell--setup {
    &-name {
      flex-grow: 1.1;
    }

    &-status {
      flex-grow: 2.8;

      .c-setup-lead-statuses {
        display: flex;
        width: 100%;
        justify-content: space-around;
      }

      .c-setup-lead-statuses-tooltip__wrapper {
        margin: 0;
      }
    }
  }
}
