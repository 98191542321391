@import '/src/app/designSystem/styles/';

.c-tab-nav {
  position: relative;
  min-height: 56px;
  overflow: hidden;
  width: 100%;

  @include breakpoint('desktop') {
    overflow: initial;
  }
}

.c-tab-nav__list {
  display: flex;
  justify-content: space-evenly;
  min-height: inherit;
  padding-bottom: 2px;
  width: 100%;

  @include breakpoint('tablet') {
    justify-content: flex-start;
  }

  & > li {
    flex-direction: column;
    text-align: center;

    @include breakpoint('mobile') {
      flex-direction: row;
    }
  }
}
