@import '~@sonnen/shared-web/abstracts';

.your-sonnen-world-trigger {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
}

.your-sonnen-world-trigger__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  padding-right: var(--gap-2);

  @include breakpointUp(NAV) {
    flex-direction: row;
    align-items: center;
    padding-right: 0;
  }
}

.your-sonnen-world-trigger__content-item {
  .is-active > .your-sonnen-world-trigger & {
    color: getColor('blue-light');
  }
}

.your-sonnen-world-trigger__arrow {
  position: relative;
  width: var(--gap-4);
  height: var(--gap-4);
  margin: 0 2px;

  @include breakpointUp(NAV) {
    width: 10px;
    height: 10px;
    margin: 0 0 0 4px;
  }

  .is-active > .your-sonnen-world-trigger > & {
    transform: rotate(180deg);
  }

  &::after {
    content: config(ICONS, ANGLE);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: var(--font-family-icons);
    font-size: 12px;
    text-align: center;
    transform: rotate(-90deg);

    @include breakpointUp(NAV) {
      font-size: 9px;
    }
  }

  .is-active > .your-sonnen-world-trigger > &::after {
    color: getColor('blue-light');
  }
}
