@import "~@sonnen/shared-web/abstracts";

.c-lead-overview-header-title {
  display: flex;
  flex-direction: column;
  padding-right: var(--gutter-h);

  @include breakpointUp(XS) {
    align-items: flex-end;
    flex-direction: row;
  }
}

.c-lead-overview-header-title__lead-name {
  display: flex;
  align-items: flex-end;
  vertical-align: middle;
  font-size: 26px;
  font-weight: 300;
  color: getTextcolor('dark');
  line-height: 1.4;
  margin-bottom: 0;

  @include breakpointUp(SM) {
    font-size: 32px;
  }

  @include breakpointDown(SM) {
    width: min-content;
    margin-bottom: var(--gap-1);
  }
}

.c-lead-overview-header-title__customer-number {
  @include breakpointUp(XS) {
    margin-left: var(--gap-3);
  }
}
