button.toolbox-button {
  display: flex;
  background: white;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span.label {
    font-weight: 500;
    padding-right: 8px;
    white-space: nowrap;
  }
}
