@import '~@sonnen/shared-web/abstracts';

.c-case-report-form {
  width: 100%;
  height: 100%;
}

.c-case-report-form__header {
  position: relative;
  margin-bottom: var(--gap-3);

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + var(--gap-4) * 2);
    left: calc(var(--gap-4) * -1);
    bottom: 0;
    height: 1px;
    background: getColor('grey-lighter');
  }
}

.c-case-report-form__paragraph {
  padding-bottom: var(--gap-3);
}

.c-case-report-form__section:not(:last-child) {
  margin-bottom: var(--gap-4);
}

.c-case-report-form__section-headline {
  margin: 0 0 var(--gap-1);
  font-weight: 420;
  font-size: 16px;
}

.c-case-report-form__topic {
  max-width: 630px;
}

.c-case-report-form__textarea {
  max-width: 630px;
}

.c-case-report-form__textarea textarea {
  height: 110px;
}

.c-case-report-form__input {
  padding-bottom: var(--gap-2);
}

.c-case-report-form__button {
  margin-left: auto;
}

.c-case-report-form__upload-input {
  padding: 0 0 var(--gap-3);
}

.c-case-report-form__error-message {
  color: getTextColor('red');
  font-size: 12px;
}

.c-case-report-form__footer {
  @include boxShadow($_side: 'top');
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  min-height: var(--gap-8);
  padding: 0 var(--modal-layout-padding-h);
}
