@import '~@sonnen/shared-web/abstracts';

.c-page-block {
  --desktop-direction: column;
  --mobile-direction: column;
  --flex-flow: nowrap;
}

.c-page-block {
  display: flex;
  align-items: stretch;
  flex-direction: var(--mobile-direction);
  flex-wrap: var(--flex-flow);
  justify-content: space-between;
  width: 100%;
  padding: var(--gap-2) 0 0;

  @include breakpointUp(XS) {
    flex-direction: var(--desktop-direction);
    padding: var(--gap-4) 0 0;
  }
}
