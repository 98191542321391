@import '~@sonnen/shared-web/abstracts';

.c-analysis-line-chart-tooltip {
  position: relative;
}

.c-analysis-line-chart-tooltip__icon {
  position: absolute;
  right: -18px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  @include createIcon(
    $_color: getColor('blue'),
    $_height: var(--gap-2)
  );
}
