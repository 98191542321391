@import '~@sonnen/shared-web/abstracts';

.c-download {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--content-height);
}

.c-download__card {
  position: relative;
  margin-bottom: 100px;
  width: 400px;
  text-align: center;
  z-index: 1;
}

.c-download__icon {
  font-size: 40px;
  color: getColor('blue')
}

.c-download__paragraph {
  padding: var(--gap-4) var(--gap-2) var(--gap-2);
}
