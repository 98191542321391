@import '~@sonnen/shared-web/abstracts';

.c-pagination {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.c-pagination__jump-to-page-label {
  color: $grey_500;
  margin-left: 16px;
}

.c-pagination__jump-to-page-input {
  width: 70px;
  margin: 6px var(--gap-1);
  font-family: inherit;
}

.c-pagination__page-list-container {
  display: inherit;
  margin-left: 10px;
}

.c-pagination__page-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.c-pagination__page {
  position: relative;
  padding: var(--gap-1);
  font-size: 16px;
  font-weight: 420;
  line-height: 24px;
  transition: opacity 400ms ease-in-out;
  color: getTextColor('blue');
  margin: 0 4px;

  &.ds-button {
    font-size: 16px;
    padding: 0;
  }

  &:hover {
    color: $blue_700;
  }

  &.is-active {
    color: getColor('blue');

    &:after {
      content: '';
      position: absolute;
      left: 10%;
      width: 80%;
      bottom: -4px;
      height: 2px;
      background-color: getColor('blue');
      border-radius: 1px;
    }
  }
}

.c-pagination__btn-prev,
.c-pagination__btn-next {
  padding: var(--gap-1);
  color: getColor('blue');

  &.ds-button {
    padding: 0;
  }

  @include breakpointUp(NAV) {
    padding: var(--gap-2);
  }

  * {
    font-size: inherit;
  }

  &:disabled {
    color: getTextColor('grey');

    &:hover {
      cursor: no-drop;
    }
  }
}

.c-pagination__btn-next {
  transform: scaleX(-1);
}

.c-pagination__rest {
  display: inline-block;
  padding: 0 var(--gap-1);
  color: getTextColor('blue');
  font-size: 18px;

  @include breakpointUp(NAV) {
    padding: 0 var(--gap-2);
  }
}
