@import '/src/app/designSystem/styles/';

.sites-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  color: $grey_900;
  padding: 0 0 8px 0;

  &__customers-tab {
    margin-right: 8px;
  }

  &__headline {
    font-size: 32px;
    font-weight: 330;
  }

  &__battery-button {
    display: flex;
    align-items: center;

    .button-icon {
      fill: $blue_300;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}