@import '~@sonnen/shared-web/abstracts';

.c-overlay__content--setup {
  background: getColor('white'); // fix for MacOS Chrome browser's edges

  .c-form-input__field,
  .c-form-input-radio-label,
  .c-form-field-label,
  .sw-select__input,
  .sw-select__trigger-items,
  .sw-select__trigger-placeholder {
    font-size: 14px;
    font-weight: 400;
  }

  .sw-select__input {
    padding: var(--gap-1) 0;
  }

  .c-form-input__field[value=''],
  .sw-select__input[value=''] {
    color: getTextColor('grey');
  }

  .sw-select__input[value=''] {
    &::placeholder {
      color: getTextColor('grey');
      font-weight: 400;
    }
  }

  .c-form-controlled-checkbox__input:checked
  + .c-form-controlled-checkbox__label {
    --form-checkbox-box-border-color: #{getColor('blue')};
  }

  .c-form-controlled-checkbox__box {
    border: 1px solid var(--form-checkbox-box-border-color);
    background: getColor('white');
  }

  .c-form-controlled-checkbox__input:checked
  + .c-form-controlled-checkbox__label .c-form-controlled-checkbox__box {
    background-color: getColor('blue');
  }

  .c-form-controlled-checkbox__input:checked
  + .c-form-controlled-checkbox__label {
    --form-checkbox-box-border-color: #{getColor('blue')};
  }

  .c-form-controlled-checkbox__box-icon {
    @include createIcon(
      $_width: 10px,
      $_height: 10px,
      $_color: getColor('white')
    );
  }

  .c-form-input__field[value='']:focus {
    color: getTextColor('dark');
  }

  .sw-select__input,
  .sw-select__trigger-items {
    font-family: inherit;
  }

  .c-form-section {
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }

  .c-form-section-paragraph {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: var(--gap-4);
    margin-top: var(--gap-3);

    &--smaller {
      font-size: 14px;
      margin-bottom: var(--gap-3);
    }

    &:first-of-type {
      margin-top: 0;
    }

    &--extra-margin {
      margin-top: var(--gap-5);
    }

    &--without-extra-margin {
      margin-bottom: calc(var(--gap-2)*(-1));
    }
  }

  .c-form-input__label,
  .c-form-input-select__label {
    font-size: 14px;
    font-weight: 400;
  }

  .c-help__side-info {
    background: getColor('white');
    margin-bottom: var(--gap-3);

    .sw-side-info__content {
      display: flex;
      justify-content: flex-start;
    }
  }

  .c-help__side-info-icon {
    height: 24px;
    margin-right: var(--gap-3);
  }

  .c-help__side-info--complete-box {
    color: getTextColor('dark');
    margin: 0 0 var(--gap-7);
    max-width: 100%;
    padding: var(--gap-2);
    width: 100%;

    .c-help__side-info-icon svg {
      fill: getColor('blue');
    }

    .sw-side-info .sw-side-info__content,
    .sw-side-info h3 {
      color: getTextColor('dark');
    }
  }

  .c-setup-tool__help-content {
    background: getColor('grey-lightest');
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
