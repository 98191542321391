@import '/src/app/designSystem/styles/';

.table-footer-wrapper {
    // by adding this margin, the Select menu has space to open downwards
    margin-bottom: 50px;

  .table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 14px 8px;

    &__left {
      display: flex;
      align-items: center;

      .total-results {
        margin-right: 16px;
        white-space: nowrap;
        color: $grey_500;
      }

      .rows-per-page-select {
        display: inline-block;
        width: unset;
        white-space: nowrap;
      }
    }
  }
}
