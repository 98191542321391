@import '~@sonnen/shared-web/abstracts';

.c-news-list-table-headline__inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.c-news-list-table__pagination {
  flex-flow: initial;
  justify-content: center;
}

.c-list-table--news-list {
  .c-list-table__header-item {
    @include breakpointUp(SM) {
      flex-grow: 1;
    }
  }
}

.c-list-table--news-list--sp {
  .c-list-table__header-item.c-list-table__header-item--0,
  .c-list-table__header-item.c-list-table__header-item--1 {
    @include breakpointUp(SM) {
      flex-grow: 2;
    }
  }
}

.c-list-table--news-list--sp {
  .c-list-table__header-item.c-list-table__header-item--2 {
    @include breakpointUp(SM) {
      flex-grow: 1.5;
    }
  }
}

.c-list-table--news-list--cp {
  .c-list-table__header-item.c-list-table__header-item--3,
  .c-list-table__header-item.c-list-table__header-item--4 {
    @include breakpointUp(SM) {
      flex-grow: 2;
    }
  }

  .c-list-table__header-item.c-list-table__header-item--5 {
    @include breakpointUp(SM) {
      flex-grow: 0.5;
    }
  }
}
