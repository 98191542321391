@mixin breakpoint($breakpoint) {
  // VIEWPORT SIZES
  $_mobile: 'screen and (min-width: 320px)';
  $_tablet: 'screen and (min-width: 768px)'; // 767-768 exists in current setup
  $_desktop: 'screen and (min-width: 1199px)'; // 1199-1200 exists in current setup
  $_extra-wide: 'screen and (min-width: 1550px)'; // 1549-1550 exists in current setup

  // MEDIA QUERIES
  @if $breakpoint == mobile {
    @media #{$_mobile} {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media #{$_tablet} {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media #{$_desktop} {
      @content;
    }
  }
  @if $breakpoint == extra-wide {
    @media #{$_extra-wide} {
      @content;
    }
  }
}
