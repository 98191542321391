@import "/src/app/designSystem//styles/";

$animationSpeed: 0.3s;
$animationType: ease-in-out;

.expandable-search-field {
  height: 30px;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  
  &__input {
    position: absolute;
    width: 0;
    border: none;
    right: 0;
    transition: width $animationSpeed $animationType;
    background: transparent;
    line-height: 30px;
    margin-bottom: 3px;
    padding-right: 30px;
    font-size: $_14;
    font-family: inherit;

    &:focus,
    :active {
      outline: none;
    }

    &::placeholder {
      color: $grey_200;
      font-size: $_14;
    }
  }

  &__search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    z-index: 1;
    right: 0;
    transition: transform $animationSpeed $animationType;
    
    .search-button-icon {
      width: 26px;
      height: 26px;
      margin-top: 2px;
      fill: $grey_900;
      
      &:hover {
        cursor: pointer;
        fill: $blue_500;

        &:disabled:hover {
          fill: $blue_500;
          cursor: default;
        }
      }
    }
  }

  &__clear-text-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-right: 2px;
    fill: $grey_900;

    &:hover {
      fill: $blue_500;
    }
  }

  &__line {
    position: absolute;
    border: none;
    width: 0px;
    height: 2px;
    background-color: $blue_500;

    bottom: -10px;
    transition: width $animationSpeed $animationType;
  }

  // animation classes
  .open {
    transition: width $animationSpeed $animationType;
    width: 250px;
  }
  .closed {
    visibility: hidden;
  }
  .move {
    transform: translateX(-260px);
    transition: transform $animationSpeed $animationType;

    .search-button-icon {
      fill: $blue_500;

      &:hover {
        cursor: default;
      }
    }
  }
  .expand {
    width: 280px;
    transition: width $animationSpeed $animationType;
  }
}
