@import '~@sonnen/shared-web/abstracts';

.c-new-feature-tooltip__active-element-reference {
  position: relative;
  z-index: 101;
  pointer-events: none;
}

.c-new-feature-tooltip__active-element-wrapper {
  position: inherit;
  z-index: 1;
}

.c-new-feature-tooltip__active-element-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + var(--gap-5));
  height: calc(100% + var(--gap-3));
  transform: translate(-50%, -50%);
  border-radius: 30px;
  background-color: getColor('white');
}

.c-new-feature-tooltip__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: getColor('modal-overlay');
  z-index: 100;
}

.reusable-class__tooltip.new-feature-tooltip {
  padding: 16px;
  max-width: 256px;
}
