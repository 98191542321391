@import '~@sonnen/shared-web/abstracts';

.c-delete-button {
  position: relative;
}

.c-delete-button__icon-with-text {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 10px;
}

.c-delete-button__icon {
  @include createIcon(
    $_width: 14px,
    $_color: getColor('blue')
  );
  height: 14px;
  margin-bottom: var(--gap-1);
}