@import '~@sonnen/shared-web/abstracts';

.c-form-input-select {
  position: relative;
  margin-bottom: var(--gap-4);

  .sw-select:not(.c-form-input-select__field--error) {
    border: 1px solid transparent;
  }

  &.c-form-input-select--error {
    .sw-select {
      border: 2px solid getColor('red');
      border-radius: 3px;
    }
  }
}

.c-form-input-select--no-gap {
  margin-bottom: 0;
}

.c-form-input-select__label {
  display: inline-block;
  position: relative;
  margin: 0 0 var(--gap-1);
  font-size: 14px;
  font-weight: 420;
  color: getTextColor('dark');
}

.c-form-input-select__field--error {
  color: getTextColor('red');
}

.c-form-input-select__field-wrapper--disabled {
  cursor: not-allowed;
}

.c-form-input-select__field--disabled {
  pointer-events: none;
  color: getTextColor('grey');
}

.c-form-input-select__error {
  position: absolute;
  padding-top: var(--gap-1);
}

.c-form-input-select__error-message {
  color: getTextColor('red');
  font-size: 12px;
}
