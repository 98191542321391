@import "~@sonnen/shared-web/abstracts";

.c-setup-info-icon-tooltip {
  --guide-text-with-tooltip-visibility: hidden;
  --guide-text-with-tooltip-opacity: 0;
  position: relative;
  font-size: 14px;

  &:hover {
    --guide-text-with-tooltip-visibility: visible;
    --guide-text-with-tooltip-opacity: 1;
  }
}

.c-setup-info-icon-tooltip__bubble {
  position: absolute;
  z-index: var(--z-index-tooltip);
  left: -55px;
  bottom: 30px;
  width: 130px;
  visibility: var(--guide-text-with-tooltip-visibility);
  opacity: var(--guide-text-with-tooltip-opacity);
  transition: visibility 200ms, opacity 200ms;
  background-color: getColor('grey-lightest');

  .sw-bubble {
    @include boxShadow();
    padding: var(--gap-1);

    p:nth-of-type(3) {
      margin-top: var(--gap-1);
    }

    &:after {
      box-shadow: -4px 4px 5px -2px getColor('grey-light');
    }
  }
}

.c-setup-info-icon-tooltip__bubble__title,
.c-setup-info-icon-tooltip__bubble__description {
  margin: 0;
  font-size: 10px;
  line-height: normal;
}

.c-setup-info-icon-tooltip__bubble__title {
  font-weight: 420;
  color: getTextColor('dark');
}

.c-setup-info-icon-tooltip__bubble__description {
  font-weight: normal;
}

.c-setup-info-icon-tooltip__trigger {
  @include createIcon(
    $_height: 20px,
    $_color: getColor('blue')
  );

  cursor: pointer;
}
