@import "~@sonnen/shared-web/abstracts";

.c-video {
  cursor: pointer;

  @include breakpointUp(XS) {
    flex-basis: calc(100% / 2);
  }

  @include breakpointUp(LG) {
    flex-basis: calc(100% / 3);
  }

  flex-basis: 100%;
  padding: var(--gap-2);
}

.c-video__title {
  @include breakpointUp(SM) {
    margin-top: var(--gap-2);
    font-size: 16px;
  }

  @include breakpointUp(LG) {
    font-size: 18px;
  }

  margin-top: var(--gap-1);
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 420;
}
