@import "~@sonnen/shared-web/abstracts";

.c-setup-tool-documents__placeholder {
  font-weight: 420;
  color: getColor('blue');
}

.c-setup-tool__upload-input-label {
  color: getTextColor('dark');
  margin-bottom: calc(-1 * var(--gap-2));
  font-size: calc(1.4 * var(--font-size));

  span {
    font-style: italic;
    font-weight: 200;
  }

  &--picture-format span {
    font-style: normal;
    font-size: calc(2px + var(--font-size));
  }
}

.c-dso-registration-documents__checkbox {
  margin: var(--gap-2) 0 var(--gap-3);
}
