.c-form-input-phone {
  flex: 1 0 33%;
  position: relative;

  input {
    padding-left: 40px;
  }
}

.c-form-input-phone__country-code {
  position: absolute;
  left: 10px;
  top: 35px;
  z-index: 1;
  font-size: 13px;
  color: getTextColor('dark');
  font-weight: 420;
  line-height: 1.4;
}
