@import "~@sonnen/shared-web/abstracts";

.c-battery-mode {
  display: flex;
  flex-direction: row;
}

.c-battery-mode__info {
  position: relative;
  margin-left: var(--gap-1);
}

.c-battery-mode__info-icon {
  @include createIcon(
    $_color: getColor('blue'),
    $_width: 18px,
    $_height: 18px,
  );
  background-color: transparent;
  cursor: pointer;
}

.c-battery-mode__info-bubble-wrapper {
  position: absolute;
  top: 30px;
  right: -156px;
  width: 330px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  z-index: var(--z-index-tooltip);
}
