@import '~@sonnen/shared-web/abstracts';

.lead-status-modal__tooltip {
  position: relative;
  margin-left: var(--gap-2);
  line-height: 1.4;
}

.lead-status-modal__info-icon {
  @include createIcon($_color: getColor('blue'), $_width: 18px, $_height: 18px);
  background-color: transparent;
  cursor: pointer;
}

.lead-status-modal__bubble-wrapper {
  position: absolute;
  top: 30px;
  right: -15px;
  width: 200px;
  font-size: 12px;
  font-weight: 300;
  z-index: 2;
}

.lead-status-modal__setup-upper {
  display: flex;
  justify-content: space-between;
}

.lead-status-modal__setup-headline {
  display: flex;
}

.lead-status-modal__star-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: getColor('grey-light');
}

.lead-status-modal__star-icon-wrapper--green {
  background-color: getColor('green');
}

.lead-status-modal__star-icon {
  @include createIcon($_color: getColor('white'), $_width: 10px, $_height: 10px);
}

.lead-status-modal__setup-lower {
  margin: var(--gap-2) var(--gap-4) 0;
  font-size: 12px;
  font-weight: 420;
  line-height: 1.4;
}

.lead-status-modal__warning-wrapper {
  margin: var(--gap-2) 0;
}

.lead-status-modal__battery-details {
  font-size: 14px;
  font-weight: 420;
  color: getTextColor('grey');

  &:first-of-type {
    margin-top: var(--gap-2);
  }
}

.lead-status-modal__new-hardware-paragraph {
  font-size: 12px;
}

.lead-status-modal__new-hardware-link {
  --link-button-icon--size: 27px;
  margin: var(--gap-1) 0 var(--gap-2) 0;
}
