@import '~@sonnen/shared-web/abstracts';

.c-accordion-button {
  display: flex;
  align-items: baseline;

  @include breakpointDown(XS) {
    align-items: center;
  }
}

.c-accordion-button__text {
  color: getTextColor('blue');
  font-weight: 420;

  @include breakpointUp(MD) {
    font-size: 16px;
  }
}

.c-accordion-button__icon {
  @include createIcon(
    $_color: getColor('blue'),
    $_width: 10px,
    $_height: 10px
  );
  margin-left: var(--gap-1);
  line-height: 0;
  transition: transform 200ms ease 100ms;
  transform: rotate(-90deg);

  &.is-rotated {
    transform: rotate(90deg);
  }
}
