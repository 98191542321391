@import '~@sonnen/shared-web/abstracts';

.c-lead-overview-action-failed-failed-modal__inner {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: var(--gap-8);
  padding-bottom: var(--gap-7);
}

.c-lead-overview-action-failed-failed-modal__heading {
  margin-top: var(--gap-1);
  font-size: 18px;
}

.c-lead-overview-action-failed-failed-modal__icon {
  width: 50px;
  fill: getColor('blue')
}

.c-lead-overview-action-failed-failed-modal__content {
  max-width: 280px;
  text-align: center;
  padding-top: var(--gap-1);
  padding-bottom: var(--gap-2);
}

.c-lead-overview-action-failed-failed-modal__paragraph {
  color: getTextColor('grey');
  font-size: 14px;
}
