@import '~@sonnen/shared-web/abstracts';

.c-certificate-list-item {
  font-size: 14px;
}

.c-certificate-list-item__key {
  color: getTextColor('dark');
  flex: 1 0 20%;

  @include breakpointDown(SM) {
    flex: 1 0 30%;
  }
}

.c-certificate-list-item__value {
  flex: 0 1 80%;

  @include breakpointDown(SM) {
    flex: 0 1 70%;
  }
}

.c-certificate-list-item__row {
  padding: var(--gap-1) 0;
}

.c-certificate-list-item__row--renew {
  padding: var(--gap-2) 0;
}
