@import '~@sonnen/shared-web/abstracts';

.c-pagination-summary {
  align-self: center;
  color: getTextColor('dark');
  font-size: 13px;
  font-weight: 420;
}

.c-pagination-summary--light {
  color: getTextColor('grey');
}

.c-pagination-summary--reordered {
  display: inline-block;
  align-self: auto;
  color: getTextColor('dark');
  font-size: 13px;
  font-weight: 420;
  order: 2;
  margin-bottom: var(--gap-1);

  @include breakpointUp(SM) {
    order: 1;
    margin-bottom: 0;
  }

  @include breakpointUp(MD) {
    align-items: center;
  }
}
