@import '~@sonnen/shared-web/abstracts';

.c-certificate-list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-certificate-list-item-header__status {
  padding: 4px var(--gap-1);
  border-radius: 4px;
  font-weight: 420;
  font-size: 12px;
  color: getTextColor('white');

  &--expired {
    background-color: getColor('red');
  }

  &--active {
    background-color: getColor('green');
  }
}
