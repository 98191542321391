@import '~@sonnen/shared-web/abstracts';

.c-lead-list-empty {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: calc(100vh - 490px);
  min-height: 250px;
  max-width: 330px;
  margin: 0 auto;
  text-align: center;
}

.c-lead-list-empty__icon {
  @include createIcon($_height: 125px, $_color: getColor('blue'));
  margin: 0 auto;
}

.c-lead-list-empty__headline {
  font-size: 20px;
  margin: var(--gap-4) 0 var(--gap-2);
}

.c-lead-list-empty__description {
  font-size: 14px;
  color: getTextColor('grey');
}
