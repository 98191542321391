@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-autarky {
  margin: 24px 0 16px;
  white-space: pre-line;
  font-size: 14px;
  line-height: 1.4;
}

.c-lead-configuration-autarky-value {
  font-weight: 420;
}

.c-lead-configuration-autarky__info {
  @include breakpointDown(MD) {
    @include breakpointUp(XS) {
      margin-top: 24px;
    }
  }
}

.c-lead-configuration-autarky__form-wrapper {
  @include breakpointDown(MD) {
    @include breakpointUp(XS) {
      display: flex;
      justify-content: center;

      > :first-child {
        margin-right: var(--gap-6);
      }

      > * {
        flex: 1;
      }
    }
  }
}
