@import '~@sonnen/shared-web/abstracts';

.c-add-video-link__movie-preview {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpointUp(XS) {
    flex-direction: row;
  }
}

.c-add-video-link__movie-title {
  margin: var(--gap-2) 0;

  @include breakpointUp(XS) {
    margin: 0 var(--gap-2);
  }
}

.c-add-video-link__movie-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpointUp(XS) {
    flex-direction: row;
  }
}

.c-add-video-link__movie-not-found {
  padding: var(--gap-2);
}
