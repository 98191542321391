@import '~@sonnen/shared-web/abstracts';

.c-form-input-radio-box-group {
  margin-right: var(--gap-3);
}

.c-form-input-radio-box-group__text {
  color: getTextColor('grey');
  font-size: var(--font-size);
  font-weight: 420;
  line-height: 1.5;
}

.c-form-input-radio-box-group__container {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
