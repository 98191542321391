@import '~@sonnen/shared-web/abstracts';

.c-page-header {
  @include boxShadow($_side: 'bottom');
  position: relative;
  overflow: visible;
  background-color: getColor('white');

  @include breakpointUp(SM) {
    padding: var(--gutter-v) 0 0;
  }
}

.c-page-header__headline {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: var(--gap-2) 0 0;
  width: 100%;

  @include breakpointUp(NAV) {
    padding-bottom: var(--gap-3);
    flex-direction: row;
  }
}

.c-page-header__headline--expanded {
  @include breakpointUp(SM) {
    align-items: flex-start;
  }

  @include breakpointUp(MD) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: var(--gap-4);
  }
}

.c-page-header__container {
  @include breakpointDown(SM) {
    &:not(:last-child) {
      margin: 0 0 10px;
    }
    padding-top: 30px;
  }
}

.c-page-header__breadcrumb {
  width: 100%;
}

.c-page-header__tab-nav {
  display: flex;
  justify-content: space-evenly;

  @include breakpointUp(SM) {
    justify-content: flex-start;
  }
}

.c-page-header__icon {
  @include createIcon($_width: 18px, $_color: getColor('white'));
  margin-right: 5px;
}

.c-page-header__loader {
  width: 100%;
  margin: var(--gap-2) 0;
}
