@import '~@sonnen/shared-web/abstracts';

.c-footer-nav-item {
  display: flex;
  width: 50%;

  @include breakpointUp(SM) {
    width: auto;
  }
}

.c-footer-nav-item__link {
  flex: 1 0 50%;
  padding: 0 24px 0 0;
  line-height: 30px;
  color: inherit;
  font-size: 12px;
  font-weight: 420;
  text-align: left;
  transition: all .25s ease;
  cursor: pointer;
  text-wrap: nowrap;

  &:hover,
  &.is-active {
    color: getTextColor('blue');
  }

  @include breakpointDown(MD) {
    padding: 0 16px 0 0;
  }

  @include breakpointUp(SM) {
    flex: 1 0 auto;
  }

  @include breakpointUp(XLG) {
    padding: 0 12px;
  }
}
