@import '~@sonnen/shared-web/abstracts';

.c-customer-header {
  position: relative;

  &__report-case-button {
    float: right;
  
    @include breakpointUp(XS) {
      height: 0;
    }
  }
  
  &__title {
    display: flex;
    align-items: flex-end;
    vertical-align: middle;
    font-size: 26px;
    font-weight: 300;
    color: getTextColor('dark');
    line-height: 1.4;
  
    @include breakpointUp(SM) {
      padding-right: var(--gutter-h);
    }
    @include breakpointUp(SM) {
      font-size: 32px;
    }
    @include breakpointUp(NAV) {
      max-width: 50%;
    }
  }
  
  &__address {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 300px;
    margin: 10px 0;
    padding: 0 2px;
    font-size: 13px;
    color: getTextColor('dark');
  
    @include breakpointUp(MD) {
      margin: 0 auto 0 0;
      padding: 0 var(--gap-1);
    }
  }
  
  &__address-info {
    display: block;
    margin-bottom: 10px;
  }
  
  &__nav-icon {
    font-size: 24px;
    margin-right: var(--gap-1);
    
    @include breakpointDown(XS) {
      display: none;
    }
  }
  
  &__nav-text {
    margin: 0;
    white-space: nowrap;
  }
  
  &__breadcrumb {
    padding: 0;
  }
  
  &__breadcrumb--icon {
    fill: #0B7AC2;
    height: 10px;
    margin-right: 5px;
  }
}
.c-nav-link__action-container.is-active, .c-nav-link__action-container:hover {
  .c-customer-header__nav-icon {
    stroke: $blue_500;
  } 
}
.c-nav-link__action-container .c-customer-header__nav-icon {
  stroke: $grey_500;
}

