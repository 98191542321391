@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-battery {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-bottom: var(--gap-6);
}

.c-lead-configuration-battery__columns-wrapper {
  display: flex;
  flex-direction: column;

  @include breakpointUp(SM) {
    flex-direction: row;
  }
}

.c-lead-configuration-battery__column {
  @include breakpointUp(SM) {
    width: var(--configuration-column-width);

    &:first-child {
      margin-right: var(--configuration-column-space);
    }
  }
}

.c-lead-configuration-battery__input {
  max-width: var(--configuration-input-width);
  margin-bottom: var(--gap-1);
}

.c-lead-configuration-battery__message {
  margin-bottom: var(--gap-4);
  max-width: var(--configuration-input-width);
  font-size: 10px;
  opacity: .5;
  color: getTextColor('dark');
  font-weight: 420;
  margin: 0;
}

.c-lead-configuration-battery__btn {
  font-size: 10px;
  font-weight: 420;
}

.c-lead-configuration-battery__new-battery-question {
  font-size: 14px;
  margin-bottom: var(--gap-3);
 }

 .c-lead-configuration-battery__new-battery-radio-btns {
  margin-bottom: var(--gap-4);
 }
