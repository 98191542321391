@import '/src/app/designSystem/styles/';

.sites-list {
  &__sticky-cell {
    position: sticky;
    z-index: 1;
    background-color: inherit;
    background-clip: padding-box; // prevents background from cover border of parent
  }

  &__selection {
    @extend .sites-list__sticky-cell;
    left: 0;
  }

  &__name {
    @extend .sites-list__sticky-cell;
    width: 220px;
    font-size: $_14;
    font-weight: $_medium;
    left: 0;

    // TODO(SON-35821): remove padding when Selection column is introduced again
    // because we hid the Selection column,
    // this padding adds a bit more space on the left to the Name column
    padding-left: 18px;

    .sortable-header-cell {
      // TODO(SON-35821): remove padding when Selection column is introduced again
      padding-left: 18px;
    }
  }

  &__serial {
    min-width: 80px;
    font-variant: tabular-nums;
  }

  &__battery {
    width: 160px;
  }

  &__location {
    width: 210px;
  }

  &__commissioning {
    font-variant: tabular-nums;
  }

  &__contract {
    min-width: 106px;
  }

  &__status {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
  }

  &__cases {
    min-width: 40px;
    text-align: center;

    .create-new-case {
      fill: $blue_500;
      
      &:hover {
        fill: $blue_300;
      }
    }
  }

  &__search-bold-text {
    font-weight: $_bold;
    color: $blue_400;
  }
}

.two-lines-padding {
  padding: 12px 14px;
}