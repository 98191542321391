@import '~@sonnen/shared-web/abstracts';

.c-battery-intelligent-charging-operation {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.c-battery-intelligent-charging-operation__info {
  margin-bottom: var(--gap-1);
  font-size: 14px;
  line-height: 1.4;
}

.c-battery-intelligent-charging-operation__radio-group label {
  font-size: 14px;
  font-weight: 400;
  color: getTextColor('dark');
}

.c-battery-intelligent-charging-operation__link {
  color: getTextColor('blue');
  font-weight: 420;
  font-size: 12px;
}

.c-battery-intelligent-charging-operation__checkboxes {
  margin-bottom: var(--gap-3);
}

.c-battery-intelligent-charging-operation__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 calc(var(--accodion-content-padding) * -1);
  padding: var(--gap-3) var(--accodion-content-padding) 0;
  border-top: 1px solid getColor('grey-lighter');
}
