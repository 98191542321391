@import "~@sonnen/shared-web/abstracts";

.alert {
  display: flex;
  flex-direction: row;
  border-width: 1px 1px 1px 4px;
  border-color: getColor('blue');
  border-style: solid;
  border-radius: 4px;
  padding: 14px 0px 12px 0px;
}

.alert__icon {
  flex-basis: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 40px;
}

.alert__icon-arrow {
  transition: opacity 200ms ease;

  svg {
    fill: $grey_900;
  }

  &.is-rotated {
    transform: rotate(180deg);
  }
}

.alert__content {
  flex-basis: 90%;

  &-header {
    line-height: 20px;
    font-weight: 420;
    font-size: 16px;
  }

  &-text {
    line-height: 20px;
    font-weight: 390;
    font-size: 14px;
  }

  &-text > a {
    font-weight: 420;
    font-size: 16px;
    color: getColor('blue');
  }

  &.truncation {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
}
