@import '~@sonnen/shared-web/abstracts';

.c-contract-paragraph {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 420;
  color: getTextColor('grey');
  line-height: 1.2;
  text-align: right;
}
