@import '~@sonnen/shared-web/abstracts';

.c-lead-invalid-address-modal {
  --modal-layout-body-padding-h: var(--gap-5);
  height: 100%;

  @include breakpointUp(SM) {
    --modal-layout-body-padding-h: var(--gap-8);
  }
}

.c-lead-invalid-address-modal__button-back {
  --link-button-font-size: 16px;
}

.c-lead-invalid-address-modal {
  height: 100vh;

  @include breakpointUp(SM) {
    height: 415px;
  }
}

.c-lead-invalid-address-modal__info {
  padding-top: var(--gap-4);
  font-size: 18px;
  line-height: 1.5;
}

.c-lead-invalid-address-modal__address {
  font-size: 18px;
  font-weight: 420;
  line-height: 1.5;
}

.c-lead-invalid-address-modal__warning-wrapper {
  display: flex;
  color: getColor('yellow');
  font-weight: 420;
  font-size: 12px;
  line-height: 1.5;
}

.c-lead-invalid-address-modal__warning-icon {
  width: 50px;
  fill: getColor('yellow');
  margin-right: var(--gap-2);
  @include breakpointUp(SM) {
    width: 40px;
    margin-right: var(--gap-3);
  }
}

.c-lead-invalid-address-modal__info,
.c-lead-invalid-address-modal__address,
.c-lead-invalid-address-modal__warning {
  padding-bottom: var(--gap-3);
}



.c-lead-invalid-address-modal__buttons {
  width: 100%;
  margin-bottom: var(--gap-3);
  padding: var(--gap-2) 0;

  @include breakpointUp(XS) {
    margin-bottom: 0;
  }

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-3) 0;
  }
}

.c-lead-invalid-address-modal__button-back {
  margin-bottom: var(--gap-3);
  padding: 0;

  @include breakpointUp(SM) {
    margin-right: var(--gap-7);
    margin-bottom: unset;
  }
}
