@import '~@sonnen/shared-web/abstracts';

.c-form-banner {
  color: getTextColor('dark');
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  opacity: 1;
  padding: var(--gap-2);
  transition: opacity 2s linear;
  visibility: visible;
  width: 100%;
}

.c-form-banner--box-shadow {
  @include boxShadow();
}

.c-form-banner--success {
  background-color: getColor('green-lighter');

  margin-bottom: var(--gap-3);
}

.c-form-banner--error {
  background-color: rgba(252, 206, 199, 0.25);
}

.c-form-banner--default {
  background-color: getColor('grey-lightest');
}

.c-form-banner--info {
  background-color: getColor('white');

  .c-form-banner__icon svg {
    fill: getColor('blue');
    transform: translateY(-6px);
    width: 28px;
  }
}

.c-form-banner--transition {
  opacity: 0;
  transition: opacity 1s linear;
}

.c-form-banner__icon {
  display: flex;
  margin-right: var(--gap-2);

  svg {
    width: 24px;
  }
}

.c-form-banner__icon--top {
  align-self: flex-start;
}

.c-form-banner__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-form-banner__title {
  line-height: 20px;
}

.c-form-banner__title--bold {
  font-weight: 420;
}

.c-form-banner__message {
  margin-top: 5px;
}

.c-form-banner__title,
.c-form-banner__message {
  margin-bottom: 0;
}
