@import '~@sonnen/shared-web/abstracts';

.customer-analysis-widget__header {
  display: flex;
  align-items: center;
  margin: var(--gap-1) 0px;
}

.customer-analysis-widget__header-status {
  margin-left: var(--gap-1);

  @include breakpointUp(MD) {
    margin-left: var(--gap-3);
  }
}
