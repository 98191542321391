@import '/src/app/designSystem/styles/';

.headline-hero {
  font-weight: $_medium;
  font-size: $_40;
  line-height: 48px;
}

.headline-large {
  font-weight: $_light;
  font-size: $_26;
  line-height: 40px;
  
  @include breakpoint('tablet') {
    font-size: $_32;
  }
}

.headline-medium {
  font-weight: $_light;
  font-size: $_24;
  line-height: 32px;
}

.headline-small {
  font-weight: $_light;
  font-size: $_20;
  line-height: 24px;
}
