@import '~@sonnen/shared-web/abstracts';

.c-dso-registration-container {
  .c-form-banner {
    margin-top: var(--gap-1);
  }
}
.c-setup-tool-sonnen-battery__item {
  width: 45.5%;

  .sw-select__list {
    max-height: 150px;
  }
}
