@import '~@sonnen/shared-web/abstracts';
@import '/src/app/designSystem/styles/';

.heat-pump-status-card {
  &__header {
    display: flex;
    align-items: center;
    margin: var(--gap-1) 0px;
  }

  &__header-status {
    margin-left: var(--gap-1);

    @include breakpointUp(MD) {
      margin-left: var(--gap-3);
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr;
    align-items: center;
  }

  &__text-on-left-side {
    text-align: right;
  }

  &__illustration {
    padding: 24px;
  }

  &__value {
    color: $grey_900;
  }

  &__footer {
    padding: 12px 24px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}
