@import '~@sonnen/shared-web/abstracts';

.c-dso-registration-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &--complete-data {
    margin-bottom: var(--gap-7);
  }

  &--with-tooltip {
    justify-content: flex-start;

    .c-setup-tool__upload-input-label {
      display: inline-flex;
      width: inherit;
    }
  }
}
