@import '~@sonnen/shared-web/abstracts';

$cell-status-margin: calc(94px - var(--gap-3));

.c-setup-tool-step-list-table-item {
  margin-bottom: var(--gap-3);

  .c-round-button,
  .sw-round-button {
    height: 24px;
    margin-right: var(--gap-2);
    min-width: 24px;
    width: 24px;
  }
  
  .c-round-button--info.c-round-button--outline {
    border: 1px solid getColor('grey-darkest');
  }

  .c-round-button__inner {
    color: getTextColor('dark');
    font-size: 16px;
    font-weight: 420;
  }

  .sw-card {
    @include breakpointDown(LG) {
      min-height: 125px;
    }
  }

  .sw-card__main {
    display: flex;
    justify-content: space-between;

    @include breakpointDown(MD) {
      padding: 14px 12px;
    }

    @include breakpointDown(XS) {
      flex-wrap: wrap;
    }
  }

  .sw-card__footer {
    background: getColor('grey-lightest');
    display: flex;
    padding: 0 var(--gutter-card);

    @include breakpointDown(MD) {
      padding: 0 12px;
    }
  }
}

.c-setup-tool-step-list-table-item--disabled {
  opacity: 0.5;
}

.c-setup-tool-step-list-table-item__cell {
  flex: 0 1 auto;
  margin: 0 var(--gap-1) var(--gap-1) var(--gap-1);
  align-items: center;

  @include breakpointUp(XS) {
    margin-bottom: 0;
  }
}

.c-setup-tool-step-list-table-item__cell--info {
  margin-right: auto;

  h4 {
    font-size: 16px;
    font-weight: 420;
    line-height: 20px;
    margin-bottom: 5px;
  }

  h5 {
    color: getTextColor('grey');

    @include breakpointDown(LG) {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.c-setup-tool-step-list-table-item__cell--status {
  justify-content: flex-end;
  margin: 0 $cell-status-margin 0 var(--gap-2);

  @include breakpointDown(LG) {
    justify-content: flex-start;
    margin: var(--gap-1) 0 0;
  }
}

.c-setup-tool-step-list-table-item__cell--start {
  justify-content: flex-end;

  button {
    max-width: 160px;

    @include breakpointDown(LG) {
      max-width: none;
    }
  }

  .sw-button__label {
    min-width: 160px;

    @include breakpointDown(LG) {
      padding: 0 12px;
      white-space: nowrap;
    }
  }

  @include breakpointDown(XS) {
    margin-left: var(--gap-7);
  }
}

.c-setup-tool-step-list-table-item__appendix {
  padding: var(--gap-2) var(--gap-1);
  width: 100%;
}

.c-setup-tool-step-list__trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: getTextColor('dark');

  .c-setup-tool-step-list__arrow {
    position: relative;
    width: 32px;
    height: 32px;
    transition: transform 400ms ease-in-out;

    .c-setup-tool-step-list__expanded & {
      transform: rotate(180deg);
    }

    &::after {
      content: config(ICONS, ANGLE);
      color: getColor('blue');
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-family: var(--font-family-icons);
      font-size: 12px;
      text-align: center;
      transform: rotate(-90deg);
    }
  }
}
