@import '~@sonnen/shared-web/abstracts';

.c-impact-analysis-results {
  @include boxShadow();
  flex-direction: column;
  margin-top: var(--gap-4);
  padding-top: var(--gap-1);
  padding-bottom: var(--gap-1);

  &__line-divider {
    margin: 0;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin: var(--gap-1) var(--gap-3) var(--gap-1) var(--gap-2);

    &--gray { 
      margin: var(--gap-1) 0px;

      &:first-of-type {
        margin-top: 0px;
      }
    
      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    &-text-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 8px;
    }

    &-value-wrapper {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: flex-end;
      white-space: nowrap;
    }
  }

  // Background Colors
  &-background-green-tint-3 {
    background: #f5faf3;
  }

  &-background-gray {
    padding: var(--gap-1) var(--gap-3) var(--gap-1) var(--gap-2);
    background: #F4F4F5;
  }

  &-box-blue {
    border-radius: 4px;
    border-left: 5px solid getColor('grey-light');
  }

  &-box-mint {
    border-radius: 4px;
    border-left: 5px solid getColor('green-lighter');
  }

  &-box-green {
    border-radius: 4px;
    border-left: 5px solid #92cd85;
  }

  &-box-dark-blue {
    border-radius: 4px;
    border-left: 5px solid getColor('blue');
  }

  &-box-yellow {
    border-radius: 4px;
    border-left: 5px solid getColor('yellow');
  }

  &-box-dark-green {
    border-radius: 4px;
    border-left: 5px solid #4eb19e;
  }
}

.c-impact-analysis-results__amortization-warning {
  margin: 32px 0;
}

.c-impact-analysis-results-amortization-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .c-warning-in-circle {
    margin-right: var(--gap-1);
  }
}

.c-impact-analysis-results-amortization-info-box {
  display: flex;
  align-self: flex-start;
  margin-top: var(--gap-2);
}

.c-impact-analysis-results__loader {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
