@import '~@sonnen/shared-web/abstracts';

.c-layout {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
}

.c-layout__with-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/img/bg.jpg');
}

.c-layout__content-only {
  .sw-header  {
    display: none;
  }
  .c-footer {
    display: none;
  }
}

.c-layout__main {
  position: relative;
  min-height: var(--content-height);
  opacity: 1;
  transition: opacity .25s;
}

.c-layout__main--higher {
  min-height: calc(var(--content-height) + var(--header-height));
}

.c-layout__modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: getColor('modal-overlay');
  z-index: calc(var(--z-index-modal) - 1);
}

.c-layout__main--bg-gray {
  background-color: getColor('grey-lightest');
}

.c-layout__footer {
  min-height: var(--footer-height);
}
