@import '/src/app/designSystem/styles/';

// below simplified styles are based on Container and PageWidthWrapper from shared-web
.responsive-page-width {
  flex: 1 0;
  margin: 0 auto;
  padding: 0 24px;
  
  @include breakpoint("tablet") {
    padding: 0 40px;
  }
  @include breakpoint("desktop") {
  }
  @include breakpoint("extra-wide") {
    padding: 0;
    max-width: 1270px;
  }
}
