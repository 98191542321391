@import './../../../../app/designSystem/styles/colors';

.csv-download-button {
  border: 1px solid $grey_900;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
