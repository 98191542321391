@import '~@sonnen/shared-web/abstracts';

.c-widget__header.clamps-table {
  display: flex;
  justify-content: flex-start;

  .headline-horizontal-spacing {
    padding: 0px 12px 0px 8px;
  }
}

.power-meter-live-states-card__header-status {
  margin-left: var(--gap-1);
  white-space: pre;

  @include breakpointUp(MD) {
    margin-left: var(--gap-3);
  }
}

.power-meter-live-states-card__body {
  padding: 24px;
}

.power-meter-live-states-card__body-table {
  font-weight: 400;
  overflow: auto;
}

.first-column-header-cell-sticky {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: inherit;
}

.power-meter-live-states-card__tooltip {
  display: flex;
}

.power-meter-live-states-card__body-table-sticky-cell {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: inherit;
  background-clip: padding-box; // prevents background from cover border of parent
  font-weight: 420;
  white-space: nowrap;
}

.power-meter-live-states-card__body-table-normal-cell {
  font-weight: 400;
}

.loader {
  display: flex;
  justify-content: center;
}
