@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-form {
  --configuration-input-width: 360px;
  --configuration-column-space: var(--gap-4);
  --configuration-column-width: calc(50% - var(--configuration-column-space) / 2);
}

.c-lead-configuration-form {
  flex: 1;
}

.c-lead-configuration-form__container {
  padding: 0 calc(var(--gutter-layout) - var(--gutter-card));

  @include breakpointUp(MD) {
    padding: 0;
  }
}

.c-lead-configuration-form__title {
  font-size: 18px;
  font-weight: 420;
  margin-top: var(--gap-1);
}

.c-lead-configuration-form__subtitle {
  font-size: 14px;
  margin-top: var(--gap-2);
}

.c-lead-configuration-form__button-wrapper {
  margin: 0 var(--gutter-layout);

  @include breakpointUp(SM) {
    align-self: flex-end;
  }

  @include breakpointUp(MD) {
    margin: 0 var(--gutter-card);
  }
}

.c-lead-configuration-form__header-container {
  display: flex;
  align-items: baseline;
}

.c-lead-configuration-form__header-product-type {
  margin-right: var(--gap-1);
}
