@import "~@sonnen/shared-web/abstracts";

.c-vpp-documentation-tooltip {
  display: inline-flex;
  position: relative;
}

.c-vpp-documentation-tooltip__info-icon {
  fill: $color-light-blue;
  margin-left: 5px;
  min-width: 22px;
  width: 22px;

  &:hover {
    cursor: pointer;
  }

  &:hover + .c-vpp-documentation-tooltip__bubble-wrapper {
    display: block;
  }
}

.c-vpp-documentation-tooltip__bubble-wrapper {
  display: none;
  left: calc(-1*var(--gap-1));
  position: absolute;
  top: var(--gap-4);
  width: 376px;
  z-index: 1;
}
