@import '~@sonnen/shared-web/abstracts';

.c-change-logo-form {
  height: 100%;
}

.c-change-logo-form__button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.c-change-logo-form__input {
  margin-top: 0;
  margin-bottom: var(--gap-3);
}

.c-change-logo-form__button {
  --btn-min-width: 120px;
  @include breakpointUp(XS) {
    --btn-min-width: 160px;
  }
}

.c-change-logo-form__hint {
  margin-bottom: var(--gap-3);
  color: getTextColor('grey');
  font-size: 14px;
  line-height: 20px;
}

.c-change-logo-form__upload-input {
  padding: 0 0 var(--gap-3);
}

.c-change-logo-form__upload-input .c-form-upload-input__drag-area {
  max-width: 210px;
  max-height: 210px;
  margin: 0 auto;
}

.c-change-logo-form__upload-input .c-form-upload-input__files {
  max-width: 100%;
  max-height: 100%;
}

.c-change-logo-form .c-form-upload-input__error {
  margin: 0 auto;
}
