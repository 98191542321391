@import '~@sonnen/shared-web/abstracts';

.c-case-report-search-results {
  @include boxShadow();
  max-height: calc(var(--gap-6) * 3);
  overflow-y: auto;
  margin: 1px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.c-case-report-search-results__wrapper {
  min-height: var(--gap-6);
  display: flex;
  align-items: center;
  padding-left: var(--gap-2);
}
