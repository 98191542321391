.c-loader-wrapper--loading {
  position: relative;
  border: 1px solid #eee;
}

.c-loader-wrapper__content--hide {
  visibility: hidden;
}

.c-loader-wrapper__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
