@import '~@sonnen/shared-web/abstracts';

.c-form-input-info {
  display: flex;
  align-items: flex-start;
}

.c-form-input-info__icon {
  @include createIcon($_width: 20px, $_color: getColor('blue'));
  margin-top: -2px;
  margin-right: var(--gap-1);
}

.c-form-input-info__text {
  margin: 0;
  font-size: 11px;
  line-height: 1.4;
  color: getTextColor('dark');
}

.c-form-input-info__text--bold {
  font-weight: 420;
}
