@import '~@sonnen/shared-web/abstracts';

.input-box {
  display: inline-block;
  height: 28px;
  padding-left: var(--gap-1);
  padding-right: var(--gap-1);
  font-size: 14px;
  border: getColor('grey-light') solid 1px;
  border-radius: 4px;
}
