@import '~@sonnen/shared-web/abstracts';

.c-form-wizard-step {
  position: relative;
}

.c-form-wizard-step__content {
  padding: var(--gap-1) var(--gap-3);
}

.c-form-wizard-step__navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: var(--gap-1) var(--gap-7);
}

.c-form-wizard-step__navigation-prev {
  margin-right: auto;
}

.c-form-wizard-step__navigation-prev__button,
.c-form-wizard-step__navigation-cancel__button {
  padding: 0;
}

.button-label-arrow-left {
  color: $blue_500;
  fill: $blue_500;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
}

.c-form-wizard-step__navigation-next {
  margin-left: 0;
}

.c-form-wizard-step__navigation-cancel {
  margin-left: auto;
  margin-right: var(--gap-6);

  .sw-link-button {
    font-size: 16px;
  }
}
