@import '~@sonnen/shared-web/abstracts';

$_notes-padding-h: 30px;

.c-lead-overview-partner-notes--display-mode {
  --widget-padding-h: 0;
}

.c-lead-overview-partner-notes__text-wrapper{
  position: relative;
  height: 150px;
  overflow-y: auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 1.4;
  padding: 0 #{$_notes-padding-h};
}

.c-lead-overview-partner-notes__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 1;
}

.c-lead-overview-partner-notes__textarea {
  position: relative;
}

.c-widget__body {
  position: relative;
}

.c-lead-overview-partner-notes__btn {
  display: flex;
  justify-content: flex-end;
}

.c-lead-overview-partner-notes__shadow {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: #{$_notes-padding-h};
    width: calc(100% - #{$_notes-padding-h} * 2);
    height: 35px;
    box-shadow: inset 0 -50px 20px -29px getColor('white');
  }
}
