@import '~@sonnen/shared-web/abstracts';
@import '/src/app/designSystem/styles/';

.c-lead-flat-configuration-tile {
  display: block;
  
  &__details-paragraph {
    margin-bottom: 18px;
  }
}

.c-lead-flat-configuration-tile__body {
  margin: var(--gap-1) 0;
  padding: var(--gap-3);
  border-radius: 5px;
  background-color: getColor('white');
}

.c-lead-flat-configuration-tile__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--gap-2);
}

.c-lead-flat-configuration-tile__header-details {
  margin-bottom: var(--gap-1);
}

.c-lead-flat-configuration-tile__header-subheadline {
  display: flex;
  align-items: baseline;
  margin-bottom: 0;
}

.c-lead-flat-configuration-tile__header-subheadline-span {
  padding-left: 5px;
}

.c-lead-flat-configuration-tile__information {
  margin: 16px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-lead-flat-configuration-tile__link {
  padding: 4px 0 0 0;
}

.c-lead-flat-configuration-tile-modal__text {
  padding: 0;

  @include breakpoint(tablet) {
    padding: 0 88px;
  }
}

.c-lead-flat-configuration-tile-modal__close-button {
  margin: 32px 8px 32px auto;
}

.c-lead-flat-configuration-tile-modal {
  height: 100vh;
  
  @include breakpointUp(SM) {
    height: 85vh;
    max-height: 700px;
  }
}

.c-lead-flat-configuration-tile__calculation-based-on {
  margin-top: 16px;
}

.c-lead-flat-configuration-tile__detailed-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.c-lead-flat-configuration-tile__config-values {
  padding: var(--gap-2) var(--gap-3) var(--gap-4);
  background-color: getColor('grey-lightest');
}

.c-lead-flat-configuration-tile__config-values-category-name {
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: var(--gap-1);
  }
}

.c-lead-flat-configuration-tile__vpp-warning {
  margin-top: var(--gap-3);
}

.c-lead-flat-configuration-tile__status-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-1);
}

.c-lead-flat-configuration__header-line {
  margin-top: 16px;
}
