@import '~@sonnen/shared-web/abstracts';

.sw-edit-news__modal {
  .sw-modal__dialog--size-medium {
    @include breakpointUp(SM) {
      height: 100%;
    }
  }
}

.sw-edit-news__modal > div > div {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}
