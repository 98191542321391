@import '~@sonnen/shared-web/abstracts';

.c-support-form-result__button {
  margin: var(--gap-4) auto 0;
}

.c-support-form-result__body {
  display: flex;
  flex-direction: column;
  margin: var(--gap-4) 0;
}