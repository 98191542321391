@import '~@sonnen/shared-web/abstracts';

.c-inbox-lead-list-partner-name {
  display: flex;
}

.c-inbox-lead-list-partner-name__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--gap-4);
  height: var(--gap-4);
  margin-right: var(--gap-3);
  border-radius: 50%;
  background-color: getColor('green');
  color: getColor('white');
  font-size: var(--gap-2);
  font-weight: 420;
}

.c-inbox-lead-list-partner-name__circle--compact {
  margin-right: var(--gap-1);
  min-width: 20px;
  height: 20px;
  font-size: 10px;
}

.c-inbox-lead-list-partner-name__name {
  font-size: 14px;
  font-weight: 420;
}

.c-inbox-lead-list-partner-name__role {
  font-size: 12px;
  font-weight: 420;
  color: getTextColor('grey');
}

.c-inbox-lead-list-partner-name__name-wrapper {
  line-height: 1.4;
}

.c-inbox-lead-list-partner-name__name-wrapper--centered {
  display: flex;
  align-items: center;
}

.c-inbox-lead-list-partner-name__nowrap {
  white-space: nowrap;
}
