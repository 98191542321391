@import '~@sonnen/shared-web/abstracts';

.c-form-error-banner {
  display: flex;
  justify-content: center;
  padding: var(--gap-2) 0;
  color: getColor('red');
}

.c-form-error-banner--left {
  justify-content: flex-start;
}
