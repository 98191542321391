@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-hardware__headline {
  font-weight: 400;
}

.c-lead-configuration-hardware__subheadline {
  font-size: 16px;
  font-weight: 420;
  margin-bottom: var(--gap-4);
}
