@import '~@sonnen/shared-web/abstracts';

.c-lead-flat-configuration-tile__recalculation-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin: 24px 32px 8px;
  position: relative;

  .sw-tooltip-confirmation {
    top: 120%;
    left: 20%;
  }
}

.c-lead-flat-configuration-tile__recalculation-wrapper-label {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
  }
}

.c-lead-flat-configuration-tile__recalculation-icon-blue {
  @include createIcon($_width: 15px, $_height: 16px, $_color: getColor('blue'));
  display: block;

  transition: fill 0.15s ease-in-out;
  margin-right: 5px;
}

.c-lead-flat-configuration-tile__recalculation-icon-black {
  @include createIcon($_width: 15px, $_height: 16px, $_color: getColor('grey-darkest'));

  transition: fill 0.15s ease-in-out;
  margin-right: 5px;
}
