@import '~@sonnen/shared-web/abstracts';

.c-customer-note-widget-form__btn {
  text-align: right;
}

.c-customer-note-widget-form__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 1;
}
