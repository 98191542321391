@import '~@sonnen/shared-web/abstracts';

$_row-border-colors: (
  'yellow': map-get($hint-colors, warning),
  'green': map-get($hint-colors, success),
);

.c-list-table {
  position: relative;
}

// LIST-TABLE__HEADER
// ---------------------------------

.c-list-table__header {
  display: none;

  @include breakpointUp(XS) {
    display: flex;
    position: static;
    top: var(--header-height);
    border-bottom: 2px solid getColor('grey-light');
    background-color: getColor('white');
    overflow-x: hidden;
    z-index: var(--z-index-banner);
  }

  @include breakpointUp(SM) {
    position: sticky;
  }
}

.c-list-table__header--small {
  @include breakpointUp(XS) {
    top: var(--gap-6);
  }
}

.c-list-table__header--narrow-border {
  @include breakpointUp(XS) {
    border-bottom: 1px solid getColor('grey-light');
  }
}

.c-list-table__header-wrapper {
  display: flex;
  width: 100%;

  @include breakpointUp(XS) {
    padding: 15px var(--gap-4);

    @include breakpointDown(MD) {
      min-width: calc(#{config(BREAKPOINTS, MD)} - var(--gutter-layout) * 2);
    }
  }
}

.c-list-table__header-wrapper--fit-width {
  @include breakpointUp(XS) {
    min-width: unset;
  }
}

.c-list-table__header-item {
  flex: 1;
  font-weight: 420;
  color: getTextColor('dark');
  align-items: center;
}

// LIST-TABLE__TABLE
// ---------------------------------

.c-list-table__table {
  @include boxShadow;
  margin: 0 calc(var(--gutter-layout) * -1);

  @include breakpointUp(XS) {
    width: 100%;
    margin: auto;
    box-shadow: none;
    overflow-x: scroll;
    border: 1px solid getColor('grey-light');
  }

  @include breakpointUp(MD) {
    overflow-x: visible;
  }
}

.c-list-table__table--no-border {
  @include breakpointUp(XS) {
    border: none;
  }
}

.c-list-table__table-wrapper {
  font-size: 14px;

  @include breakpointUp(XS) {
    @include breakpointDown(MD) {
      min-width: calc(#{config(BREAKPOINTS, MD)} - var(--gutter-layout) * 2);
    }
  }
}

.c-list-table__table-wrapper--fit-width {
  @include breakpointUp(XS) {
    min-width: unset;
  }
}

.c-list-table__table-row-wrapper {
  position: relative;
}

.c-list-table__table-row {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--gap-2) var(--gap-4);
}

.c-list-table__no-results {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-1);

  @include breakpointUp(XS) {
    margin-top: var(--gap-8);
  }
}

@each $_type, $_color in $_row-border-colors {
  .c-list-table__table-row--border-#{$_type} {
    border-left: 5px solid $_color;
  }
}

.c-layout__content-only {
  .c-list-table__header {
    top: 0;
  }
}
