@import '~@sonnen/shared-web/abstracts';

$area-chart-height-mobile: 270px;
$area-chart-height-desktop: 390px;
$line-chart-height: 60px;
$band-chart-height: 270px;
$x-axis-height: 30px;
$area-chart-margin: 25px;
$band-chart-margin: 55px;

:export {
  areaChartHeightMobile: $area-chart-height-mobile;
  areaChartHeightDesktop: $area-chart-height-desktop;
  lineChartHeight: $line-chart-height;
  bandChartHeight: $band-chart-height;
  xAxisHeight: $x-axis-height;
  areaChartMargin: $area-chart-margin;
  bandChartMargin: $band-chart-margin;
}

.c-customer-analysis-energy-flow {
  margin: 0 calc(-1 * var(--gap-3)) var(--gap-3);

  @include breakpointUp(XS) {
    margin: 0 0 var(--gap-5);
  }

  .sw-card__header {
    flex-direction: column;
  }
}

.c-customer-analysis-energy-flow__content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  margin: 0 calc(-1 * var(--gap-3));
  padding-bottom: var(--gap-3);

  @include breakpointUp(XS) {
    margin: 0;
  }
}

.c-customer-analysis-energy-flow__tooltip-wrapper {
  position: sticky;
  top: 100px;
  margin: 2px var(--gap-1);
  z-index: calc(var(--z-index-tooltip) + 1);

  @include breakpointUp(XS) {
    margin: 0 0 var(--gap-1);
  }
}

.c-customer-analysis-energy-flow__tooltip-header,
.c-customer-analysis-energy-flow__tooltip-footer {
  @include breakpointUp(SM) {
    padding: 0 var(--gap-2);
  }
  button {
    padding: 0;
  }
}

// @TODO move to the shared web
.c-customer-analysis-energy-flow {
  .sw-tooltip-horizontal {
    min-width: unset;
    padding: var(--gap-1);
  }

  .sw-tooltip-horizontal__header--wrapped\@xs {
    white-space: unset;
  }
}

.c-customer-analysis-energy-flow__reset-wrapper {
  margin-bottom: var(--gap-2);
  text-align: right;
  visibility: hidden;
}

.c-customer-analysis-energy-flow__reset-wrapper--visible {
  visibility: visible;
}

.c-customer-analysis-energy-flow__container {
  width: 100%;
  position: relative;
}
