@import '~@sonnen/shared-web/abstracts';

.c-setup-overview-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.c-setup-overview-header__mobile-lower--flex {
  display: flex;
  flex-wrap: wrap;

  @include breakpointDown(SM) {
    flex-direction: column;
  }
}
