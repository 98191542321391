@import '~@sonnen/shared-web/abstracts';

.c-warning-in-circle {
  display: inline-block;
  padding: 0 5px;
  width: var(--gap-3);
  height: var(--gap-3);
  border-radius: 50%;
  background: getColor('yellow');
}
