@import '~@sonnen/shared-web/abstracts';

.c-case-detail--gap {
  margin-bottom: var(--gap-2);

  @include breakpointUp(MD) {
    margin-bottom: var(--gap-4);
  }
}

.c-case-detail__label {
  margin-bottom: var(--gap-1);
  color: getTextColor('grey');
  font-size: 14px;
  font-weight: 420;
}

.c-case-detail__value {
  color: getTextColor('dark');
  font-size: 14px;
  font-weight: 300;
}

.c-case-detail__value--bold {
  color: getTextColor('dark');
  font-weight: 420;
}
