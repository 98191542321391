@import '~@sonnen/shared-web/abstracts';

.c-notification-modal {
  .sw-modal-layout__header {
    padding: var(--gap-4) var(--modal-layout-padding-h);
  }

  .sw-modal-layout__title {
    font-size: 32px;
  }

  .sw-modal-layout__footer {
    justify-content: flex-end;
    padding: var(--gap-4) var(--modal-layout-padding-h);
  }
}

.c-notification-modal__footer-link {
  flex-grow: 0;
  font-size: 16px;
  margin-right: var(--gap-3);
}
