@import '~@sonnen/shared-web/abstracts';

$_default-color: #2e7c8f;

$mappedColors: (
  'blue': (
    getColor('blue-lighter'),
    getTextColor('blue-dark'),
  ),
  'yellow': (
    getColor('yellow-lighter'),
    getTextColor('yellow-dark'),
  ),
  'green': (
    getColor('green-lighter'),
    getTextColor('green-dark'),
  ),
  'gray': (
    getColor('grey-lighter'),
    getTextColor('grey-dark'),
  ),
  'red': (
    getColor('red-lighter'),
    getTextColor('red-dark'),
  ),
  'turquoise': (
    getColor('turquoise-lighter'),
    getTextColor('turquoise-dark'),
  ),
);

.c-status-tile {
  --status-tile-height: 20px;
  --status-tile-padding: 2px 8px;
  position: relative;
  margin: 4px;
  margin-left: 0;
}

.c-status-tile--compact {
  margin-top: 0;
  margin-right: var(--gap-2);
}

.c-status-tile--hoverable {
  cursor: pointer;
}

.c-status-tile--no-margin {
  margin: 0;
}

.c-status-tile__status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--status-tile-height);
  padding: var(--status-tile-padding);
  border-radius: var(--gap-2);
  font-size: 12px;
  font-weight: 420;
  color: getTextColor('white');
  text-transform: uppercase;
  background-color: $_default-color;
  white-space: nowrap;
}

@each $name, $values in $mappedColors {
  .c-status-tile__status-wrapper--#{$name} {
    background-color: nth($values, 1);
    color: nth($values, 2);
  }
}

.c-status-tile__status-wrapper--transparent {
  border: 1px solid getColor('grey');
  color: getTextColor('grey');
  background-color: transparent;
}

.c-status-tile__dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: getTextColor('green-dark');
  margin-right: 4px;
}

.c-status-tile__bubble-wrapper {
  position: absolute;
  margin-top: var(--gap-1);
  width: 200px;
  line-height: 1.4;
  z-index: 2;

  @include breakpointUp(SM) {
    left: 50%;

    .sw-bubble {
      left: -50%;
    }
  }
}
