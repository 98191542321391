@import '~@sonnen/shared-web/abstracts';

$_notes-padding-h: 30px;

.c-lead-overview-sonnen-notes {
  --widget-padding-h: 0;
}

.c-lead-overview-sonnen-notes__text-wrapper {
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.4;
  padding: 0 #{$_notes-padding-h};
}

.c-lead-overview-sonnen-notes__shadow {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: #{$_notes-padding-h};
    width: calc(100% - #{$_notes-padding-h} * 2);
    height: 35px;
    box-shadow: inset 0 -50px 20px -29px getColor('white');;
  }
}
