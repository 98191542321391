@import '~@sonnen/shared-web/abstracts';

.c-register-form {
  padding: var(--gap-3) 0 0;

  .link {
    color: getTextColor('blue');
  }
}

.c-register-form__submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--gap-4) 0 var(--gap-2);
}

.c-register-form__group {
  position: relative;
}

.c-register-form__input {
  position: relative;
  padding: 0 0 var(--gap-2);
}

.c-register-form__alert {
  @include fadeOut();
  text-align: center;
  color: getTextColor('red');
  max-height: 0;
  transition: .25s ease-in-out;

  &.is-active {
    @include fadeIn();
    max-height: 100px;
  }
}

.c-register-form__checkbox {
  margin: var(--gap-2) 0 var(--gap-3);
}
