@import '~@sonnen/shared-web/abstracts';

.add-tariff-button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.hw-config-tile {
  @include boxShadow;
  margin: var(--gap-1) 0;
  padding: var(--gap-3);
  border-radius: 0 0 5px 5px;
  background-color: white;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--gap-2);

    .header-status-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__details {
    .c-lead-configuration-line__value {
      white-space: normal;
      text-align: end;
    }
  }

  &__status-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--gap-2);
  }
}
