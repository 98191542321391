@import '~@sonnen/shared-web/abstracts';

.c-lead-list-assign-modal {
  --modal-layout-body-padding-v: var(--gap-4);
  --modal-layout-body-padding-h: var(--gap-4);

  @include breakpointUp(SM) {
    --modal-layout-body-padding-h: var(--gap-10);
  }
}

.c-lead-list-assign-modal {
  height: 100vh;

  @include breakpointUp(SM) {
    height: 85vh;
    max-height: 600px;
  }
}

.c-lead-list-assign-modal__buttons {
  width: 100%;
  margin-bottom: var(--gap-3);
  padding: var(--gap-2) 0;

  @include breakpointUp(XS) {
    margin-bottom: 0;
  }

  @include breakpointUp(SM) {
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-3) 0;
  }
}

.c-lead-list-assign-modal__buttons-wrapper {
  @include breakpointDown(SM) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.c-lead-list-assign-modal__footer-button {
  padding: 0;
  margin-bottom: var(--gap-3);

  @include breakpointUp(SM) {
    margin-right: var(--gap-7);
    margin-bottom: unset;
  }
}

.c-lead-list-assign-modal__accept-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(getColor('white'), 0.7);
  z-index: 9;
}

.c-lead-list-assign-modal__empty-list {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 300px;
  max-width: 330px;
  margin: 0 auto;
  text-align: center;
}

.c-lead-list-assign-modal__empty-list-icon {
  height: 80px;
}

.c-lead-list-assign-modal__empty-list-headline {
  font-size: 20px;
  margin: var(--gap-4) 0 var(--gap-2);
}
