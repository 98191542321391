@import '~@sonnen/shared-web/abstracts';

.c-form-input-radio-box {
  position: relative;
  flex: 0 0 160px;
  min-height: 100px;
  padding: 10px;

  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }
}

.c-form-input-radio-box--disabled {
  &:hover {
    transform: initial;
  }
}

.c-form-input-radio-box--large {
  flex: 0 0 300px;
  min-height: 140px;
}

.c-form-input-radio-box__box {
  @include boxShadow();
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.c-form-input-radio-box__input:checked + .c-form-input-radio-box__box {
  border: solid 1px getColor('blue');
  color: getColor('blue');

  .c-form-input-radio-box__icon {
    fill: getColor('blue');
  }
}

.c-form-input-radio-box__box--disabled {
  opacity: 0.5;
}

.c-form-input-radio-box__icon {
  height: 30px;
  fill: getColor('grey');
}

.c-form-input-radio-box__icon--large {
  margin-top: var(--gap-1);

  @include breakpointUp(SM) {
    margin-top: var(--gap-2);
  }
}

.c-form-input-radio-box__label {
  margin-top: 4px;
  font-size: 13px;
  font-weight: 420;
}

.c-form-input-radio-box__label--large {
  font-size: 14px;
}

.c-form-input-radio-box__input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.c-form-input-radio-box__input--disabled {
  cursor: default;
}

.c-form-input-radio-box__description {
  margin: var(--gap-1) 0;
  max-width: 200px;
  text-align: center;
  color: getTextColor('grey');
  font-size: 12px;
  line-height: 1.2;
}
