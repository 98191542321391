@import "~@sonnen/shared-web/abstracts";

.c-widget-accordion {
  --accodion-content-padding: var(--gap-3);
  --accordion-header-main-color: #{getColor('blue')};
  --accordion-header-cursor: pointer;
}

.c-widget-accordion--disabled {
  --accordion-header-main-color: #{getColor('grey-lighter')};
  --accordion-header-cursor: not-allowed;
}

.c-widget-accordion__header {
  @include boxShadow();
  position: relative;
  width: 100%;
  padding: var(--gap-2);
  border-radius: 4px 0 0 4px;
  border-left: 4px solid var(--accordion-header-main-color);
  background-color: getColor('white');
  z-index: 1;
  cursor: var(--accordion-header-cursor);
}

.c-widget-accordion__header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-widget-accordion__label:not(:empty) {
  font-size: 14px;
  font-weight: 420;
  color: var(--accordion-header-main-color);
  text-align: left;
  cursor: var(--accordion-header-cursor);
}

.c-widget-accordion__icon {
  transition: opacity 200ms ease;
  margin-left: var(--gap-1);
  transform: rotate(90deg);

  &.is-rotated {
    transform: rotate(-90deg);
  }
}

.c-widget-accordion__icon-inner {
  @include createIcon($_width: 50%, $_color: getColor('white'));
}

.c-widget-accordion__content-wrapper {
  max-height: 0;
  margin-top: -2px;
  opacity: 0;
  //overflow: hidden;
  transition: opacity 500ms ease;
}

.c-widget-accordion__content-wrapper--opened {
  @include borderRadius();
  @include boxShadow();
  max-height: 10000px;
  opacity: 1;
  margin: -3px 1px 1px;

  @include breakpointUp(SM) {
    max-height: 4000px;
  }
}

.c-widget-accordion__content {
  padding: var(--accodion-content-padding);
}
