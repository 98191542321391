@charset "UTF-8";
@import '~@sonnen/shared-web/styles';
@import './app/designSystem/styles/_fonts.scss';

html,
body,
#root {
  height: 100%;

  --border-radius: 3px;
  --border-radius-big: 5px;
}

.body--disabled {
  position: relative;
  overflow-y: hidden;
}
