@import '~@sonnen/shared-web/abstracts';

.c-contact-list-headline__inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.c-contact-list__pagination {
  flex-flow: initial;
  justify-content: center;
}

.c-list-table--contact-list {
  .c-list-table__header-item.c-list-table__header-item--1,
  .c-list-table__header-item.c-list-table__header-item--3 {
    @include breakpointUp(SM) {
      flex-grow: 1.5;
    }
  }
}
