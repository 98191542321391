.new-feature-tooltip-content {
  white-space: normal;
  &__header {
    margin-bottom: 16px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
}