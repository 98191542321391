@import '~@sonnen/shared-web/abstracts';

.c-analysis-day-chart {
  position: relative;
  width: 100%;
}

.c-analysis-day-chart__canvas {
  width: 100%;
}

.c-analysis-day-chart__loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: getColor('white');
  transition: opacity 350ms ease-in-out;
  z-index: 1;

  opacity: 0;
  pointer-events: none;

  &.is-active {
    opacity: .9;
    pointer-events: all;
  }
}

.c-analysis-day-chart__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-analysis-day-chart__line-chart-tooltip-wrapper {
  position: absolute;
  padding: 0 5px;
  line-height: 1.4;

  @include breakpointUp(SM) {
    padding: 0;
  }
}

.c-analysis-day-chart__line-chart-tooltip a {
  display: block;
  padding-top: 8px;
  font-weight: 420;
  color: getTextColor('blue');
}
