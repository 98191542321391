@import '~@sonnen/shared-web/abstracts';

.c-case-list-table-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--gap-1) 0;
}

.c-case-list-table-item__cell {
  flex: 1 1 50%;
  align-items: flex-end;
  margin: 0 var(--gap-2) var(--gap-1) 0;

  @include breakpointUp(XS) {
    flex-basis: 0;
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.c-case-list-table-item__cell--id {
  flex: 0 1 0;
  font-weight: 420;

  @include breakpointUp(XS) {
    flex: .5;
  }
}

.c-case-list-table-item__cell--case-status {
  flex-direction: column;

  @include breakpointUp(XS) {
    flex: .5;
  }
}

.c-case-list-table-item__cell--status {
  position: relative;
  flex: 1 0 50%;

  @include breakpointUp(XS) {
    flex-basis: 0;
  }

  .sw-icon {
    margin-right: var(--gap-1);
  }
}

.c-case-list-table-item__cell--status-closed {
  color: getColor('green');
}

.c-case-list-table-item__cell--name {
  flex-basis: 100%;
  margin-top: var(--gap-1);
  border-top: 1px solid getColor('grey-light');
  padding-top: var(--gap-2);
  font-weight: 420;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-top: 0;
    border: 0;
    padding-top: 0;
  }
}

.c-case-list-table-item__cell--subject {
  flex-basis: 100%;
  word-break: break-all;
  font-weight: 420;
  color: getTextColor('dark');

  @include breakpointUp(XS) {
    flex-basis: 0;
    flex: 1.5;
  }
}

.c-case-list-table-item__cell--date {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  padding-right: var(--gap-5);
  font-size: 14px;
  font-weight: 420;
  color: getTextColor('dark');

  @include breakpointUp(XS) {
    flex: 1;
    padding-right: 0;
  }
}

.c-case-list-table-item__cell--preview {
  @include breakpointUp(XS) {
    flex: 0 0 40px;
    padding-right: 0;
  }
}

.c-case-list-table-item__label {
  padding: var(--gap-2) 0 var(--gap-1);
  font-weight: 420;
}

.c-case-list-table-item__icon {
  @include createIcon($_width: 10px, $_color: getColor('white'));
}
