@import "~@sonnen/shared-web/abstracts";

$fontSize: 14px;
$lineHeight: 20px;

.pv-feed-in-limit {
  display: flex;
  justify-content: space-between;

  &__label {
    display: inline-block;
    font-size: $fontSize;
    font-weight: 420;
    line-height:$lineHeight;
    margin-bottom: 8px;
  }

  &__input {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__input-field {
    height: 40px;
    width: 213px;
    font-size: $fontSize;
    font-weight: 390;
    line-height: $lineHeight;
    border-style: hidden;
    text-align: left;

    &.edit {
      border: 1px solid getColor('grey-light');
      border-radius: 4px;
    }

    &.error {
      border: 1px solid getColor('red');
      border-radius: 4px;
    }
  }

  &__input-symbol {
    position: absolute;
    font-size: $fontSize;
    font-weight: 390;
    line-height: $lineHeight;
    top: 11px;
    right: 12px;
    z-index: 1;
  }

  &__input-validation {
    font-size: 12px;
    line-height: 16px;
    padding: 4px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
  }

  &__confirmation {
    margin-top: 18px;
  }

  &__confirmation-label {
    font-size: $fontSize;
    line-height: $lineHeight;
  }

  &__information  {
    margin-bottom: 16px;

    &-text > a {
      text-decoration: underline;
    }
  }

  .error-message {
    color: getColor('red');
  }
}
