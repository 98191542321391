@import '/src/app/designSystem/styles/';

.measurement-concept {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &__title {
    color: $grey_500;
    text-transform: capitalize;
    margin-left: 10px;
  }

  &__dropdown-button {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    &:hover &--label, &:hover .arrow-svg{
      color: $blue_400;
      fill: $blue_400;
    }

    &--label {
      color: $blue_500;
    }

    & > .arrow-button {
      display: flex;
      justify-content: center;
      margin-left: 6px;
      margin-top: 2px;
      transform: rotate(0);
      transition: transform 250ms ease-in-out;
      
      &.is-rotated {
        transform: rotate(180deg);
      }
      
      & > svg {
        fill: $blue_500;
      }
    }
  }

  &__dropdown-content {
    background-color: $grey_25;
    padding: 16px 24px;
    border-radius: 4px;

    .body-spacing {
      margin: 6px 0px 16px 0px;
    }
  }
}