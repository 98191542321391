@import '~@sonnen/shared-web/abstracts';

.nav-status-tile {
  margin-left: 14px;
}

.your-sonnen-world-tab {
  --transition-enter-timeout: 300;
  --transition-leave-timeout: 300;
  --transition-enter-ms: calc(var(--transition-enter-timeout) * 1ms);
  --transition-leave-ms: calc(var(--transition-leave-timeout) * 1ms);

  display: flex;
  align-items: center;
}

.your-sonnen-world-tab__dropdown-content {
  display: flex;
  flex-flow: column;
  user-select: none;
}

.your-sonnen-world-tab__dropdown-content--desktop {
  @include dropdown(
    $_top: calc(100% - 10px),
    $_right: -25px,
    $_padding: 0,
  );
  @include createTriangleArrow(
    $_side: 'top',
    $_offset: '100% - 47px',
    $_color: $grey_900,
  );
  opacity: 0;
  transform: translateY(20px);
  min-width: 300px;
  box-shadow: none;

  &::after {
    box-shadow: none;
  }

  .is-enter > &,
  .is-enter-done > & {
    opacity: 1;
    transform: translateY(0);
    transition: opacity var(--transition-enter-ms) ease-in-out,
                transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity var(--transition-leave-ms) ease-in-out,
                transform var(--transition-leave-ms) ease-in-out;
  }

  @include breakpointUp(MD) {
    max-width: 500px;
  }
}

.your-sonnen-world-tab__dropdown-content--mobile {
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;

  .is-enter > &,
  .is-enter-done > & {
    height: auto;
    opacity: 1;
    transform: scaleY(1);
    transition: opacity var(--transition-enter-ms) ease-in-out,
                transform var(--transition-enter-ms) ease-in-out;
  }

  .is-exit > &,
  .is-exit-done > & {
    height: 0;
    opacity: 0;
    transform: scaleY(0);
    transition: opacity var(--transition-leave-ms) ease-in-out,
                transform var(--transition-leave-ms) ease-in-out;
  }
}

.your-sonnen-world-tab__navigation {
  margin-top: 20px;
  border-left: 1px solid getColor('grey-light');
  padding-left: var(--gap-2);
  list-style: none;

  @include breakpointUp(NAV) {
    margin: 0;
    border-left: 0;
    padding: 0;
  }
}
