@import '~@sonnen/shared-web/abstracts';

.c-lead-configuration-tile {
  @include boxShadow;
  margin-bottom: var(--gap-3);
  padding-bottom: var(--gap-1);
  border-radius: 5px;
  background-color: getColor('grey-lightest');
  width: 100%;
  border-top: 4px solid $blue_200;
  border-radius: 4px 4px 0 0;
}

.c-lead-configuration-tile--yellow {
  border-top: 4px solid $yellow_200;
}

.c-lead-configuration-tile--green {
  border-top: 4px solid $green_200;
}

.c-lead-configuration-tile--red {
  border-top: 4px solid $red_200;
}

.c-lead-configuration-tile--hoverable {
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 0 getColor('grey-light');
  }
}

.c-lead-configuration-tile__header {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--gap-1) var(--gap-3);
  background-color: getColor('white');
  min-height: 100px;
  border-radius: 5px;
}

.c-lead-configuration-tile__header-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--gap-2) 0;
  font-size: 20px;
  font-weight: 400;
  color: getTextColor('dark');
}

.c-lead-configuration-tile__header-title {
  margin: 0;
}

.c-lead-configuration-tile__header-actions {
  display: flex;
  flex-flow: row;
  justify-items: space-between;
  align-items: center;
}

.c-lead-configuration-tile__header-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-lead-configuration-tile__header-detail {
  display: flex;
  padding-bottom: var(--gap-1);
  font-size: 12px;
  color: getTextColor('grey');
}

.c-lead-configuration-tile__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(getColor('white'), 0.5);
  z-index: 9;
}

.c-lead-configuration-tile__offer-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: var(--gap-3);
  margin-bottom: var(--gap-2);
}

.c-lead-configuration-tile__header-status-offer {
  display: flex;
  align-items: center;
  font-weight: 420;
  font-size: 12px;
  color: getTextColor('yellow');
  transition: fill 0.15s ease-in-out;
}

.c-lead-configuration-tile__configuration-type {
  @include borderRadius();
  margin-bottom: -8px;
  padding: var(--gap-2) var(--gap-3) var(--gap-3);
  font-size: 20px;
  font-weight: 420;
}

.c-lead-configuration-tile__configuration-type-paragraph {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  margin-top: var(--gap-1);
}
