@import '~@sonnen/shared-web/abstracts';

.c-overview-recommend-lead__paragraph {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: var(--gap-5);
}

.c-overview-recommend-lead__button-wrapper {
  display: flex;
  justify-content: center;

  @include breakpointUp(XS) {
    justify-content: flex-end;
  }
}
