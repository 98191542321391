@import '~@sonnen/shared-web/abstracts';

.c-date-picker {
  @include fadeOut();
  position: relative;
  width: 100%;
  height: auto;
  transition: visibility 200ms ease 150ms,
              opacity 200ms ease 150ms;

  &.is-active {
    @include fadeIn();
  }
}

.c-date-picker--arrow-top {
  @include boxShadow($_side: 'bottom', $_inset: inset);
}

.c-date-picker--arrow-bottom {
  @include boxShadow($_side: 'bottom');
}

.c-date-picker__content {
  position: relative;
  background: getColor('white');
  transform: translateX(-50%);
  width: 310px;

  @include breakpointUp(XS) {
    width: 400px;
  }
}

.c-date-picker__content--arrow-top {
  @include createTriangleArrow(
    $_side: 'top',
    $_offset: '50% - 6px',
  );

  @include dropdown(
    $_top: var(--gap-1),
    $_left: 50%,
    $_padding: 0
  );
}

.c-date-picker__content--arrow-top-right {
  --datepicker-offset-right: 106px;

  @include breakpointUp(XS) {
    --datepicker-offset-right: 136px;
  }

  @include createTriangleArrow(
    $_side: 'top',
    $_offset: '85%',
  );

  @include dropdown(
    $_top: var(--gap-1),
    $_left: calc(-1 * var(--datepicker-offset-right)),
    $_padding: 0
  );
}

.c-date-picker__content--arrow-bottom {
  @include createTriangleArrow(
    $_side: 'bottom',
    $_offset: '50% - 6px',
  );
  @include dropdown(
    $_bottom: calc(100% + var(--gap-3)),
    $_left: 50%,
    $_padding: 0
  );
  margin-bottom: var(--gap-1);
}

.c-date-picker__content--arrow-bottom-right {
  --datepicker-offset-right: 106px;

  @include breakpointUp(XS) {
    --datepicker-offset-right: 136px;
  }

  @include createTriangleArrow(
    $_side: 'bottom',
    $_offset: '85%',
  );

  @include dropdown(
    $_bottom: calc(100% + var(--gap-3)),
    $_left: calc(-1 * var(--datepicker-offset-right)),
    $_padding: 0
  );
  
  margin-bottom: var(--gap-1);
}

.c-date-picker__day-picker {
  padding-top: var(--gap-2);
}
