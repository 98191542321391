@import '~@sonnen/shared-web/abstracts';

.c-contact-details__text {
  margin-top: var(--gap-2);
  color: getTextColor('grey');
  font-size: 14px;
  font-weight: 400;
}

.c-contact-details__link {
  color: getTextColor('blue');
}
