@import '~@sonnen/shared-web/abstracts';

.c-inbox-lead-list-table-item {
  width: 100%;
}

.c-inbox-lead-list-table-item__cell {
  flex: 1 1 50%;
  display: flex;
  align-items: center;

  @include breakpointUp(XS) {
    flex-basis: 0;
    margin-bottom: 0;
  }
}

.c-inbox-lead-list-table-item__cta {
  @include breakpointUp(SM) {
    height: var(--gap-4);
  }
}

.c-inbox-lead-list-table-item__nav-icon {
  @include createIcon($_width: 24px, $_height: 24px, $_color: currentColor);
  margin: 0 10px 10px 0;
}

.c-inbox-lead-list-table-item__details-wrapper {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
}

.c-inbox-lead-list-table-item__buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: var(--gap-1);
  padding-top: var(--gap-2);
  border-top: 1px solid getColor('grey-light');
}

.c-inbox-lead-list-table-item-details {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--gap-1) 0;
}

.c-inbox-lead-list-table-item__hint {
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: var(--gap-1);
  }
}
