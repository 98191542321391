@import '~@sonnen/shared-web/abstracts';

.c-video-list__table {
  .c-list-table__header-item.c-list-table__header-item--1 {
    @include breakpointUp(SM) {
      flex-grow: 2;
      margin-right: var(--gap-1);
    }
  }
  .c-list-table__header-item.c-list-table__header-item--3,
  .c-list-table__header-item.c-list-table__header-item--4 {
    @include breakpointUp(SM) {
      flex-grow: 0.5;
    }
  }
}
