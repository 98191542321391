@import '~@sonnen/shared-web/abstracts';

.c-form-status {
  width: 100%;
  height: 100%;
}

.c-form-status__content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.c-form-status__icon {
  @include createIcon($_width: 70px, $_height: 70px, $_color: getColor('blue'));
  display: block;
  margin: 0 auto var(--gap-4);
}

.c-form-status__headline {
  margin: 0 auto var(--gap-4);
  max-width: 250px;
  text-align: center;
}

.c-form-status__body {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}
